import React from 'react';
import Modal from './Modal';
import PromptRedline from './PromptRedline';
import ProcedureStep from './ProcedureStep';
import ProcedureHeader from './ProcedureHeader';
import Comment from './Comment';
import { REDLINE_TYPE } from '../lib/redlineUtil';
import {
  HeaderBlockRedline,
  HeaderFieldRedline,
  Redline,
  StepBlockRedline,
  StepFieldRedline,
} from '../lib/views/redlines';

interface DetachedRedlinesModalProps {
  show: boolean;
  onClose: () => void;
  detachedRedlines: Redline[];
  currentTeamId: string;
  runViewPath: (teamId: string, runId: string) => string;
  resolveDisconnectedStepRedline: (id: string) => void;
  resolveDisconnectedHeaderRedline: (id: string) => void;
  onResolveComment: (id: string) => void;
}

const DetachedRedlinesModal = ({
  show,
  onClose,
  detachedRedlines,
  currentTeamId,
  runViewPath,
  resolveDisconnectedStepRedline,
  resolveDisconnectedHeaderRedline,
  onResolveComment,
}: DetachedRedlinesModalProps) => {
  const sortedRedlines = detachedRedlines.sort((a, b) => {
    const getTimestamp = (redline: Redline) => {
      let timestamp = 0;

      if (redline.type === REDLINE_TYPE.STEP_REDLINE) {
        const { step_redline } = redline as StepFieldRedline | StepBlockRedline;
        timestamp = new Date(step_redline?.created_at ?? step_redline?.createdAt ?? 0).getTime();
      } else if (redline.type === REDLINE_TYPE.HEADER_REDLINE) {
        const { header_redline } = redline as HeaderBlockRedline | HeaderFieldRedline;
        timestamp = new Date(header_redline?.created_at ?? 0).getTime();
      } else if (redline.type === REDLINE_TYPE.REDLINE_COMMENT) {
        timestamp = new Date(redline.redline_comment?.created_at ?? 0).getTime();
      }

      return timestamp;
    };

    return getTimestamp(b) - getTimestamp(a);
  });

  return (
    <>
      {show && (
        <Modal title="Detached Redlines" primaryActionTitle="Close" onPrimaryAction={onClose} size="lg">
          <div className="flex flex-col space-y-4 w-full max-h-[80vh] overflow-y-auto">
            {sortedRedlines.map((redline) => {
              if (redline.type === REDLINE_TYPE.STEP_REDLINE) {
                const { step_redline: redlineStep } = redline as StepFieldRedline | StepBlockRedline;
                const userId = redlineStep.user_id ?? redlineStep.userId ?? '';
                const timestamp = redlineStep.created_at ?? redlineStep.createdAt ?? '';

                return (
                  <div key={redline._id} className="w-full">
                    <PromptRedline
                      allRedlineUserIds={[userId]}
                      timestamp={timestamp}
                      onReject={() => resolveDisconnectedStepRedline(redline._id)}
                      isLocationAmbiguous={true}
                      isDisconnected={true}
                      contextUrl={runViewPath(currentTeamId, redline.run_id)}
                    >
                      <ProcedureStep step={redlineStep.step} />
                    </PromptRedline>
                  </div>
                );
              } else if (redline.type === REDLINE_TYPE.HEADER_REDLINE) {
                const { header_redline: redlineHeader } = redline as HeaderBlockRedline | HeaderFieldRedline;
                const userId = redlineHeader.user_id ?? '';
                const timestamp = redlineHeader.created_at ?? '';

                return (
                  <div key={redline._id} className="w-full">
                    <PromptRedline
                      allRedlineUserIds={[userId]}
                      timestamp={timestamp}
                      onReject={() => resolveDisconnectedHeaderRedline(redline._id)}
                      isLocationAmbiguous={true}
                      isDisconnected={true}
                      contextUrl={runViewPath(currentTeamId, redline.run_id)}
                    >
                      <ProcedureHeader header={redlineHeader.header} isCollapsed={false} />
                    </PromptRedline>
                  </div>
                );
              } else if (redline.type === REDLINE_TYPE.REDLINE_COMMENT) {
                const { redline_comment: redlineComment } = redline;
                const userId = redlineComment.user_id ?? '';
                const timestamp = redlineComment.created_at ?? '';

                return (
                  <div key={redline._id} className="w-full">
                    <PromptRedline
                      allRedlineUserIds={[userId]}
                      timestamp={timestamp}
                      isLocationAmbiguous={true}
                      isDisconnected={true}
                      contextUrl={runViewPath(currentTeamId, redline.run_id)}
                    >
                      <Comment
                        comment={redline.redline_comment}
                        onResolve={onResolveComment}
                        onFieldRefChanged={undefined}
                        onUnresolve={undefined}
                        editComment={undefined}
                        commentButton={undefined}
                        onRefChanged={undefined}
                        isChild={undefined}
                        onSetParentCollapsed={undefined}
                        parentCollapsed={undefined}
                        error={undefined}
                      />
                    </PromptRedline>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </Modal>
      )}
    </>
  );
};

export default DetachedRedlinesModal;
