import { RunStep, RunStepFullRedline, RunStepRedline } from 'shared/lib/types/views/procedures';
import Modal from '../Modal';
import SuggestedEditIcon from './SuggestedEditIcon';
import DateTimeDisplay from '../DateTimeDisplay';
import React, { useCallback, useMemo, useState } from 'react';
import ReviewProcedureStep from '../Review/ReviewProcedureStep';
import diffUtil from '../../lib/diffUtil';
import lodash from 'lodash';
import { useAuth } from '../../contexts/AuthContext';
import { useRunContext } from '../../contexts/RunContext';
import { PERM } from '../../lib/auth';
import CommentsList from '../CommentsList';
import FieldLabel from '../../elements/internal/FieldLabel';

import VersionCompare from '../VersionCompare';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import { copyStepWithoutActiveContent } from 'shared/lib/runUtil';
import SuggestedEditHistoryItem from './SuggestedEditHistoryItem';
import ExpandCollapseButtons from '../ExpandCollapse/ExpandCollapseButtons';
import { newStepRedline, REDLINE_TYPE } from 'shared/lib/redlineUtil';
import runUtil from '../../lib/runUtil';

const OMIT_FIELDS_FOR_COMPARISON = ['id', 'redlines', 'redline_comments', 'redline_id'];

interface FullStepSuggestedEditsModalProps {
  currentStep: RunStep;
  redlines: Array<RunStepFullRedline>;
  onClose: () => void;
  acceptAction?: (redline: RunStepRedline) => void;
}

const FullStepSuggestedEditsModal = ({
  currentStep,
  redlines = [],
  onClose,
  acceptAction,
}: FullStepSuggestedEditsModalProps) => {
  const { auth } = useAuth();
  const { isUserParticipant, isRunning, run } = useRunContext();
  const [isAccepting, setIsAccepting] = useState(false);
  const redlinesWithOriginal = useMemo(() => {
    if (redlines[0] && !redlines[0].is_original) {
      const originalPseudoBlueline = newStepRedline({
        step: currentStep,
        userId: 'system',
        pending: false,
        fieldOrBlockMetadata: {},
        isRedline: false,
        createdAt: new Date().toISOString(),
        type: REDLINE_TYPE.FULL_STEP,
      }) as RunStepFullRedline;

      originalPseudoBlueline.is_original = true;
      return [originalPseudoBlueline, ...redlines];
    }
    return redlines;
  }, [currentStep, redlines]);

  const latestRedline =
    redlinesWithOriginal.length > 0 ? redlinesWithOriginal[redlinesWithOriginal.length - 1] : undefined;
  const reversedRedlines = redlinesWithOriginal.slice(0, redlinesWithOriginal.length - 1).reverse(); // Do not include the latest redline here

  const [redlineExpandedMap, setRedlineExpandedMap] = useState(
    new Map(
      (reversedRedlines ?? []).map((redline) => {
        return [redline.redline_id, false];
      })
    )
  );

  const setExpandedForAll = useCallback(
    (isExpanded) => {
      setRedlineExpandedMap(
        new Map(
          (reversedRedlines ?? []).map((redline) => {
            return [redline.redline_id, isExpanded];
          })
        )
      );
    },
    [reversedRedlines]
  );

  const currentStepCleaned = useMemo(
    () => lodash.omit(copyStepWithoutActiveContent(currentStep), OMIT_FIELDS_FOR_COMPARISON),
    [currentStep]
  );

  const canIncludeRedlines = useMemo(() => {
    return run && auth.hasPermission(PERM.PROCEDURES_EDIT, run.project_id);
  }, [auth, run]);

  const isAcceptDisplayed = useMemo(
    () => Boolean(isRunning && canIncludeRedlines && acceptAction && latestRedline && latestRedline.pending),
    [acceptAction, canIncludeRedlines, isRunning, latestRedline]
  );

  const isAcceptEnabled = useMemo(() => {
    return isUserParticipant && !isAccepting && runUtil.canIncludeRedlines(currentStep);
  }, [currentStep, isAccepting, isUserParticipant]);

  const onAcceptLatest = useCallback(() => {
    setIsAccepting(true);
    if (!acceptAction || !latestRedline) {
      return;
    }
    acceptAction(latestRedline);
    onClose();
  }, [acceptAction, latestRedline, onClose]);

  const toggleExpanded = useCallback(
    (redlineId) => {
      setRedlineExpandedMap((prev) => {
        const updated = new Map(prev);
        updated.set(redlineId, !prev.get(redlineId));
        return updated;
      });
    },
    [setRedlineExpandedMap]
  );

  const latestStepDiff = useMemo(() => {
    if (!latestRedline) {
      return;
    }

    const updated = lodash.omit(copyStepWithoutActiveContent(latestRedline.step), OMIT_FIELDS_FOR_COMPARISON);
    return diffUtil.getStepDiff({
      previous: currentStepCleaned,
      updated,
      ignoredFields: ['actions', 'id', 'actions', 'redline_id'],
    });
  }, [latestRedline, currentStepCleaned]);

  const isCurrentLatest =
    !latestStepDiff?.diff_change_state || latestStepDiff?.diff_change_state === ARRAY_CHANGE_SYMBOLS.UNCHANGED;

  return (
    <Modal isPrimaryActionVisible={false} onSecondaryAction={onClose} size="lg" secondaryActionTitle="Close">
      <div className="flex flex-col w-full max-h-[80vh] overflow-y-auto px-4">
        <div className="flex justify-between items-end mb-2">
          <div className="flex flex-row items-center">
            <h1 className="mb-0">Suggested Edits</h1>
          </div>
        </div>

        <div>
          {latestRedline && latestStepDiff && (
            <div className="flex flex-col gap-y-2">
              <FieldLabel label="Latest Suggested Edit" />
              <div className="flex flex-row items-center justify-between">
                <div className="text-sm">
                  <span className="font-semibold mr-1">
                    {[latestRedline.user_id ?? latestRedline.userId].join(', ')}
                  </span>
                  <DateTimeDisplay timestamp={latestRedline.created_at ?? latestRedline.createdAt ?? ''} />
                </div>
                <SuggestedEditIcon isBlueline={Boolean(latestRedline.run_only)} />
              </div>
              <VersionCompare
                label="Latest Suggested Edit"
                isCurrentLatest={isCurrentLatest}
                currentVersion={
                  <div>
                    <table>
                      <ReviewProcedureStep
                        step={currentStepCleaned}
                        showDiffContainer={false}
                        isCollapsed={false}
                        isFullWidth={true}
                        runId={undefined}
                        projectId={undefined}
                        stepKey={undefined}
                        sectionId={undefined}
                        sectionKey={undefined}
                        sourceName={undefined}
                        docState={undefined}
                        operation={undefined}
                        repeatKey={undefined}
                        onStartLinkedRun={undefined}
                        onRefChanged={undefined}
                        saveNewComment={undefined}
                        onResolveReviewComment={undefined}
                        onUnresolveReviewComment={undefined}
                        saveReviewComment={undefined}
                        comments={undefined}
                        isHidden={undefined}
                        onStepCollapse={undefined}
                        onAddIssue={undefined}
                      />
                    </table>
                    {isCurrentLatest && <CommentsList comments={latestRedline.comments ?? []} />}
                  </div>
                }
                latestVersion={
                  <div>
                    <table>
                      <ReviewProcedureStep
                        step={latestStepDiff}
                        showDiffContainer={false}
                        isCollapsed={false}
                        isFullWidth={true}
                        runId={undefined}
                        projectId={undefined}
                        stepKey={undefined}
                        sectionId={undefined}
                        sectionKey={undefined}
                        sourceName={undefined}
                        docState={undefined}
                        operation={undefined}
                        repeatKey={undefined}
                        onStartLinkedRun={undefined}
                        onRefChanged={undefined}
                        saveNewComment={undefined}
                        onResolveReviewComment={undefined}
                        onUnresolveReviewComment={undefined}
                        saveReviewComment={undefined}
                        comments={undefined}
                        isHidden={undefined}
                        onStepCollapse={undefined}
                        onAddIssue={undefined}
                      />
                    </table>
                    <CommentsList comments={latestRedline.comments ?? []} />
                  </div>
                }
                onAcceptLatest={onAcceptLatest}
                isAcceptDisplayed={isAcceptDisplayed}
                isAcceptEnabled={isAcceptEnabled}
              />
            </div>
          )}

          {reversedRedlines.length > 1 && (
            <>
              <div className="self-center my-2 h-px bg-gray-300 w-full" />

              <div className="flex flex-col gap-y-2">
                <div className="flex flex-row items-center justify-between w-full">
                  <FieldLabel label="Suggested Edits History" />
                  <div className="flex flex-row gap-x-2 divide-x">
                    <ExpandCollapseButtons
                      buttonClasses="p-2"
                      onExpand={() => setExpandedForAll(true)}
                      onCollapse={() => setExpandedForAll(false)}
                      isDisabled={false}
                      labelText="Suggested Edits"
                    />
                  </div>
                </div>
                {reversedRedlines.map((redline, index) => {
                  if (index === reversedRedlines.length - 1) {
                    return <></>;
                  }
                  return (
                    <SuggestedEditHistoryItem
                      key={redline.redline_id}
                      redline={redline}
                      previousRedline={reversedRedlines[index + 1]}
                      isExpanded={Boolean(redlineExpandedMap.get(redline.redline_id))}
                      onToggleExpandCollapse={() => toggleExpanded(redline.redline_id)}
                    />
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FullStepSuggestedEditsModal;
