import { useMemo } from 'react';
import StepDependencies from './StepDependency/StepDependencies';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldStepTimer from './FieldStepTimer';
import FieldStepDuration from './FieldStepDuration';
import { STEP_STATE, isStepEnded } from 'shared/lib/runUtil';
import { Duration } from 'luxon';
import Tooltip from '../elements/Tooltip';

const ProcedureStepBanner = ({
  step,
  isRun,
  hasDependencies,
  isSingleCardEnabled,
  areConditionalAndStepDependenciesFulfilled,
  onRefChanged,
  scrollMarginTopValueRem,
  updateStepDetail,
  baseRunningCondition,
  isStepActive,
  stepState,
  isAddedStep = false,
  addedStepTooltip,
}) => {
  const timerDurationStarted = useMemo(() => {
    if (step.duration && typeof step.duration === 'object') {
      if (step.duration.started_at) {
        return true;
      } else {
        return false;
      }
    }

    if (step.timer && typeof step.timer === 'object') {
      if (step.timer.started_at) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  }, [step.timer, step.duration]);

  const bgColor = useMemo(() => {
    if (!areConditionalAndStepDependenciesFulfilled || (hasDependencies && !isRun)) {
      return 'bg-gray-100 border-2 border-slate-300';
    } else if (stepState === STEP_STATE.FAILED) {
      return 'bg-red-200 border-2 border-red-400 border-b-slate-300';
    } else if (stepState === STEP_STATE.SKIPPED) {
      return 'bg-app-gray-400 border-2 border-app-gray-400 border-b-slate-300';
    } else if (stepState === STEP_STATE.COMPLETED) {
      return 'bg-app-green-200 border-2 border-green-400 border-b-slate-300';
    } else if (timerDurationStarted) {
      return 'bg-blue-100 border-2 border-slate-300';
    } else {
      return 'bg-white border-2 border-slate-300';
    }
  }, [areConditionalAndStepDependenciesFulfilled, hasDependencies, isRun, stepState, timerDurationStarted]);

  const timerMessage = useMemo(() => {
    if (!step.timer) {
      return;
    }

    let output = 'Start Timer';
    if (step.timer.completed) {
      output = 'Timer Elapsed';
    } else if (isStepEnded(step)) {
      output = 'Timer Not Completed';
    } else if (step.timer.started_at) {
      output = 'Timer Running';
    }

    const timeLeft = step.timer.time_left;
    if (!timeLeft) {
      return output;
    }

    const formattedTimeLeft = Duration.fromISO(timeLeft).toFormat('hh:mm:ss');
    return `${output} (${formattedTimeLeft})`;
  }, [step]);

  return (
    <tbody
      data-testid="header"
      className={`${bgColor} shadow z-0`}
      ref={onRefChanged}
      style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
      aria-label="Step Header"
      role="region"
    >
      <tr>
        <td colSpan={3} className="py-2 pr-2">
          <div className="w-full flex flex-row justify-between">
            <div className="flex flex-row flex-grow">
              {step.requires_previous && (
                <span className="ml-4 mr-3 font-semibold text-xs text-gray-600 whitespace-nowrap">
                  Requires Previous Step
                </span>
              )}
              {step['duration'] && typeof step['duration'] === 'object' && (
                <div className="ml-2 mt-1">
                  <Tooltip
                    content={step.duration.started_at ? 'Step Started' : 'Requires Step to be Started'}
                    visible={true}
                  >
                    <FontAwesomeIcon icon="stopwatch" />
                  </Tooltip>
                </div>
              )}
              {step['timer'] && typeof step['timer'] === 'object' && (
                <div className="ml-2 mt-1">
                  <FontAwesomeIcon icon="hourglass-start" />
                  <span className="ml-1 mr-3 text-gray-600">{timerMessage}</span>
                </div>
              )}
              {!isSingleCardEnabled && hasDependencies && (
                <div className="ml-2 mt-0.5">
                  <StepDependencies dependencies={step.dependencies} step={step} />
                </div>
              )}
              {isAddedStep && (
                <Tooltip content={addedStepTooltip}>
                  <div className="ml-4 flex flex-row gap-x-1 whitespace-nowrap">
                    <div className={`pt-[0.29rem] text-sm ${step.run_only ? 'text-blue-500' : 'text-red-500'}`}>
                      <FontAwesomeIcon icon="strikethrough" aria-label={`${step.run_only ? 'Blueline' : 'Redline'}`} />
                    </div>
                    <div className="pt-[0.22rem] text-sm font-bold text-gray-600 italic">Added Step</div>
                  </div>
                </Tooltip>
              )}
            </div>
            <div className="flex flex-row items-center">
              {step['duration'] && typeof step['duration'] === 'object' && (
                <FieldStepDuration
                  value={step['duration']}
                  disabled={!baseRunningCondition}
                  timingPresent={typeof step['timer'] === 'object'}
                  updateStepDetail={updateStepDetail}
                  isPaused={!isStepActive}
                />
              )}
              {step['timer'] && typeof step['timer'] === 'object' && (
                <FieldStepTimer
                  value={step['timer']}
                  disabled={!baseRunningCondition}
                  updateStepDetail={updateStepDetail}
                />
              )}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  );
};

export default ProcedureStepBanner;
