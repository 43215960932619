import StepDependencies from './StepDependencies';
import TooltipOverlay from '../TooltipOverlay';
import { Step } from 'shared/lib/types/views/procedures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Requirements = ({ isTooltipEnabled }) => {
  return (
    <span className="font-semibold text-xs text-gray-600 whitespace-nowrap">
      Requirements Unmet
      {isTooltipEnabled && <FontAwesomeIcon className="text-gray-500 self-center ml-1" icon="info-circle" />}
    </span>
  );
};

interface RequirementsOverlayProps {
  step: Step;
  isTooltipEnabled: boolean;
}

const RequirementsOverlay = ({ step, isTooltipEnabled }: RequirementsOverlayProps) => {
  return (
    <>
      {!isTooltipEnabled && <Requirements isTooltipEnabled={isTooltipEnabled} />}
      {isTooltipEnabled && (
        <TooltipOverlay
          content={
            <div className="px-1 text-sm font-medium">
              <StepDependencies dependencies={step.dependencies} step={step} />
            </div>
          }
        >
          <Requirements isTooltipEnabled={isTooltipEnabled} />
        </TooltipOverlay>
      )}
    </>
  );
};

export default RequirementsOverlay;
