import React, { Fragment, useCallback } from 'react';
import BlockText from './Blocks/BlockText';
import ProcedureBlockRun from './Blocks/ProcedureBlockRun';
import CommentWrapper from './CommentWrapper';

const ProcedureStepHeader = ({ header, onRefChanged, scrollMarginTopValueRem }) => {
  const onStepHeaderRefChanged = useCallback(
    (element) => {
      if (typeof onRefChanged === 'function') {
        onRefChanged(header.id, element);
      }
    },
    [header, onRefChanged]
  );

  return (
    <>
      <tbody
        data-testid={`header ${header.id}`}
        className="p-10 bg-slate-300 border-2 border-slate-300 shadow-lg shadow z-0"
        // ref and style for scrolling need to be in tbody, since the step header name may not be present
        ref={onStepHeaderRefChanged}
        style={{ scrollMarginTop: `${scrollMarginTopValueRem}rem` }}
        aria-label="Step Header"
        role="region"
      >
        {header.name && (
          <tr>
            <td></td>
            <td colSpan={2} className="pt-2 pr-8 font-semibold">
              <div className="w-full whitespace-pre-line min-w-0 break-words">{header.name}</div>
            </td>
          </tr>
        )}
        {header.content &&
          header.content.map((headerContent, headerContentIndex) => {
            return (
              <CommentWrapper content={headerContent}>
                <Fragment key={headerContentIndex}>
                  {headerContent.type.toLowerCase() === 'text' && <BlockText block={headerContent} isHidden={false} />}
                  {headerContent.type.toLowerCase() === 'alert' && (
                    <ProcedureBlockRun
                      block={headerContent}
                      isHidden={false}
                      isDark={true}
                      blockLabel={undefined}
                      redlines={undefined}
                      recorded={undefined}
                      contentIndex={undefined}
                      isEnabled={undefined}
                      onRecordValuesChanged={undefined}
                      onRecordErrorsChanged={undefined}
                      onRecordUploadingChanged={undefined}
                      actions={undefined}
                      isSpacerHidden={undefined}
                      onContentRefChanged={undefined}
                      scrollMarginTopValueRem={undefined}
                      onAcceptPendingRedline={undefined}
                    />
                  )}
                </Fragment>
              </CommentWrapper>
            );
          })}
        <tr>
          <td colSpan={3} className="h-2"></td>
        </tr>
      </tbody>

      {/* Create spacing between the header and the step, and add the down arrow in this space */}

      <tbody className="border-none">
        <tr>
          <td></td>
          <td className="relative">
            <div className="ml-5 callout-triangle-down z-10" />
          </td>
        </tr>
        <tr className="h-1"></tr>
      </tbody>
    </>
  );
};

export default ProcedureStepHeader;
