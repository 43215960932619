import cloneDeep from 'lodash.clonedeep';
import {
  generateCommentId,
  generateRedlineDocId,
  generateRunId,
} from './idUtil';
import {
  getStepById,
  hasStep,
  isReleased,
  isSuggestEditsStepSettingEnabled,
  isTokenizedTextBlock,
} from './procedureUtil';
import {
  displaySectionStepKey as procedureSectionStepKey,
  displaySectionKey as procedureSectionKey,
} from './procedureUtil';
import signoffUtil, { SIGNOFF_ACTION_TYPE } from './signoffUtil';
import {
  Procedure,
  Recorded,
  RedlinedStep,
  Run,
  RunAction,
  RunStep,
  RunStepBlock,
  RunStepComment,
  RunStepFullRedline,
  RunTableInputBlock,
  RunTextBlock,
  Step,
  StepRevokeSignoffAction,
  TableInputBlock,
} from './types/views/procedures';
import { RunState } from './types/couch/procedures';
import lodash from 'lodash';
import { validateCanEditComment, wasEdited } from './comment';
import tableUtil from './tableUtil';
import ProcedureGraph from './ProcedureGraph';
import { updateStepWithAction } from './runStepUtil';
import { getRedlineId, newStepRedline, REDLINE_TYPE } from './redlineUtil';

export const RUN_STATE = {
  RUNNING: 'running',
  COMPLETED: 'completed',
  PAUSED: 'paused',
} as const;

export const ACTION_TYPE = {
  ...SIGNOFF_ACTION_TYPE,
  FAIL: 'fail',
  SKIP: 'skip',
  PAUSE: 'pause',
  ISSUE_PAUSE: 'issue pause',
  RISK_PAUSE: 'risk pause',
  ALL_ISSUES_RESOLVED: 'all issues resolved',
  ALL_RISKS_ACCEPTED: 'all risks accepted',
  RESUME: 'resume',
  COMPLETE: 'complete',
  ISSUE: 'issue',
  AUTOMATION_START: 'automation_start',
  AUTOMATION_PAUSE: 'automation_pause',
  AUTOMATION_RESUME: 'automation_resume',
  STEP_ADDED: 'step added',
  REOPEN: 'reopen',
  REDLINE_ADDED: 'redline_added',
  BLUELINE_ADDED: 'blueline_added',
  REDLINE_INCLUDED: 'redline_included',
  BLUELINE_INCLUDED: 'blueline_included',
} as const;

/*
 * Do not change `ACTIVE_RUN_STATES` while the front end still make direct
 * queries to couchdb.
 *
 * This exists for specific couchdb queries that are based on
 * "in ['running', 'paused']".
 * If the values considered running ever changed, an entirely new index will
 * need to be created because changing this value is not backward-compatible.
 *
 * Changing this value could lead to new organizations being created with
 * indexes that are not consistent with the queries being run against them,
 * causing run listing to fail.
 */
export const ACTIVE_RUN_STATES = [RUN_STATE.RUNNING, RUN_STATE.PAUSED] as const;

export const RUN_STATUS = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  ABORT: 'abort',
} as const;

export const STEP_STATE = {
  COMPLETED: 'completed',
  FAILED: 'failed',
  SKIPPED: 'skipped',
  PAUSED: 'paused',
  INCOMPLETE: '',
} as const;

type StepState = (typeof STEP_STATE)[keyof typeof STEP_STATE];

export const getStepState = (step: RunStep): StepState | undefined => {
  if (step.completed) {
    return STEP_STATE.COMPLETED;
  }
  if (step.skipped) {
    return STEP_STATE.SKIPPED;
  }
  return step.state;
};

export const isStepEnded = (step: RunStep): boolean => {
  const stepState = getStepState(step);
  return (
    [
      STEP_STATE.COMPLETED,
      STEP_STATE.SKIPPED,
      STEP_STATE.FAILED,
    ] as Array<StepState>
  ).includes(stepState as StepState);
};

export const isRunStateActive = (runState: RunState | undefined) => {
  return ACTIVE_RUN_STATES.includes(
    runState as (typeof ACTIVE_RUN_STATES)[number]
  );
};

export const isStepStateEnded = (stepState: StepState | undefined): boolean => {
  if (stepState === 'paused') {
    return false;
  }
  return Boolean(stepState);
};

// Only first operator can record data when signoffs are required.
export const cannotUpdateStep = (step: RunStep) => {
  return (
    signoffUtil.isSignoffRequired(step.signoffs) &&
    (isStepEnded(step) || signoffUtil.anySignoffsComplete(step))
  );
};

const _isStepEnded = (run: Run, sectionIndex: number, stepIndex: number) => {
  const step = run.sections[sectionIndex].steps[stepIndex];
  return isStepEnded(step);
};

/**
 * Creates an initial version of a run doc for a given procedure.
 *
 * Returns a newly created run doc that is not yet saved to the database.
 * Callers are responsible for saving the new run doc after making any
 * additional changes.
 */
export const newRunDoc = ({
  procedure,
  userId,
  method = 'web',
  userParticipantType,
}: {
  procedure: object;
  userId?: string;
  method?: 'web' | 'api';
  userParticipantType?: 'participant' | 'viewer';
}) => {
  if (!isReleased(procedure as Procedure)) {
    throw new Error('Cannot run unreleased procedure');
  }
  return runFromProcedure({ procedure, userId, method, userParticipantType });
};

export const newPreviewRunDoc = ({
  procedure,
  userId,
  method = 'web',
  userParticipantType,
}: {
  procedure: object;
  userId: string;
  method?: 'web' | 'api';
  userParticipantType?: 'participant' | 'viewer';
}) => {
  // @ts-expect-error: `Procedure` type needs to be moved from web to shared
  const reviewComments = (procedure.comments ?? []).filter(
    (comment) => comment.type === 'review_comment'
  );
  const previewRun = runFromProcedure({
    procedure,
    userId,
    method,
    userParticipantType,
  });
  previewRun.comments = reviewComments;
  return previewRun;
};

const runFromProcedure = ({
  procedure,
  userId,
  method = 'web',
  userParticipantType,
}: {
  procedure: object;
  userId?: string;
  method?: 'web' | 'api';
  userParticipantType?: 'participant' | 'viewer';
}) => {
  const run = cloneDeep(procedure);
  run._id = generateRunId();
  // @ts-expect-error: `Procedure` type needs to be moved from web to shared
  run.procedure = procedure._id;
  // @ts-expect-error: `Procedure` type needs to be moved from web to shared
  run.procedureRev = procedure._rev;
  run.state = RUN_STATE.RUNNING;
  run.starttime = new Date().toISOString();
  run.started_by = {
    method,
    user_id: userId ?? null,
  };
  if (userId && userParticipantType) {
    run.participants = [
      {
        user_id: userId,
        created_at: run.starttime,
        type: userParticipantType,
      },
    ];
  }
  delete run._rev;
  delete run.comments;
  delete run.actions;

  /**
   * Delete pending block redlines.
   *
   * When editing a procedure, redlines that are pending (not ignored or
   * accepted), are stored as block level redlines and persisted to the
   * procedure when saved. This allows redlines to follow the blocks when
   * dragging and dropping, and also ensures that the pending redlines
   * are not lost when further run changes are made. This also means we
   * need to strip them out when creating a run doc from this procedure.
   */
  run.sections.forEach((section) => {
    section.steps.forEach((step) => {
      step.content.forEach((content) => {
        // @ts-ignore: OK to call even if `redlines` isn't defined
        delete content.redlines;
      });
    });
  });
  return run;
};

const _updateDocWithRunComment = (runDoc, userId, text, createdAt) => {
  // Don't save empty comments
  if (!text) {
    return;
  }
  if (!runDoc.comments) {
    runDoc.comments = [];
  }
  runDoc.comments.push({
    text,
    user_id: userId,
    created_at: createdAt,
  });
};

export const getSectionIndex = (run: Run, sectionId: string): number => {
  const sectionIndex = run.sections.findIndex(
    (section) => section.id === sectionId
  );
  if (sectionIndex === -1) {
    throw new Error(`Invalid section index for section ID ${sectionId}.`);
  }
  return sectionIndex;
};

export const getStepIndex = (
  run: Run,
  stepId: string,
  sectionIndex: number
): number => {
  if (sectionIndex < 0 || sectionIndex >= run.sections.length) {
    throw new Error(`Invalid section index when getting step ID ${stepId}.`);
  }
  const stepIndex = run.sections[sectionIndex].steps.findIndex(
    (step) => step.id === stepId
  );
  if (stepIndex === -1) {
    throw new Error(`Invalid step index for step ID ${stepId}.`);
  }
  return stepIndex;
};

/**
 * Searches a run document for the given section and steps by id.
 * @throws Error getting the section index or step index, if either the section or step ID cannot be found.
 */
const getSectionAndStepIndices = (
  run: Run,
  sectionId: string,
  stepId: string
): { sectionIndex: number; stepIndex: number } => {
  const sectionIndex = getSectionIndex(run, sectionId);
  const stepIndex = getStepIndex(run, stepId, sectionIndex);
  return { sectionIndex, stepIndex };
};

const _getCommentIdForNewComment = ({
  commentList,
  comment,
}: {
  commentList: Array<RunStepComment>;
  comment: RunStepComment;
}): string => {
  // Generate a commentId if it is not provided
  if (!comment.id) {
    return generateCommentId();
  }

  // Check if duplicate ids exist.
  const existingComment = commentList.find(
    (_comment) => _comment.id === comment.id
  );
  if (existingComment) {
    if (existingComment.text === comment.text) {
      throw new Error('Duplicate id and text');
    }
    // If text is not the same then assign a new id to the new text.
    return generateCommentId();
  }

  return comment.id;
};

const _validateEditComment = ({
  userId,
  comment,
}: {
  userId: string;
  comment: RunStepComment;
}) => {
  if (!comment.id) {
    throw new Error('Edited comment must have id.');
  }

  if (comment.user !== userId) {
    throw new Error('Only the author of a comment can edit the comment.');
  }
};

const _editStepComment = ({
  userId,
  step,
  comment,
}: {
  userId: string;
  step: RunStep;
  comment: RunStepComment;
}) => {
  _validateEditComment({ userId, comment });

  if (!step.comments || step.comments.length === 0) {
    throw new Error('There are no comments available to edit.');
  }

  const commentIndex = step.comments.findIndex(
    (existingComment) => existingComment.id === comment.id
  );

  if (commentIndex === -1) {
    throw new Error('Comment not found.');
  }

  validateCanEditComment(comment.timestamp, comment.updated_at as string);

  step.comments[commentIndex] = comment;
  return true;
};

const _addStepComment = ({
  userId,
  step,
  comment,
}: {
  userId: string;
  step: RunStep;
  comment: RunStepComment;
  timestamp?: string;
}) => {
  if (!step.comments) {
    step.comments = [];
  }
  const commentId = _getCommentIdForNewComment({
    commentList: step.comments,
    comment,
  });

  step.comments.push({
    ...comment,
    id: commentId,
    user: userId,
  });

  return true;
};

const _updateDocWithStepComment = ({
  userId,
  step,
  comment,
}: {
  userId: string;
  step: RunStep;
  comment: RunStepComment;
}) => {
  if (wasEdited(comment.timestamp, comment.updated_at)) {
    return _editStepComment({ userId, step, comment });
  }

  return _addStepComment({ userId, step, comment });
};

const _editTableComment = ({
  userId,
  block,
  rowIndex,
  columnIndex,
  comment,
}: {
  userId: string;
  block: RunTableInputBlock;
  rowIndex: number;
  columnIndex: number;
  comment: RunStepComment;
}) => {
  if (lodash.isNil(rowIndex) || lodash.isNil(columnIndex)) {
    throw new Error('Invalid indices');
  }

  if (block.columns[columnIndex].column_type !== 'comment') {
    throw new Error('Invalid column type');
  }

  if (!block.cells) {
    throw new Error('Missing cells');
  }

  _validateEditComment({ userId, comment });

  const commentList = block.cells[rowIndex][
    columnIndex
  ] as Array<RunStepComment>;

  if (!commentList || commentList.length === 0) {
    throw new Error('There are no comments available to edit.');
  }

  const commentIndex = commentList.findIndex(
    (_comment) => _comment.id === comment.id
  );
  if (commentIndex === -1) {
    throw new Error('Comment not found.');
  }

  validateCanEditComment(comment.timestamp, comment.updated_at as string);

  commentList[commentIndex] = comment;

  return true;
};

const _addTableComment = ({
  userId,
  block,
  rowIndex,
  columnIndex,
  comment,
}) => {
  if (lodash.isNil(rowIndex) || lodash.isNil(columnIndex)) {
    return false;
  }

  const commentList = block.cells[rowIndex][columnIndex];

  const commentId = _getCommentIdForNewComment({
    commentList,
    comment,
  });

  commentList.push({
    ...comment,
    id: commentId,
    user: userId,
  });

  return true;
};

const _updateDocWithTableComment = ({
  userId,
  block,
  rowIndex,
  columnIndex,
  comment,
}: {
  userId: string;
  block: TableInputBlock;
  rowIndex: number;
  columnIndex: number;
  comment: RunStepComment;
}): boolean => {
  if (
    !block.columns[columnIndex] ||
    block.columns[columnIndex].column_type !== 'comment'
  ) {
    return false;
  }
  if (wasEdited(comment.timestamp, comment.updated_at)) {
    return _editTableComment({ userId, block, rowIndex, columnIndex, comment });
  }

  return _addTableComment({ userId, block, rowIndex, columnIndex, comment });
};

export const updateDocWithComment = ({
  userId,
  doc,
  sectionId,
  stepId,
  contentId,
  rowIndex,
  columnIndex,
  comment,
}: {
  userId: string;
  doc: Run;
  sectionId: string;
  stepId: string;
  contentId?: string;
  rowIndex?: number;
  columnIndex?: number;
  comment: RunStepComment;
}): boolean => {
  let sectionIndex: number;
  let stepIndex: number;
  try {
    ({ sectionIndex, stepIndex } = getSectionAndStepIndices(
      doc,
      sectionId,
      stepId
    ));
  } catch (e) {
    return false;
  }

  // Set parent id if it does not exist
  if (!comment.parent_id) {
    comment.parent_id = '';
  }

  if (contentId) {
    const block = doc.sections[sectionIndex].steps[stepIndex].content.find(
      (block) => block.id === contentId
    );
    if (!block) {
      return false;
    }

    if (
      block.type === 'table_input' &&
      !lodash.isNil(rowIndex) &&
      !lodash.isNil(columnIndex)
    ) {
      try {
        return _updateDocWithTableComment({
          userId,
          block,
          rowIndex,
          columnIndex,
          comment,
        });
      } catch (e) {
        return false;
      }
    }

    return false;
  }

  const step = doc.sections[sectionIndex].steps[stepIndex];
  try {
    return _updateDocWithStepComment({
      userId,
      step,
      comment,
    });
  } catch {
    return false;
  }
};

const _updateDocWithRecorded = (
  doc: Run,
  sectionIndex: number,
  stepIndex: number,
  recorded: Recorded
) => {
  const step = doc.sections[sectionIndex].steps[stepIndex];

  if (cannotUpdateStep(step)) {
    return;
  }

  // Add recorded data
  if (recorded) {
    Object.keys(recorded).forEach((contentIndex) => {
      if (
        doc.sections[sectionIndex].steps[stepIndex].content[contentIndex] &&
        recorded[contentIndex]
      ) {
        const content =
          doc.sections[sectionIndex].steps[stepIndex].content[contentIndex];

        if (content.type === 'field_input_table') {
          content.fields.forEach((field, fieldIndex) => {
            field.recorded = recorded[contentIndex][fieldIndex];
          });
        } else {
          content.recorded = recorded[contentIndex];
        }
      }
    });
  }
};

const _updateDocWithStepSkipped = (
  runDoc,
  userId,
  skippedAt,
  recorded,
  sectionIndex,
  stepIndex
) => {
  // Add recorded info
  _updateDocWithRecorded(runDoc, sectionIndex, stepIndex, recorded);

  runDoc.sections[sectionIndex].steps[stepIndex].skipped = true;
  runDoc.sections[sectionIndex].steps[stepIndex].skippedAt = skippedAt;
  runDoc.sections[sectionIndex].steps[stepIndex].skippedUserId = userId;
};

const _updateDocWithSectionSkipped = (
  runDoc,
  userId,
  skippedAt,
  recordedAllSectionSteps,
  sectionIndex
) => {
  runDoc.sections[sectionIndex]?.steps.forEach((_step, stepIndex) => {
    if (!_isStepEnded(runDoc, sectionIndex, stepIndex)) {
      const recorded = recordedAllSectionSteps?.steps[stepIndex]?.recorded;
      _updateDocWithStepSkipped(
        runDoc,
        userId,
        skippedAt,
        recorded,
        sectionIndex,
        stepIndex
      );
    }
  });
};

const _updateDocWithUnfinishedStepsSkipped = (
  runDoc,
  userId,
  recordedAllSteps,
  skippedAt
) => {
  runDoc.sections.forEach((_section, sectionIndex) => {
    const recordedAllSectionSteps = recordedAllSteps?.sections[sectionIndex];
    _updateDocWithSectionSkipped(
      runDoc,
      userId,
      skippedAt,
      recordedAllSectionSteps,
      sectionIndex
    );
  });
};

const _updateDocWithRunStatus = (runDoc, status) => {
  // Only allow saving defined run statuses
  if (Object.values(RUN_STATUS).includes(status)) {
    runDoc.status = status;
  }
  if (runDoc.automation_status) {
    delete runDoc.automation_status;
  }
};

const _updateDocWithRunCompletion = (
  runDoc,
  userId,
  recordedAllSteps,
  endedAt
) => {
  _updateDocWithUnfinishedStepsSkipped(
    runDoc,
    userId,
    recordedAllSteps,
    endedAt
  );

  runDoc.state = RUN_STATE.COMPLETED;
  runDoc.completedAt = endedAt;
  runDoc.completedUserId = userId;
};

export const updateDocWithEndRun = (
  runDoc,
  userId,
  comment,
  status,
  recordedAllSteps,
  endedAt
) => {
  // If run is already completed, drop this request.
  if (runDoc.state === RUN_STATE.COMPLETED) {
    return false;
  }

  _updateDocWithRunComment(runDoc, userId, comment, endedAt);
  _updateDocWithRunStatus(runDoc, status);
  _updateDocWithRunCompletion(runDoc, userId, recordedAllSteps, endedAt);

  // Doc was modified
  return true;
};

export const updateDocWithAction = ({
  runDoc,
  type,
  userId,
  timestamp,
  comment,
  context,
}: {
  runDoc: Run;
  type: string;
  userId: string;
  timestamp?: string;
  comment?: string;
  context?: object;
}) => {
  if (!runDoc.actions) {
    runDoc.actions = [];
  }
  runDoc.actions.push({
    type,
    user_id: userId,
    timestamp: timestamp ?? new Date().toISOString(),
    ...(comment && { comment }),
    ...(context && { context }),
  } as RunAction);
};

export const updateDocWithRunReopened = ({
  runDoc,
  userId,
  comment,
  timestamp,
}: {
  runDoc: Run;
  userId: string;
  comment: string;
  timestamp: string;
}) => {
  if (runDoc.state !== RUN_STATE.COMPLETED) {
    return;
  }
  _updateDocWithRunComment(runDoc, userId, comment, timestamp);
  updateDocWithAction({
    runDoc,
    type: ACTION_TYPE.REOPEN,
    userId,
    comment,
    timestamp,
  });
  runDoc.state = RUN_STATE.RUNNING;
  delete runDoc.status;
  delete runDoc.completedAt;
  delete runDoc.completedUserId;
};

// Returns copy of step without recorded, signoff, state, or completion data
export const copyStepWithoutActiveContent = (
  step: Step | RedlinedStep
): Step => {
  const updated = cloneDeep(step);
  if (updated.content) {
    for (let i = 0; i < updated.content.length; i++) {
      if (updated.content[i].type === 'procedure_link') {
        delete updated.content[i].run; // delete linked runs
      }
      if (updated.content[i].type === 'table_input') {
        updated.content[i].cells = tableUtil.getInitialCells(
          updated.content[i]
        );
      }
      if (updated.content[i].type === 'field_input_table') {
        updated.content[i].fields.forEach((field) => {
          delete field.recorded;
        });
      }
      delete updated.content[i].recorded; // delete recorded data
    }
  }

  // Clear duration.
  if (updated.duration && typeof updated.duration === 'object') {
    updated.duration = {
      started_at: '',
      duration: '',
    };
  }

  // Clear timer
  if (updated.timer && typeof updated.timer === 'object') {
    const { time_left } = updated.timer;
    updated.timer = {
      started_at: '',
      completed: false,
      time_remaining: '',
      time_left,
    };
  }

  const toDelete = [
    'actions',
    'completed',
    'completedUserId',
    'completedAt',
    'skipped',
    'skippedUserId',
    'skippedAt',
    'repeated_user_id',
    'repeated_at',
    'repeat_of',
    'comments',
    'state',
  ];
  toDelete.forEach((e) => delete updated[e]);
  return updated;
};

/**
 * Create a new step in a run. (Dynamically added steps.)
 *
 * @param {Object} step - The new step object.
 * @param {String} createdAt - Timestamp when the step was created.
 * @param {String} createdBy - Id of user that created the step.
 * @param {Boolean} runOnly - whether the redline will display in the draft
 * @returns {Object} step - New updated step object for insertion into the run doc.
 */
const _createRunStep = (step, createdAt, createdBy, runOnly): RunStep => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return {
    ...step,
    // Do not change the redline id if it already exists.
    redline_id: step.redline_id ?? generateRedlineDocId(),
    created_at: createdAt,
    created_by: createdBy,
    created_during_run: true,
    ...(runOnly && { run_only: runOnly }),
    actions: [
      {
        type: ACTION_TYPE.STEP_ADDED,
        user_id: createdBy,
        timestamp: createdAt,
      },
    ],
  };
};

/**
 * @returns whether the document was updated.
 */
const _insertStep = (doc, stepToAdd, sectionId, precedingStepId): boolean => {
  // Don't allow adding steps if the run has ended
  if (doc.state === RUN_STATE.COMPLETED) {
    return false;
  }

  // Don't allow adding a step that already exists
  if (hasStep(doc, stepToAdd.id)) {
    return false;
  }

  const sectionIndex = doc.sections.findIndex(
    (section) => section.id === sectionId
  );
  const steps = doc.sections[sectionIndex].steps;

  // If preceding step id is null, index will be -1 and the step is inserted at the start of the section
  const precedingStepIndex = steps.findIndex(
    (step) => step.id === precedingStepId
  );

  // Lifted in scope to be used outside of for loop
  let trailingStepIndex = precedingStepIndex + 1;

  // Advance trailing to the last repeat of the specified step.
  for (; trailingStepIndex < steps.length; trailingStepIndex += 1) {
    const step = steps[trailingStepIndex];
    if (!step.repeat_of) {
      break;
    }
  }

  // Insert step after the preceding step and its additions or repeats
  doc.sections[sectionIndex].steps.splice(trailingStepIndex, 0, stepToAdd);
  return true;
};

export const updateDocWithAddedStep = ({
  runDoc,
  sectionId,
  precedingStepId,
  step,
  createdAt,
  userId,
  runOnly,
}) => {
  const stepToAdd = _createRunStep(step, createdAt, userId, runOnly);
  return _insertStep(runDoc, stepToAdd, sectionId, precedingStepId);
};

const _repeatsUpToIndex = (repeatables, index) => {
  return repeatables.filter((elem, i) => i <= index && elem.repeat_of).length;
};

export const updateDocWithFullStepRedline = ({
  runDoc,
  sectionId,
  stepId,
  redline,
  userId,
  includeInRun,
}: {
  runDoc: Run;
  sectionId: string;
  stepId: string;
  redline: RunStepFullRedline;
  userId: string;
  includeInRun: boolean;
}) => {
  if (!runDoc) {
    throw new Error('Missing run document');
  }
  // If run is already completed, drop this request.
  if (runDoc.state === RUN_STATE.COMPLETED) {
    return false;
  }

  const existingStep = getStepById(runDoc, sectionId, stepId) as RunStep;
  if (
    !isSuggestEditsStepSettingEnabled({
      step: existingStep,
      procedure: runDoc,
    })
  ) {
    return false;
  }
  // The redline needs to be copied so that the front-end updates via redux do not get passed directly to the backend.
  const redlineCopy = lodash.cloneDeep(redline);

  if (!existingStep.redlines) {
    const originalPseudoBlueline = newStepRedline({
      step: existingStep,
      userId,
      pending: false,
      fieldOrBlockMetadata: {},
      isRedline: false,
      createdAt: redlineCopy.created_at ?? redlineCopy.createdAt,
      type: REDLINE_TYPE.FULL_STEP,
    }) as RunStepFullRedline;

    originalPseudoBlueline.is_original = true;
    // For purposes of record-keeping and diffing, store the original step as a pseudo blueline at the beginning of the redlines array.
    existingStep.redlines = [originalPseudoBlueline];
  }

  existingStep.redlines.push(redlineCopy);

  const isRedline = !redlineCopy.run_only;
  updateStepWithAction({
    step: existingStep,
    type: isRedline ? ACTION_TYPE.REDLINE_ADDED : ACTION_TYPE.BLUELINE_ADDED,
    userId,
    timestamp:
      redlineCopy.created_at ??
      redlineCopy.createdAt ??
      new Date().toISOString(),
    context: {
      redline_id: getRedlineId(redline),
    },
  });

  if (includeInRun) {
    includeFullStepRedline({
      runDoc,
      userId,
      sectionId,
      stepId,
      redlineId: getRedlineId(redlineCopy) ?? '',
      includedAt: redlineCopy.created_at ?? new Date().toISOString(),
      addAction: false,
    });
  }

  return true;
};

export const includeFullStepRedline = ({
  runDoc,
  userId,
  sectionId,
  stepId,
  redlineId,
  includedAt,
  addAction = true,
}: {
  runDoc: Run;
  userId: string;
  sectionId: string;
  stepId: string;
  redlineId: string;
  includedAt: string;
  addAction?: boolean;
}) => {
  const { sectionIndex, stepIndex } = getSectionAndStepIndices(
    runDoc,
    sectionId,
    stepId
  );

  const existingStep = runDoc.sections[sectionIndex].steps[stepIndex];
  if (!redlineId || cannotUpdateStep(existingStep)) {
    return false;
  }
  const redline = existingStep.redlines?.find(
    (redline) => redline.redline_id === redlineId
  );
  if (!redline || !redline.pending) {
    return false;
  }
  // Replace the step values with values from the redline.
  runDoc.sections[sectionIndex].steps[stepIndex] = {
    ...existingStep,
    ...redline.step,
    redline_id: redline.redline_id,
  };
  if (addAction) {
    const isRedline = !redline.run_only;
    updateStepWithAction({
      step: runDoc.sections[sectionIndex].steps[stepIndex],
      type: isRedline
        ? ACTION_TYPE.REDLINE_INCLUDED
        : ACTION_TYPE.BLUELINE_INCLUDED,
      userId,
      timestamp: includedAt,
      context: {
        redline_id: redlineId,
      },
    });
  }

  // Mark the redline as included.
  redline.pending = false;

  return true;
};

export const displaySectionKey = (sections, sectionIndex, style) => {
  const repeatsUpToIndex = _repeatsUpToIndex(sections, sectionIndex);
  return procedureSectionKey(sectionIndex - repeatsUpToIndex, style);
};

export const displaySectionStepKey = (
  sections,
  sectionIndex,
  stepIndex,
  style
) => {
  const repeatsSectionUpToIndex =
    sectionIndex - _repeatsUpToIndex(sections, sectionIndex);
  const repeatsStepUpToIndex =
    stepIndex - _repeatsUpToIndex(sections[sectionIndex].steps, stepIndex);
  return procedureSectionStepKey(
    repeatsSectionUpToIndex,
    repeatsStepUpToIndex,
    style
  );
};

export const getStepDependenciesMap = (run) => {
  const map = new Map();
  run.sections.forEach((section) => {
    section.steps.forEach((step: Step) => {
      if (step.dependencies) {
        let allDependencies: Array<string> = [];
        step.dependencies.forEach((dependency) => {
          if (dependency?.dependent_ids) {
            allDependencies = allDependencies.concat(dependency?.dependent_ids);
          }
        });
        if (allDependencies.length !== 0) {
          map.set(step.id, allDependencies);
        }
      }
    });
  });
  return map;
};

/**
 * Gets a map where the keys are step IDs and the values are steps that depend on it as opposed to
 * getStepDependenciesMap where the values are steps that the step depends on.
 *
 * NOTE: Calculated separately from getDependencyMaps since it is not used very frequently.
 */
export const getTargetStepDependenciesMap = (run) => {
  const map = {};
  run.sections?.forEach((section) => {
    section.steps?.forEach((step: Step) => {
      if (step.dependencies) {
        step.dependencies.forEach((dependency) => {
          if (dependency.dependent_ids) {
            dependency.dependent_ids.forEach((dependentId) => {
              if (!Object.prototype.hasOwnProperty.call(map, dependentId)) {
                map[dependentId] = new Set<string>();
              }
              map[dependentId].add(step.id);
            });
          }
        });
      }
      if (step.conditionals) {
        step.conditionals.forEach((conditional) => {
          if (
            !Object.prototype.hasOwnProperty.call(map, conditional.target_id)
          ) {
            map[conditional.target_id] = new Set<string>();
          }
          map[conditional.target_id].add(step.id);
        });
      }
    });
  });
  return map;
};

export const buildStepMap = (run) => {
  const map = {};
  run.sections.forEach((section) => {
    section.steps.forEach((step) => {
      map[step.id] = step;
    });
  });
  return map;
};

export const IRREVOCABLE_BLOCK_TYPE_SET = new Set([
  'part_kit',
  'part_build',
  'part_usage',
  'tool_check_out',
  'tool_check_in',
  'tool_usage',
]);

const _commonValidateSignoff = ({
  run,
  signoffId,
  step,
  userOperatorRolesSet,
}: {
  run: Run;
  signoffId: string;
  step: RunStep;
  userOperatorRolesSet: Set<string>;
}) => {
  if (run.state !== RUN_STATE.RUNNING) {
    throw new Error('Run must be running.');
  }

  if (!signoffId) {
    throw new Error('Signoff id is required.');
  }

  if (!signoffUtil.isSignoffRequired(step.signoffs)) {
    throw new Error('No signoffs are required.');
  }

  const signoff = step.signoffs.find((signoff) => signoff.id === signoffId);
  if (!signoff) {
    throw new Error('Signoff not found.');
  }

  if (
    !signoffUtil.isGenericSignoffRequired(step.signoffs) &&
    !signoffUtil.requiresAnyRoles(signoff, Array.from(userOperatorRolesSet))
  ) {
    throw new Error('User does not have any operator roles for this signoff.');
  }

  if (!new ProcedureGraph(run).areRequirementsMet(step.id)) {
    throw new Error('Requirements must be fulfilled.');
  }
};

export const checkCanSignOffStep = ({
  run,
  step,
  signoffId,
  operator,
  userOperatorRolesSet,
  timestamp,
  userId,
}: {
  run: Run;
  step: RunStep;
  signoffId: string;
  operator: string;
  userOperatorRolesSet: Set<string>;
  timestamp: string;
  userId: string;
}) => {
  _commonValidateSignoff({
    run,
    signoffId,
    step,
    userOperatorRolesSet,
  });

  canSignOffStepValidation({
    step,
    signoffId,
    operator,
    userOperatorRolesSet,
    timestamp,
    userId,
  });
};

export const canSignOffStepValidation = ({
  step,
  signoffId,
  operator,
  userOperatorRolesSet,
  timestamp,
  userId,
}: {
  step: RunStep;
  signoffId: string;
  operator: string;
  userOperatorRolesSet: Set<string>;
  timestamp: string;
  userId: string;
}) => {
  if (
    !signoffUtil.isGenericSignoffRequired(step.signoffs) &&
    !userOperatorRolesSet.has(operator)
  ) {
    throw new Error('User does not have the required operator role.');
  }

  const stepState = getStepState(step);
  if (
    stepState &&
    !([STEP_STATE.INCOMPLETE, STEP_STATE.PAUSED] as Array<StepState>).includes(
      stepState
    )
  ) {
    throw new Error('Signoff can only be made on in-progress or paused steps.');
  }

  const latestSignoffAction = signoffUtil.getLatestSignoffAction(
    step.actions ?? [],
    signoffId
  );

  if (
    latestSignoffAction &&
    latestSignoffAction.type !== SIGNOFF_ACTION_TYPE.REVOKE_SIGNOFF
  ) {
    throw new Error(
      'Step must be un-signed-off or revoked in order to approve a signoff.'
    );
  }

  if (latestSignoffAction && latestSignoffAction.timestamp >= timestamp) {
    throw new Error('Signoff action must happen after its latest revocation.');
  }

  if (
    signoffUtil.isRoleSignedOffAnywhere({ operator, userId, signoffable: step })
  ) {
    throw new Error(
      `Signoff user ${userId} has already signed off on this role`
    );
  }
};

export const checkCanRevokeStepApproval = ({
  run,
  sectionId,
  stepId,
  signoffId,
  userOperatorRolesSet,
  timestamp,
}: {
  run: Run;
  sectionId: string;
  stepId: string;
  signoffId: string;
  userOperatorRolesSet: Set<string>;
  timestamp: string;
}) => {
  const step: RunStep = getStepById(run, sectionId, stepId);
  _commonValidateSignoff({ run, signoffId, step, userOperatorRolesSet });

  // Do not allow revoking signoff if step is fully signed off and any of the disallowed block types are present.
  if (signoffUtil.allSignoffsComplete(step)) {
    for (const contentBlock of step.content) {
      if (IRREVOCABLE_BLOCK_TYPE_SET.has(contentBlock.type)) {
        throw new Error(
          `Signoff cannot be revoked for step with block type: ${contentBlock.type}`
        );
      }
    }
  }

  const stepState = getStepState(step);
  if (
    stepState &&
    !(
      [
        STEP_STATE.COMPLETED,
        STEP_STATE.INCOMPLETE,
        STEP_STATE.PAUSED,
      ] as Array<StepState>
    ).includes(stepState)
  ) {
    throw new Error(
      'Signoff can only be revoked on completed or in-progress steps.'
    );
  }

  const latestSignoffAction = signoffUtil.getLatestSignoffAction(
    step.actions ?? [],
    signoffId
  );
  if (latestSignoffAction?.type !== SIGNOFF_ACTION_TYPE.SIGNOFF) {
    throw new Error('Signoff cannot be revoked for incomplete signoff.');
  }
  if (latestSignoffAction.timestamp >= timestamp) {
    throw new Error(
      'Revoke action must happen after the signoff it is revoking.'
    );
  }
};

export const updateDocWithStepSignoffRevoked = ({
  run,
  userId,
  sectionId,
  stepId,
  signoffId,
  userOperatorRolesSet,
  timestamp,
}: {
  run: Run;
  userId: string;
  sectionId: string;
  stepId: string;
  signoffId: string;
  userOperatorRolesSet: Set<string>;
  timestamp: string;
}): boolean => {
  try {
    checkCanRevokeStepApproval({
      run,
      sectionId,
      stepId,
      signoffId,
      userOperatorRolesSet,
      timestamp,
    });
  } catch (e) {
    return false;
  }

  const step: RunStep = getStepById(run, sectionId, stepId);

  // Add revoke signoff action.
  if (!step.actions) {
    step.actions = [];
  }
  step.actions.push(
    signoffUtil.getRevokeSignoffAction({
      userId,
      signoffId,
      timestamp,
      actions: step.actions,
    }) as StepRevokeSignoffAction
  );

  const stepState = getStepState(step);

  // Remove completed state if it exists.
  if (stepState === STEP_STATE.COMPLETED) {
    delete step.completed;
    delete step.completedAt;
    delete step.completedUserId;
  }

  // If there are no longer any signoffs, remove recorded values.
  if (!signoffUtil.anySignoffsComplete(step)) {
    // Restart duration timer if it exists.
    if (step.duration && typeof step.duration === 'object') {
      step.duration.duration = '';
    }

    // Remove recorded if it exists.
    for (const contentBlock of step.content) {
      delete contentBlock['recorded'];
    }
  }

  return true;
};

export const shouldRecordExpressionForBlock = (block: RunStepBlock) => {
  return (
    block.type === 'expression' ||
    (isTokenizedTextBlock(block) &&
      (block as RunTextBlock).tokens?.some(
        (token) => token.type === 'reference'
      ))
  );
};

const runUtil = {
  getStepState,
  isStepEnded,
  isStepStateEnded,
  RUN_STATE,
  ACTIVE_RUN_STATES,
  RUN_STATUS,
  STEP_STATE,
  newRunDoc,
  updateDocWithEndRun,
  updateDocWithRunReopened,
  updateDocWithAction,
  copyStepWithoutActiveContent,
  displaySectionKey,
  displaySectionStepKey,
  getStepDependenciesMap,
  buildStepMap,
  updateDocWithStepSignoffRevoked,
  getSectionAndStepIndices,
};

export default runUtil;

// Exported for legacy tests
export { _updateDocWithUnfinishedStepsSkipped };
export { _updateDocWithRunComment };
export { _updateDocWithRunStatus };
