import {
  Draft,
  DraftAddedStep,
  DraftHeader,
  DraftHeaderBlock,
  DraftRedlineComment,
  DraftSection,
  DraftStepBlock,
  ReviewComment,
  RunAddedStep,
  RunHeaderBlockRedline,
  RunHeaderFieldRedline,
  RunRedline,
  RunRedlineComment,
  RunStepBlock,
  RunStepBlockRedline,
  RunStepFieldRedline,
} from 'shared/lib/types/views/procedures';
import {
  AddedStepRedline,
  HeaderBlockRedline,
  HeaderFieldRedline,
  Redline,
  RedlineComment,
  StepBlockRedline,
  StepFieldRedline,
} from './views/redlines';
import revisionsUtil from './revisions';
import { ArrayUnion } from './types';
import {
  HeaderFieldScrollEntry,
  ScrollContext,
  ScrollEntry,
  StepFieldScrollEntry,
} from '../hooks/useScroll';
import { getRedlineId } from 'shared/lib/redlineUtil';
import {
  getAllProcedureHeaderIds,
  getAllStepIds,
} from 'shared/lib/procedureUtil';
import { partition } from 'lodash';

type RunRedlineFromRedlineT<RedlineT> = RedlineT extends HeaderFieldRedline
  ? RunHeaderFieldRedline
  : RedlineT extends HeaderBlockRedline
  ? RunHeaderBlockRedline
  : RedlineT extends StepFieldRedline
  ? RunStepFieldRedline
  : RedlineT extends StepBlockRedline
  ? RunStepBlockRedline
  : RedlineT extends RedlineComment
  ? RunRedlineComment
  : RedlineT extends AddedStepRedline
  ? RunAddedStep
  : never;

export const REDLINE_STATE = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  RESOLVED: 'resolved',
  UNRESOLVED: 'unresolved',
} as const;

export const REDLINE_TYPE = {
  HEADER_REDLINE: 'header_redline',
  STEP_REDLINE: 'step_redline',
  REDLINE_COMMENT: 'redline_comment',
  ADDED_STEP: 'added_step',
  FULL_STEP: 'full_step',
} as const;

/**
 * Get redlines that have valid redline ids.
 */
export const getRedlinesWithValidRedlineIds = (
  redlines: Array<Redline>
): Array<Redline> => {
  return redlines.filter((redline) => {
    const redlineType = redline.type;
    const runRedline = redline[redlineType];
    const redlineId = getRedlineId(runRedline);
    return Boolean(redlineId) && redlineId === redline._id;
  });
};

/**
 * Given a list of redline docs of any redline type, get a map of the form {header_id: [header_redline]}.
 *
 * @param redlines - a list of redline docs of any type
 * @return a map of the form {header_id: [header_redline]}
 */
export const getHeaderRedlineMap = (
  redlines: Array<Redline> | undefined
): Map<string, Array<HeaderFieldRedline | HeaderBlockRedline>> => {
  const headerRedlineMap = new Map<
    string,
    Array<HeaderFieldRedline | HeaderBlockRedline>
  >();
  if (!redlines) {
    return headerRedlineMap;
  }

  const headerRedlines = redlines.filter(
    (redline) => redline.type === REDLINE_TYPE.HEADER_REDLINE
  ) as Array<HeaderFieldRedline | HeaderBlockRedline>;
  headerRedlines.forEach((redline) => {
    const headerId = redline.header_redline.header.id;
    if (!headerRedlineMap.has(headerId)) {
      headerRedlineMap.set(headerId, []);
    }

    headerRedlineMap.get(headerId)?.push(redline);
  });
  return headerRedlineMap;
};

/**
 * Given a list of header redline docs, get a map of the form {content_id: [block_header_redline]}.
 *
 * @param redlines - a list of header redline docs
 * @return a map of the form {content_id: [block_header_redline]}
 */
export const getHeaderBlockRedlineMap = (
  redlines: Array<HeaderFieldRedline | HeaderBlockRedline> | undefined
): Map<string, Array<HeaderBlockRedline>> => {
  const headerBlockRedlineMap = new Map<string, Array<HeaderBlockRedline>>();
  if (!redlines) {
    return headerBlockRedlineMap;
  }
  const headerBlockRedlines = redlines.filter((redline) =>
    Boolean((redline as HeaderBlockRedline).header_redline.content_id)
  ) as Array<HeaderBlockRedline>;
  headerBlockRedlines.forEach((redline) => {
    const contentId = redline.header_redline.content_id;
    if (!headerBlockRedlineMap.has(contentId)) {
      headerBlockRedlineMap.set(contentId, []);
    }

    headerBlockRedlineMap.get(contentId)?.push(redline);
  });

  return headerBlockRedlineMap;
};

/**
 * Given a list of redline docs of any redline type, get a map of the form {step_id: [step_redline]}.
 *
 * @param redlines - a list of redline docs of any type
 * @return a map of the form {step_id: [step_redline]}
 */
export const getStepRedlineMap = (
  redlines: Array<Redline> | undefined
): Map<string, Array<StepFieldRedline | StepBlockRedline>> => {
  const stepRedlineMap = new Map<
    string,
    Array<StepFieldRedline | StepBlockRedline>
  >();
  if (!redlines) {
    return stepRedlineMap;
  }
  const stepRedlines = redlines.filter(
    (redline) => redline.type === REDLINE_TYPE.STEP_REDLINE
  ) as Array<StepFieldRedline | StepBlockRedline>;
  stepRedlines.forEach((redline) => {
    const stepId = redline.step_id;
    if (!stepRedlineMap.has(stepId)) {
      stepRedlineMap.set(stepId, []);
    }

    stepRedlineMap.get(stepId)?.push(redline);
  });
  return stepRedlineMap;
};

/**
 * Given a list of step redline docs, get a map of the form {content_id: [header_block_redline]}.
 *
 * @param redlines - a list of step redline docs
 * @return a map of the form {content_id: [header_block_redline]}
 */
export const getStepBlockRedlineMap = (
  redlines: Array<StepFieldRedline | StepBlockRedline> | undefined
): Map<string, Array<StepBlockRedline>> => {
  const stepBlockRedlineMap = new Map<string, Array<StepBlockRedline>>();
  if (!redlines) {
    return stepBlockRedlineMap;
  }
  const stepBlockRedlines = redlines.filter((redline) =>
    Boolean((redline as StepBlockRedline).content_id)
  ) as Array<StepBlockRedline>;
  stepBlockRedlines.forEach((redline) => {
    const contentId = redline.content_id;
    if (!stepBlockRedlineMap.has(contentId)) {
      stepBlockRedlineMap.set(contentId, []);
    }

    stepBlockRedlineMap.get(contentId)?.push(redline);
  });

  return stepBlockRedlineMap;
};

const _getRunRedline = (redline: Redline): RunRedline => {
  const redlineType = redline.type;
  return redline[redlineType];
};

/**
 * Given an array of redline docs, get an array of run redlines sorted by their creation times.
 *
 * @param redlines
 */
export const getRunRedlinesSorted = <RedlineT extends Redline>(
  redlines: ArrayUnion<RedlineT> | undefined
): Array<RunRedlineFromRedlineT<RedlineT>> => {
  if (!redlines) {
    return [] as Array<RunRedlineFromRedlineT<RedlineT>>;
  }
  const runRedlines = redlines.map((redline) => _getRunRedline(redline));
  return revisionsUtil.sortRunRedlines(runRedlines) as Array<
    RunRedlineFromRedlineT<RedlineT>
  >;
};

/**
 * Given a list of redline docs of any redline type, get a map of the form {preceding_step_id: [added_step_redline]}.
 *
 * @param redlines - a list of redline docs of any type
 * @return a map of the form {preceding_step_id: [added_step_redline]}
 */
export const getAddedStepRedlineMap = (
  redlines: Array<Redline> | undefined
): Map<string, Array<AddedStepRedline>> => {
  const addedStepRedlineMap = new Map<string, Array<AddedStepRedline>>();
  if (!redlines) {
    return addedStepRedlineMap;
  }

  const addedStepRedlines = redlines.filter(
    (redline) => redline.type === REDLINE_TYPE.ADDED_STEP
  ) as Array<AddedStepRedline>;
  addedStepRedlines.forEach((redline) => {
    const precedingStepId = redline.preceding_step_id;
    if (!addedStepRedlineMap.has(precedingStepId)) {
      addedStepRedlineMap.set(precedingStepId, []);
    }
    addedStepRedlineMap.get(precedingStepId)?.push(redline);
  });

  return addedStepRedlineMap;
};

const _createScrollEntry = (
  fieldName: string,
  context: ScrollContext
): ScrollEntry => ({
  ...context,
  fieldName,
});

const _createAddedStepEntry = (
  id: string,
  context: ScrollContext
): ScrollEntry => _createScrollEntry(`${id}.stepAddedDuringRun`, context);

const _createNameEntry = (id: string, context: ScrollContext): ScrollEntry =>
  _createScrollEntry(`${id}.name`, context);

const _getBlockEntries = (
  content: Array<DraftStepBlock | RunStepBlock | DraftHeaderBlock>,
  blockMap: Map<string, Array<HeaderBlockRedline | StepBlockRedline>>,
  referenceId: string,
  context: ScrollContext
) => {
  return content
    .filter((block) => blockMap.has(block.id))
    .flatMap((block) => {
      return _createScrollEntry(`${referenceId}.content[${block.id}]`, context);
    });
};

const _getCommentEntries = (
  commentReferenceMap: Map<string, Array<DraftRedlineComment | ReviewComment>>,
  referenceId: string,
  context: ScrollContext
): Array<ScrollEntry> => {
  const comments = commentReferenceMap.get(referenceId);
  if (!comments) {
    return [];
  }
  return comments.map((comment) =>
    _createScrollEntry(`comment.[${comment.id}]`, context)
  );
};

const _getHeaderScrollEntries = (
  headers: Array<DraftHeader> | undefined,
  headerRedlineMap: Map<string, Array<HeaderFieldRedline | HeaderBlockRedline>>,
  commentReferenceMap: Map<string, Array<DraftRedlineComment | ReviewComment>>
): Array<HeaderFieldScrollEntry> => {
  if (!headers) {
    return [];
  }

  return headers.flatMap<HeaderFieldScrollEntry>((header) => {
    const entries: Array<HeaderFieldScrollEntry> = [];
    const headerContext = { headerId: header.id };
    const headerRedlines = headerRedlineMap.get(header.id);
    const hasHeaderFieldRedlines = headerRedlines?.some(
      (redline) => (redline.header_redline as RunHeaderFieldRedline).field,
      []
    );
    if (hasHeaderFieldRedlines) {
      entries.push(
        _createNameEntry(header.id, headerContext) as HeaderFieldScrollEntry
      );
    }

    const headerBlockRedlines = getHeaderBlockRedlineMap(headerRedlines);
    const blockEntries = _getBlockEntries(
      header.content,
      headerBlockRedlines,
      header.id,
      headerContext
    ) as Array<HeaderFieldScrollEntry>;
    entries.push(...blockEntries);

    const commentEntries = _getCommentEntries(
      commentReferenceMap,
      header.id,
      headerContext
    ) as Array<HeaderFieldScrollEntry>;
    entries.push(...commentEntries);

    return entries;
  });
};

const _getStepScrollEntries = (
  sections: Array<DraftSection>,
  stepRedlineMap: Map<string, Array<StepFieldRedline | StepBlockRedline>>,
  commentReferenceMap: Map<string, Array<DraftRedlineComment | ReviewComment>>
): Array<StepFieldScrollEntry> => {
  return sections.flatMap<StepFieldScrollEntry>((section) => {
    return section.steps.flatMap<StepFieldScrollEntry>((step) => {
      const entries: Array<StepFieldScrollEntry> = [];
      const stepContext = {
        sectionId: section.id,
        stepId: step.id,
      };
      const isAddedStep = Boolean((step as DraftAddedStep).created_during_run);
      if (isAddedStep) {
        entries.push(
          _createAddedStepEntry(step.id, stepContext) as StepFieldScrollEntry
        );
      }

      const stepRedlines = stepRedlineMap.get(step.id);
      const hasStepFieldRedlines = stepRedlines?.some(
        (redline) => (redline.step_redline as RunStepFieldRedline).field
      );
      if (hasStepFieldRedlines) {
        entries.push(
          _createNameEntry(step.id, stepContext) as StepFieldScrollEntry
        );
      }

      const stepBlockRedlines = getStepBlockRedlineMap(stepRedlines);
      const blockEntries = _getBlockEntries(
        step.content,
        stepBlockRedlines,
        step.id,
        stepContext
      ) as Array<StepFieldScrollEntry>;
      entries.push(...blockEntries);

      const commentEntries = _getCommentEntries(
        commentReferenceMap,
        step.id,
        stepContext
      ) as Array<StepFieldScrollEntry>;
      entries.push(...commentEntries);

      return entries;
    });
  });
};

export const getUnresolvedRedlines = (
  procedure: Draft,
  redlines: Array<Redline>
): Array<Redline> => {
  return redlines.filter((redline) => {
    if (
      redline.type === REDLINE_TYPE.ADDED_STEP ||
      redline.type === REDLINE_TYPE.STEP_REDLINE ||
      redline.type === REDLINE_TYPE.HEADER_REDLINE
    ) {
      const matchingActionExists = procedure?.redline_actions?.some(
        (action) => action.redline_id === redline._id
      );
      return !matchingActionExists;
    } else if (redline.type === REDLINE_TYPE.REDLINE_COMMENT) {
      const matchingComment = procedure?.comments?.find(
        (comment) => (comment as DraftRedlineComment).redline_id === redline._id
      );
      return !matchingComment || !matchingComment.resolved;
    }
    return true;
  });
};

/**
 * Get an ordered list of redlines to scroll to.
 */
export const getScrollEntries = (
  procedure: Draft,
  redlineDocs: Array<Redline>
): Array<ScrollEntry> => {
  if (!procedure) {
    return [];
  }
  const unactionedRedlines = revisionsUtil.getUnactionedNonCommentRedlineDocs(
    procedure.redline_actions,
    redlineDocs
  );
  const commentReferenceMap: Map<
    string,
    Array<DraftRedlineComment | ReviewComment>
  > = revisionsUtil.getSortedCommentReferenceMap(procedure);

  const headerRedlineMap = getHeaderRedlineMap(unactionedRedlines);
  const headerScrollEntries = _getHeaderScrollEntries(
    procedure.headers,
    headerRedlineMap,
    commentReferenceMap
  );

  const stepRedlineMap = getStepRedlineMap(unactionedRedlines);
  const stepScrollEntries = _getStepScrollEntries(
    procedure.sections,
    stepRedlineMap,
    commentReferenceMap
  );

  return [...headerScrollEntries, ...stepScrollEntries];
};

export const getUnactionedNonCommentRedlines = (
  procedure: Draft,
  redlines: Array<Redline>
): {
  unactionedNonCommentRedlines: Array<Redline>;
  detachedNonCommentRedlines: Array<Redline>;
} => {
  const allStepIds = getAllStepIds(procedure);
  const stepIdSet = new Set(allStepIds);

  const allHeaderIds = getAllProcedureHeaderIds(procedure);
  const headerIdSet = new Set(allHeaderIds);

  const redlineDocs = revisionsUtil.getUnactionedNonCommentRedlineDocs(
    procedure.redline_actions,
    redlines
  );

  const [unactionedNonCommentRedlines, detachedNonCommentRedlines] = partition(
    redlineDocs ?? [],
    (redline) => {
      const isStepRedline =
        redline.type === REDLINE_TYPE.STEP_REDLINE &&
        stepIdSet.has(redline.step_id);
      const isHeaderRedline =
        redline.type === REDLINE_TYPE.HEADER_REDLINE &&
        headerIdSet.has(
          (redline as HeaderBlockRedline | HeaderFieldRedline).header_redline
            .header.id
        );

      return (
        isStepRedline ||
        isHeaderRedline ||
        redline.type === REDLINE_TYPE.ADDED_STEP
      );
    }
  );

  return { unactionedNonCommentRedlines, detachedNonCommentRedlines };
};

export const getCommentRedlines = (
  procedure: Draft,
  redlines: Array<Redline>
): {
  commentRedlines: Array<Redline>;
  detachedCommentRedlines: Array<Redline>;
} => {
  const allStepIds = getAllStepIds(procedure);
  const stepIdSet = new Set(allStepIds);

  const redlineComments = redlines.filter(
    (redline) => redline.type === REDLINE_TYPE.REDLINE_COMMENT
  );

  const [commentRedlines, detachedCommentRedlines] = partition(
    redlineComments,
    (comment) => stepIdSet.has((comment as RedlineComment).step_id)
  );

  return { commentRedlines, detachedCommentRedlines };
};
