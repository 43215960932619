import { sortBy, deduplicate } from 'shared/lib/collections';
import {
  ComponentItem,
  ComponentItemType,
  DenormalizedComponentItem,
  EMPTY_TRACKING_DISPLAY_VALUE,
  Item,
  TotalUsageRecord,
} from '../types';
import {
  Part,
  ComponentPart,
} from 'shared/lib/types/postgres/manufacturing/types';
import { getFormattedAmount } from './currency';
import { asComponentPart } from './parts';
import { BuildsAutoNumbering } from 'shared/lib/types/couch/settings';
import sum from '../../lib/mathUtil';

type GetLocationLabel = (string) => string | undefined;

export const AUTO_NUMBERING_OFFSET_RANGE = {
  min: 100000,
  max: 100000000000,
};

export const DEFAULT_AUTO_NUMBERING: BuildsAutoNumbering = {
  part_numbers: {
    enabled: false,
    prefixes: [],
    delimiter: '',
    offset: AUTO_NUMBERING_OFFSET_RANGE.min,
  },
  part_serial_numbers: {
    enabled: false,
    prefixes: [],
    delimiter: '',
    offset: AUTO_NUMBERING_OFFSET_RANGE.min,
  },
  part_lot_numbers: {
    enabled: false,
    prefixes: [],
    delimiter: '',
    offset: AUTO_NUMBERING_OFFSET_RANGE.min,
  },
};

export const getTrackingLabel = (trackingType: string | undefined): string => {
  switch (trackingType) {
    case 'serial':
      return 'Serial #';
    case 'lot':
      return 'Lot #';
    default:
      return 'Tracking';
  }
};

export const getTrackingValue = (item: Item | undefined): string => {
  switch (item?.part.tracking) {
    case 'serial':
      return item.serial || EMPTY_TRACKING_DISPLAY_VALUE;
    case 'lot':
      return item.lot || EMPTY_TRACKING_DISPLAY_VALUE;
    default:
      return EMPTY_TRACKING_DISPLAY_VALUE;
  }
};

export const getItemTopLabel = (
  item: Item,
  getLocationLabel: GetLocationLabel
): string => {
  switch (item.part.tracking) {
    case 'serial': {
      return getSerialItemTopLabel(item);
    }
    case 'lot': {
      return item.lot || 'No Lot #';
    }
    default: {
      return getLocationLabel(item.location_id) || 'No Location';
    }
  }
};

export const getSerialItemTopLabel = (item: Partial<Item>): string => {
  return item.serial || 'No Serial #';
};

export const getItemLocationLabel = (
  item: Item,
  getLocationLabel: GetLocationLabel
): string => {
  // bottom label not needed for untracked items
  if (['lot', 'serial'].includes(item.part.tracking) && item.location_id) {
    const locationLabel = getLocationLabel(item.location_id);
    if (locationLabel) {
      return locationLabel;
    }
  }
  return '';
};

export const isNewSerialNumber = (
  serialNumber: string,
  partId: string,
  allItems: Array<Item | Partial<Item>>
): boolean => {
  if (!serialNumber) {
    return true; // ignore blank / missing values
  }
  const processedSerial = serialNumber.trim().toLowerCase();
  for (const item of allItems) {
    if (item.part?.id === partId) {
      const itemSerial = (item.serial || '').trim().toLowerCase();
      if (itemSerial === processedSerial) {
        return false;
      }
    }
  }
  return true;
};

const getComponentItemTypeSortOrder = (type: ComponentItemType): number => {
  switch (type) {
    case ComponentItemType.Kit: {
      return 1;
    }
    case ComponentItemType.UserDefined: {
      return 2;
    }
    case ComponentItemType.Missing: {
      return 3;
    }
  }
};

export const getDenormalizedComponentList = (
  item: Item,
  allParts: Array<Part>,
  allItems: Array<Item>
): Array<DenormalizedComponentItem> => {
  const neededParts: { [key: string]: ComponentPart } = {};
  for (const componentPart of item.part.components || []) {
    const revisionId = componentPart.revision_id as string;
    neededParts[revisionId] = { ...componentPart };
  }

  const componentList: Array<DenormalizedComponentItem> = [];
  const addExistingComponentsToList = (
    components: Array<ComponentItem>,
    type: ComponentItemType
  ) => {
    const existing: Array<DenormalizedComponentItem> = [];
    for (const component of components) {
      const item = allItems.find((item) => item.id === component.item_id);
      const itemRevisionId = item?.part_revision_id || '';
      if (
        type === ComponentItemType.UserDefined ||
        itemRevisionId in neededParts
      ) {
        let part: ComponentPart | undefined;
        const neededPart = neededParts[itemRevisionId];
        if (neededPart) {
          neededPart.amount = Math.max(neededPart.amount - component.amount, 0);
          part = neededPart;
        } else {
          const itemPart = item?.part;
          part = itemPart
            ? asComponentPart(itemPart, component.amount)
            : undefined;
        }
        existing.push({ ...component, type, part, item });
      }
    }
    componentList.push(...existing);
  };
  addExistingComponentsToList(item.kit_components || [], ComponentItemType.Kit);
  addExistingComponentsToList(
    item.components || [],
    ComponentItemType.UserDefined
  );

  Object.values(neededParts)
    .filter((part) => part.amount > 0)
    .forEach((part) => {
      const { part_id, amount } = part;
      componentList.push({
        type: ComponentItemType.Missing,
        part,
        part_id,
        amount,
        item_id: '',
      });
    });

  const mappedList = componentList.map((component) => ({
    ...component,
    _sort_part_part_no: component.part?.part_no || '',
    _sort_component_item_type_sort_order: getComponentItemTypeSortOrder(
      component.type
    ),
    _sort_tracking_value: getTrackingValue(component.item),
  }));
  const sortedList = sortBy(mappedList, [
    '_sort_part_part_no',
    '_sort_component_item_type_sort_order',
    '_sort_tracking_value',
    'amount',
  ]);
  return sortedList;
};

export type ExportedComponentItem = {
  lineId: string;
  partNumber: string;
  revision: string;
  trackingId: string;
  quantity: number;
  unit_cost: string;
  locationId: string | undefined;
};

const _generateComponentsTree = (
  item: Item | undefined,
  lineId: string,
  quantity: number,
  allParts: Array<Part>,
  allItems: Array<Item>
): Array<ExportedComponentItem> => {
  if (!item) {
    return [];
  }
  let tree: Array<ExportedComponentItem> = [
    {
      lineId,
      partNumber: item.part.part_no,
      revision: item.part.rev,
      quantity,
      trackingId: getTrackingValue(item),
      locationId: item.location_id,
      unit_cost:
        item.unit_cost_cents === undefined
          ? ''
          : getFormattedAmount(item.unit_cost_cents),
    },
  ];
  const components = getDenormalizedComponentList(item, allParts, allItems);
  const lineIdPrefix = lineId ? `${lineId}.` : '';
  components
    .filter((component) => component.type !== ComponentItemType.Missing)
    .forEach((component, index) => {
      const componentTree = _generateComponentsTree(
        component.item,
        `${lineIdPrefix}${index + 1}`,
        component.amount,
        allParts,
        allItems
      );
      tree = [...tree, ...componentTree];
    });
  return tree;
};

export const generateComponentTree = (
  item: Item,
  allParts: Array<Part>,
  allItems: Array<Item>
): Array<ExportedComponentItem> => {
  return _generateComponentsTree(item, '', item.amount, allParts, allItems);
};

export const generateComponentTrees = (
  items: Item[],
  allParts: Array<Part>,
  allItems: Array<Item>
): Array<Array<ExportedComponentItem>> => {
  const exportedComponentItems: Array<Array<ExportedComponentItem>> = [];
  for (const item of items) {
    exportedComponentItems.push(
      _generateComponentsTree(item, '', item.amount, allParts, allItems)
    );
  }
  return exportedComponentItems;
};

export const getTotalItemUsage = (item: Item): Array<TotalUsageRecord> => {
  if (!item.usage) {
    return [];
  }

  const usageTypeNames = getUsageTypeNames(item);
  return usageTypeNames.map((name) => getTotalUsageForType(item, name));
};

const getTotalUsageForType = (
  item: Item,
  usageTypeName: string
): TotalUsageRecord => {
  const totalAmount = getTotalUsageAmount(item, usageTypeName);
  const limit = getUsageLimit(item, usageTypeName);
  return {
    name: usageTypeName,
    totalAmount,
    limit,
  };
};

const getUsageTypeNames = (item: Item): Array<string> => {
  if (!item.usage) {
    return [];
  }
  const usageRecords = item.usage.map((occurrence) => occurrence.content_data);
  const usageTypeNamesFromItem = usageRecords.map(
    (record) => record.usage_type
  );

  const usageTypes = item.part.usage_types || [];
  const usageTypeNamesFromPart = usageTypes.map((record) => record.name);

  const allUsageTypeNames = [
    ...usageTypeNamesFromItem,
    ...usageTypeNamesFromPart,
  ];
  return deduplicate(allUsageTypeNames);
};

const getTotalUsageAmount = (item: Item, usageTypeName: string): number => {
  if (!item.usage) {
    return 0;
  }
  const usageRecords = item.usage.map((occurrence) => occurrence.content_data);
  const recordsForType = usageRecords.filter((record) => {
    return record.usage_type === usageTypeName;
  });
  if (recordsForType.length === 0) {
    return 0;
  }
  const amountsForType = recordsForType.map((record) => record.amount);
  return sum(amountsForType);
};

const getUsageLimit = (
  item: Item,
  usageTypeName: string
): number | undefined => {
  if (!item.part || !item.part.usage_types) {
    return undefined;
  }
  const usageTypes = item.part.usage_types;
  const found = usageTypes.find(
    (usageType) => usageType.name === usageTypeName
  );
  if (!found) {
    return undefined;
  }
  return found.limit;
};

// Check-in quantity must be a positive integer
export const isValidCheckinAmount = (amount: number): boolean => {
  if (!Number.isInteger(amount)) {
    return false;
  }
  return amount > 0;
};
