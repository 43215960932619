import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { selectOfflineInfo } from '../app/offline';
import { Release } from 'shared/lib/types/views/procedures';
import { isPending, getPendingProcedureIndex } from 'shared/lib/procedureUtil';
import procedureUtil from '../lib/procedureUtil';
import { Redline } from '../lib/views/redlines';
import { DatabaseServices } from '../contexts/proceduresSlice';

const OFFLINE_MESSAGE =
  'Are you sure you want to proceed? You are offline and may not have the latest updates to this procedure.';
const PENDING_MESSAGE = 'Are you sure you want to proceed? There are pending edits to this procedure.';
const POTENTIAL_MESSAGE = 'Are you sure you want to proceed? There are potential edits to this procedure.';

/*
 * Generate the message text to warn user of pending changes.  Returns empty string if no changes are present
 * Known draft edits take precedence and we say "pending".  Known unresolved redlines or any fetch errors we say "potential"
 */
const getPendingChangesMessage = (
  pendingProcedureResult: PromiseSettledResult<Release>,
  pendingRedlineResult: PromiseSettledResult<Array<Redline>>
): string => {
  if (pendingProcedureResult.status === 'fulfilled') {
    const procedure = pendingProcedureResult.value;
    if (procedure && isPending(procedure)) {
      return PENDING_MESSAGE;
    }
  } else if (pendingProcedureResult.status === 'rejected') {
    // 404 is expected if the pending doc does not exist
    if (pendingProcedureResult.reason.status !== 404) {
      return POTENTIAL_MESSAGE;
    }
  }

  if (pendingRedlineResult.status === 'fulfilled') {
    if (pendingRedlineResult.value?.length) {
      return POTENTIAL_MESSAGE;
    }
  } else if (pendingRedlineResult.status === 'rejected') {
    return POTENTIAL_MESSAGE;
  }

  return '';
};

const usePendingChangesPrompt = () => {
  const { services }: { services: DatabaseServices } = useDatabaseServices();
  const online = useSelector((state) => selectOfflineInfo(state).online);

  // Prompt user if there are pending changes on the procedure.  Returns false if user cancels confirmation.
  const promptForChanges = useCallback(
    async (procedure: Release): Promise<boolean> => {
      if (!online) {
        return window.confirm(OFFLINE_MESSAGE);
      }

      /*
       * When online, fetch the pending procedure and redlines from the backend and wait for both.
       * The results array is ordered the same as the associated promises array
       */
      let pendingChangesMessage;
      try {
        const [pendingProcedureResult, pendingRedlineResult] = await Promise.allSettled([
          services.procedures.getProcedure(getPendingProcedureIndex(procedure._id)),
          services.procedures.getUnresolvedRedlineDocs(procedureUtil.getProcedureId(procedure)),
        ]);
        pendingChangesMessage = getPendingChangesMessage(
          pendingProcedureResult as PromiseSettledResult<Release>,
          pendingRedlineResult
        );
      } catch {
        // assume the worst and warn of potential edits
        pendingChangesMessage = POTENTIAL_MESSAGE;
      }

      if (pendingChangesMessage && !window.confirm(pendingChangesMessage)) {
        return false;
      }
      return true;
    },
    [services.procedures, online]
  );

  return promptForChanges;
};

export default usePendingChangesPrompt;
