import { cloneDeep, omit } from 'lodash';
import debounce from 'lodash.debounce';
import { useMemo } from 'react';
import { BuildsAutoNumbering } from 'shared/lib/types/couch/settings';
import { ComponentPart, Part } from 'shared/lib/types/postgres/manufacturing/types';
import {
  PartBuildRecordedItem as RecordedItem,
  RunPartBuildBlock,
  RunPartBuildRecorded,
} from 'shared/lib/types/views/procedures';
import SubstepNumber from '../../components/SubstepNumber';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { BUILDS_AUTO_NUMBERING_KEY, useSettings } from '../../contexts/SettingsContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import apm from '../../lib/apm';
import idUtil from '../../lib/idUtil';
import { generateHiddenClassString } from '../../lib/styles';
import useItems from '../hooks/useItems';
import useParts from '../hooks/useParts';
import { Item as CheckedOutItem } from '../lib/inventoryUtil';
import { DEFAULT_AUTO_NUMBERING } from '../lib/items';
import { asComponentPart } from '../lib/parts';
import { Item } from '../types';
import FieldInputBuildItem from './FieldInputBuildItem';
import FieldInputBuildItemsSerial from './FieldInputBuildItemsSerial';
import PartAndRevisionPusher from './PartAndRevisionPusher';

export const getComponentPartFromRecordedItem = (item: RecordedItem): ComponentPart => {
  return {
    part_id: item.part_id,
    part_no: item.part_no,
    revision: item.revision,
    revision_id: item.revision_id,
    name: item.name,
    amount: item.amount,
  };
};

export type PartBuildProps = {
  content: RunPartBuildBlock;
  recorded?: RunPartBuildRecorded;
  blockLabel?: string;
  teamId: string;
  onRecordValuesChanged: (id: string, updated: RunPartBuildBlock['recorded']) => void;
  onRecordErrorsChanged: (errorObj: { [key: string]: string }) => void;
  isEnabled: boolean;
  isHidden: boolean;
  isStepComplete: boolean;
  checkedOutItems?: CheckedOutItem[];
};

const PartBuild = ({
  content,
  recorded,
  blockLabel,
  teamId,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  isEnabled,
  isHidden,
  isStepComplete,
  checkedOutItems,
}: PartBuildProps) => {
  const { getSetting } = useSettings();
  const { parts, getPart } = useParts();
  const { allItems, refreshItems } = useItems();
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const onPushPart = (part: Part) => {
    const updated = recorded ? cloneDeep(recorded) : { items: {} };
    updated.added_items = updated.added_items ?? [];
    updated.added_items.push({
      ...asComponentPart(part, 1),
      id: idUtil.generateUuidEquivalentId(),
      part_no: part.part_no,
    });
    onRecordValuesChanged?.(content.id, updated);
  };

  const autoNumbering = useMemo(
    () => getSetting<BuildsAutoNumbering>(BUILDS_AUTO_NUMBERING_KEY, DEFAULT_AUTO_NUMBERING),
    [getSetting]
  );

  const partIdToSerialMapping: { [partId: string]: string[] } = {};
  if (allItems) {
    for (const item of allItems) {
      // filter out non-serial items and items that have already been consumed/checked out
      if (!item.serial || Number(item.amount) !== 1) {
        continue;
      }
      if (!partIdToSerialMapping[item.part.id]) {
        partIdToSerialMapping[item.part.id] = [];
      }
      partIdToSerialMapping[item.part.id].push(item.serial.trim());
    }
  }

  const debouncedInputChange = useMemo(
    () =>
      debounce(() => {
        refreshItems().catch((err) => apm.captureError(err));
      }, 500),
    [refreshItems]
  );

  const recordItemValuesChanged = (item: RecordedItem, values) => {
    if (!recorded) {
      refreshItems().catch((err) => apm.captureError(err));
    }

    const updated = {
      ...recorded,
      items: {
        ...recorded?.items,
        [item.id]: values,
      },
    };
    onRecordValuesChanged?.(content.id, updated);

    debouncedInputChange();
  };

  const recordAddedItemValuesChanged = (index: number, values: RecordedItem) => {
    if (!recorded) {
      refreshItems().catch((err) => apm.captureError(err));
    }

    const updatedAddedItems = [...(recorded?.added_items ?? [])];
    updatedAddedItems[index] = values;

    const updated = {
      ...(recorded ?? { items: {} }),
      added_items: updatedAddedItems,
    };
    onRecordValuesChanged?.(content.id, updated);
  };

  const allItemsAndRecordedItems = useMemo(() => {
    if (!allItems) {
      return;
    }
    if (!recorded?.items) {
      return allItems;
    }
    const recordedItems: Array<Partial<Item>> = Object.values<RecordedItem>(recorded.items).map((item) => {
      const part = getPart(item.part_id);
      return {
        ...item,
        part,
      };
    });
    return (allItems as Array<Partial<Item>>).concat(recordedItems);
  }, [allItems, getPart, recorded?.items]);

  const generateNewItem = (item: RecordedItem) => {
    const id = idUtil.generateUuidEquivalentId();
    const newItem = {
      ...item,
      id,
    };
    return newItem;
  };

  const addItemToRecorded = (_recorded, item: RecordedItem) => {
    const newItem = generateNewItem(item);
    return {
      ..._recorded,
      items: {
        ..._recorded.items,
        [newItem.id]: newItem,
      },
    };
  };

  const onAddSerialItem = (item: RecordedItem) => {
    const recordedItems = recorded?.items || {};
    let updated = {
      ...recorded,
      items: recordedItems,
    };
    updated = addItemToRecorded(updated, item);

    /*
     * If no items have been recorded for the part, add two--
     * one for the new item, and one for the placeholder item
     * that has displayed but has no data recorded.
     */
    const itemsArray: Array<RecordedItem> = Object.values(recordedItems);
    const hasItemsForPart = itemsArray.some((_item) => {
      return _item.part_id === item.part_id;
    });

    if (!hasItemsForPart) {
      updated = addItemToRecorded(updated, item);
    }

    onRecordValuesChanged?.(content.id, updated);
  };

  const onAddSerial = async (
    item: Partial<Item>,
    itemId: string | undefined,
    partId: string,
    index: number,
    prefix: string | undefined
  ) => {
    if (!autoNumbering.part_serial_numbers?.enabled) {
      return;
    }

    // item will not be in allItemsAndRecordedItems until serial is set
    const itemToUpdate = allItemsAndRecordedItems?.find((item) => item.id === itemId) ?? item;
    const newItem = cloneDeep(itemToUpdate);
    (newItem as RecordedItem).prefix = prefix;
    newItem.serial = await services.builds.nextSerial(partId, prefix);
    (newItem as RecordedItem).part_index = index;

    recordItemValuesChanged(itemToUpdate as RecordedItem, newItem);
  };

  const onAddSerialForAddedItem = async (index: number, prefix?: string) => {
    if (!autoNumbering.part_serial_numbers?.enabled) {
      return;
    }
    if (!recorded?.added_items) {
      return;
    }

    const itemToUpdate = recorded.added_items[index];
    const updated = cloneDeep(itemToUpdate);
    updated.prefix = prefix;
    updated.serial = await services.builds.nextSerial(itemToUpdate.part_id, prefix);
    recordAddedItemValuesChanged(index, updated);
  };

  const onClearSerial = async (item: Partial<Item>, itemId: string | undefined) => {
    if (!autoNumbering.part_serial_numbers?.enabled) {
      return;
    }

    // item will not be in allItemsAndRecordedItems until serial is set
    const itemToUpdate = allItemsAndRecordedItems?.find((item) => item.id === itemId) ?? item;
    const newItem = cloneDeep(itemToUpdate);
    (newItem as RecordedItem).prefix = '';
    newItem.serial = '';
    recordItemValuesChanged(itemToUpdate as RecordedItem, newItem);
  };

  const onClearSerialForAddedItem = async (index: number, prefix?: string) => {
    if (!autoNumbering.part_serial_numbers?.enabled) {
      return;
    }
    if (!recorded?.added_items) {
      return;
    }

    const itemToUpdate = recorded.added_items[index];
    const updated = cloneDeep(itemToUpdate);
    updated.prefix = '';
    updated.serial = '';
    recordAddedItemValuesChanged(index, updated);
  };

  const onAddLotNumber = async (
    item: Partial<Item>,
    itemId: string | undefined,
    partId: string,
    index: number,
    prefix?: string
  ) => {
    if (!autoNumbering.part_lot_numbers?.enabled) {
      return;
    }

    // item will not be in allItemsAndRecordedItems until lot number is set
    const itemToUpdate = allItemsAndRecordedItems?.find((item) => item.id === itemId) ?? item;
    const newItem = cloneDeep(itemToUpdate);
    (newItem as RecordedItem).prefix = prefix;
    newItem.lot = await services.builds.nextLotNumber(partId, prefix);
    (newItem as RecordedItem).part_index = index;

    recordItemValuesChanged(itemToUpdate as RecordedItem, newItem);
  };

  const onAddLotNumberForAddedItem = async (index: number, prefix?: string) => {
    if (!autoNumbering.part_lot_numbers?.enabled) {
      return;
    }
    if (!recorded?.added_items) {
      return;
    }

    const itemToUpdate = recorded.added_items[index];
    const updated = cloneDeep(itemToUpdate);
    updated.prefix = prefix;
    updated.lot = await services.builds.nextLotNumber(itemToUpdate.part_id, prefix);
    recordAddedItemValuesChanged(index, updated);
  };

  const onClearLotNumber = async (item: Partial<Item>, itemId: string | undefined) => {
    if (!autoNumbering.part_lot_numbers?.enabled) {
      return;
    }

    // item will not be in allItemsAndRecordedItems until lot number is set
    const itemToUpdate = allItemsAndRecordedItems?.find((item) => item.id === itemId) ?? item;
    const newItem = cloneDeep(itemToUpdate);
    (newItem as RecordedItem).prefix = '';
    newItem.lot = '';

    recordItemValuesChanged(itemToUpdate as RecordedItem, newItem);
  };

  const onClearLotNumberForAddedItem = async (index: number, prefix?: string) => {
    if (!autoNumbering.part_lot_numbers?.enabled) {
      return;
    }
    if (!recorded?.added_items) {
      return;
    }

    const itemToUpdate = recorded.added_items[index];
    const updated = cloneDeep(itemToUpdate);
    updated.prefix = '';
    updated.lot = '';
    recordAddedItemValuesChanged(index, updated);
  };

  const onRemoveSerialItem = (item: RecordedItem) => {
    const itemsUpdated = omit(recorded?.items, item.id);

    const updated = {
      ...recorded,
      items: itemsUpdated,
    };
    onRecordValuesChanged?.(content.id, updated);
  };

  const onRemoveAddedItem = (itemIndex: number) => {
    if (!recorded) {
      return;
    }
    const addedItems = [...(recorded.added_items ?? [])];
    addedItems.splice(itemIndex, 1);
    onRecordValuesChanged?.(content.id, { ...recorded, added_items: addedItems });
  };

  if (!parts) {
    return null;
  }

  return (
    <tr>
      <td></td>
      <td colSpan={2}>
        <div className={generateHiddenClassString('', isHidden)} />
        <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
          <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />

          <div className="flex items-start w-full py-1 mr-8">
            {/* Part components */}
            <table className="w-full table-fixed">
              <thead>
                <tr>
                  <td>
                    <div className="p-1">
                      <label htmlFor="components" className="text-sm font-medium uppercase">
                        Parts for Check-In
                      </label>
                    </div>
                  </td>
                  <td>
                    <div className="p-1">
                      <span className="text-sm font-medium uppercase">Quantity</span>
                    </div>
                  </td>
                  <td>
                    <div className="p-1">
                      <span className="text-sm font-medium uppercase">Serial / Lot #</span>
                    </div>
                  </td>
                  {/* search locations */}
                  <td></td>
                  {/* remove component button */}
                  <td className="w-6"></td>
                </tr>
              </thead>
              <tbody>
                {content?.items.map((item, index) => {
                  const part = getPart(item.part_id);
                  const checkedOutItemsForRev = checkedOutItems?.filter(
                    (checkedOutItem) =>
                      checkedOutItem.part.part_no === part?.part_no && checkedOutItem.part.rev === item.revision
                  );
                  if (part?.tracking === 'serial') {
                    return (
                      <FieldInputBuildItemsSerial
                        key={item.id}
                        item={{
                          ...item,
                          part_index: index,
                        }}
                        part={part}
                        isEnabled={isEnabled}
                        recorded={recorded}
                        teamId={teamId}
                        existingSerialNumbers={partIdToSerialMapping[part.id] || []}
                        isAddedDuringRun={false}
                        onAddItem={onAddSerialItem}
                        onRemoveItem={onRemoveSerialItem}
                        onRecordItemValuesChanged={recordItemValuesChanged}
                        onRecordErrorsChanged={onRecordErrorsChanged}
                        autoNumbering={autoNumbering.part_serial_numbers}
                        onAddSerial={(itemId, prefix) => onAddSerial(item, itemId, part.id, index, prefix)}
                        onClearSerial={(itemId) => onClearSerial(item, itemId)}
                        checkedOutItems={checkedOutItemsForRev}
                      />
                    );
                  } else {
                    return (
                      <FieldInputBuildItem
                        key={item.id}
                        item={item}
                        part={part}
                        isEnabled={isEnabled}
                        recorded={recorded?.items[item.id]}
                        autoNumbering={autoNumbering.part_lot_numbers}
                        onAddLotNumber={async (itemId?: string, prefix?: string) =>
                          part && onAddLotNumber(item, itemId, part.id, index, prefix)
                        }
                        onClearLotNumber={(itemId?: string) => onClearLotNumber(item, itemId)}
                        onRecordValuesChanged={(values) => recordItemValuesChanged(item, values)}
                        onRecordErrorsChanged={onRecordErrorsChanged}
                        teamId={teamId}
                        canRemoveItem={false}
                        checkedOutItems={checkedOutItemsForRev}
                      />
                    );
                  }
                })}
                {(recorded?.added_items ?? []).map((item, index) => {
                  const part = getPart(item.part_id);
                  const checkedOutItemsForRev = checkedOutItems?.filter(
                    (checkedOutItem) =>
                      checkedOutItem.part.part_no === part?.part_no && checkedOutItem.part.rev === item.revision
                  );
                  if (part?.tracking === 'serial') {
                    return (
                      <FieldInputBuildItemsSerial
                        key={item.id}
                        item={item}
                        part={part}
                        isEnabled={isEnabled}
                        recorded={recorded}
                        teamId={teamId}
                        existingSerialNumbers={partIdToSerialMapping[part.id] || []}
                        isAddedDuringRun={true}
                        onRemoveItem={() => onRemoveAddedItem(index)}
                        onRecordItemValuesChanged={(_, values) => recordAddedItemValuesChanged(index, values)}
                        onRecordErrorsChanged={onRecordErrorsChanged}
                        autoNumbering={autoNumbering.part_serial_numbers}
                        onAddSerial={(_, prefix) => onAddSerialForAddedItem(index, prefix)}
                        onClearSerial={() => onClearSerialForAddedItem(index)}
                        checkedOutItems={checkedOutItemsForRev}
                      />
                    );
                  } else {
                    return (
                      <FieldInputBuildItem
                        key={item.id}
                        item={item}
                        part={part}
                        isEnabled={isEnabled}
                        recorded={item}
                        autoNumbering={autoNumbering.part_lot_numbers}
                        onAddLotNumber={(_, prefix) => onAddLotNumberForAddedItem(index, prefix)}
                        onClearLotNumber={() => onClearLotNumberForAddedItem(index)}
                        onRecordValuesChanged={(values) => recordAddedItemValuesChanged(index, values)}
                        onRecordErrorsChanged={onRecordErrorsChanged}
                        teamId={teamId}
                        canRemoveItem={true}
                        onRemoveItem={() => onRemoveAddedItem(index)}
                        checkedOutItems={checkedOutItemsForRev}
                      />
                    );
                  }
                })}
                {!isStepComplete && (
                  <tr>
                    <td colSpan={4}>
                      <PartAndRevisionPusher onPush={onPushPart} isDisabled={!isEnabled} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </td>
    </tr>
  );
};

export default PartBuild;
