import React, { useMemo } from 'react';
import { Form, Formik } from 'formik';
import UpdateOnDirty from '../UpdateOnDirty';
import useBlockComponents from '../../hooks/useBlockComponents';
import { getRedlineDisabledFields } from './BlockTypes';
import MenuSubmitSuggestedEdit from '../MenuSubmitSuggestedEdit';

/**
 * Component for redlining a procedure block when running a procedure.
 *
 * @param {object} props
 * @param {object} props.block - A Block object to make redline changes.
 * @param {boolean} props.isHidden - True to hide all block content (expand/collapse).
 * @param {function} props.onDirtyChanged - Whether the user has made any redline changes in the form.
 * @param {() => void} props.onCancel - Function callback when a redline change is cancelled.
 * @param {(values, formikHelpers) => void} props.onSubmit - Function callback when a redline change is submitted.
 *           Type fn(values) where values is the updated Block object.
 * @param {import('formik').FormikConfig['innerRef']} [props.formikRef] - React ref to Formik instance.
 * @param {import('shared/lib/types/views/procedures').Step} [props.step]
 */
export default function ProcedureBlockEdit({ block, isHidden, onDirtyChanged, onCancel, onSubmit, formikRef, step }) {
  const { TypedProcedureBlock, TypedFieldSet } = useBlockComponents({ blockType: block.type });

  const disabledFields = useMemo(() => getRedlineDisabledFields(block.type), [block.type]);
  const runOnly = useMemo(
    () =>
      step &&
      'run_only' in step &&
      /** @type {import('shared/lib/types/views/procedures').RunAddedStep} */ (step)?.run_only,
    [step]
  );

  return (
    <TypedProcedureBlock isHidden={isHidden}>
      {/* Form */}
      <Formik
        innerRef={formikRef}
        initialValues={{
          ...block,
          editType: runOnly ? 'Blueline' : 'Redline',
        }}
        onSubmit={onSubmit}
      >
        {({ errors, touched, dirty, handleSubmit, isSubmitting, setFieldValue }) => (
          <Form onSubmit={handleSubmit} className="w-full">
            <UpdateOnDirty onDirtyChanged={onDirtyChanged} />
            <div className="w-full relative">
              <div className="w-full text-sm group flex py-1">
                {/* Render form fields for specific block type */}
                <TypedFieldSet
                  path=""
                  content={block}
                  contentErrors={errors}
                  contentTouched={touched}
                  disabledFields={disabledFields}
                  setFieldValue={setFieldValue}
                />
              </div>
            </div>
            <MenuSubmitSuggestedEdit
              isRedlineSubmitDisabled={!dirty || isSubmitting}
              isRedlineCancelDisabled={isSubmitting}
              onRedlineCancel={onCancel}
              onSubmit={handleSubmit}
              runOnly={runOnly}
            />
          </Form>
        )}
      </Formik>
    </TypedProcedureBlock>
  );
}
