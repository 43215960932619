import ItemSelect from './ItemSelect';
import { getUsageTypeLabel, isPartRestricted, getPartRevisionId } from '../lib/parts';
import SubstepNumber from '../../components/SubstepNumber';
import { generateHiddenClassString } from '../../lib/styles';
import isNumber from '../../lib/number';
import { Item } from '../types';
import { Part, UsageType } from 'shared/lib/types/postgres/manufacturing/types';
import { cloneDeep, isEqual } from 'lodash';
import PartAndUsageTypeSelect from './PartAndUsageTypeSelect';
import PartLabel from '../components/PartLabel';
import RunControlledInput from '../../elements/RunControlledInput';
import useParts from '../hooks/useParts';

const INVALID_AMOUNT_ERROR = 'Enter a non-negative number';

const PartUsage = ({
  content,
  errors,
  recorded,
  blockLabel,
  teamId,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  isEnabled,
  isHidden,
}) => {
  const partIds = content?.part?.id ? [content.part.id] : undefined;
  const { parts, getPart, getPartByRevisionId } = useParts({ partIds, includeAllReleasedRevisions: true });

  if (!parts) {
    return null;
  }

  const partRevisionId = content?.part ? getPartRevisionId(content.part) : undefined;
  let retrievedPart;
  // Use the specified revision if we can find it
  if (partRevisionId) {
    retrievedPart = getPartByRevisionId(partRevisionId);
  }
  // Fall back to using the current revision
  if (!retrievedPart) {
    retrievedPart = getPart(content?.part?.id);
  }
  const partRestricted = Boolean(retrievedPart) && isPartRestricted(retrievedPart);

  const selectedPartforUsage = recorded && recorded[0]?.part;
  const selectedPartIdforUsage = recorded && recorded[0]?.part_id;
  const selectedUsageType = recorded && recorded[0]?.usage_type;

  let usageTypeSelected;
  if ((content && content.usage_types && content.usage_types.length > 0) || selectedUsageType) {
    usageTypeSelected = content.usage_types[0] || selectedUsageType;
  }

  const onChangeItem = (item: Item): void => {
    let updated = cloneDeep(recorded);
    if (!updated) {
      updated = [{}];
    }

    updated[0].item_id = item?.id;
    updated[0].item = item;
    updated[0].amount = recorded?.[0]?.amount ?? '';

    onRecordValuesChanged?.(content?.id, updated);
  };

  const onChangePart = (part: Part): void => {
    let updated = cloneDeep(recorded);

    if (!updated) {
      updated = [{}];
    }

    updated[0].part_id = part?.id;
    updated[0].part = part;

    if (updated[0].usage_type) {
      updated[0].usage_type = null;
    }
    if (updated[0].amount) {
      updated[0].amount = null;
    }

    onRecordValuesChanged?.(content?.id, updated);
  };

  const onChangeUsage = (usage_type: UsageType): void => {
    let updated = cloneDeep(recorded);
    if (!updated) {
      updated = [{}];
    }

    updated[0].usage_type = usage_type;

    onRecordValuesChanged?.(content?.id, updated);
  };

  const checkErrors = (amount: string): void => {
    let newError = {};
    if (!isNumber(amount) || Number(amount) < 0) {
      newError = { amount: INVALID_AMOUNT_ERROR };
    }
    if (!isEqual(errors, newError)) {
      onRecordErrorsChanged(newError);
    }
  };

  const onRecordAmount = (amount: string): void => {
    let item = null;
    let item_id = null;
    if (recorded && recorded.length > 0) {
      item = recorded[0].item;
      item_id = recorded[0].item_id;
    }
    let updated = cloneDeep(recorded);
    if (!updated) {
      updated = [{}];
    }

    updated[0].item_id = item_id;
    updated[0].item = item;
    updated[0].amount = amount;

    onRecordValuesChanged?.(content?.id, updated);
  };

  const itemSelected = recorded && recorded.length > 0 && recorded[0].item;
  const itemIdSelected = itemSelected?.id;
  const contentPartNo = content?.part?.part_no || selectedPartforUsage?.part_no || '';
  const contentPartId = content?.part_id || selectedPartIdforUsage || '';
  const contentPartRev = content?.part?.rev !== undefined ? content.part.rev : selectedPartforUsage?.rev;

  return (
    <>
      <tr>
        <td></td>

        <td colSpan={2}>
          <div className={generateHiddenClassString('', isHidden)} />
          <div className={generateHiddenClassString('mt-3 ml-4 flex flex-wrap page-break', isHidden)}>
            <SubstepNumber blockLabel={blockLabel} hasExtraVerticalSpacing={false} />
            <div className="text-gray-500 text-sm tracking-wide">Part Usage</div>

            <div className="flex flex-col w-full pr-4 gap-y-6">
              {!content?.part ? (
                <tr>
                  <td></td>
                  <td colSpan={2}>
                    <PartAndUsageTypeSelect
                      content={content}
                      onChangePartDuringRun={onChangePart}
                      onChangeUsage={onChangeUsage}
                      recorded={recorded}
                      isEnabled={isEnabled}
                    />
                  </td>
                </tr>
              ) : (
                <div>
                  <div className="flex flex-col">
                    <PartLabel teamId={teamId} part={retrievedPart} />
                  </div>
                </div>
              )}

              {!partRestricted && (
                <div className="flex gap-x-2">
                  <div className="w-56">
                    <div className="field-title">Item</div>
                    <ItemSelect
                      itemId={itemIdSelected}
                      partId={contentPartId}
                      partRevision={contentPartRev}
                      isDisabled={!isEnabled || (contentPartNo === '' ? true : false)}
                      onChangeItem={onChangeItem}
                      usageTypeName={usageTypeSelected?.name}
                      includeInactive={true}
                    />
                  </div>

                  <div>
                    <div className="field-title">Usage</div>
                    <RunControlledInput
                      data-testid="usage-amount"
                      className="px-2 h-[2.4rem] w-16 border border-gray-400 rounded disabled:bg-gray-200 disabled:bg-opacity-50"
                      disabled={!isEnabled || (contentPartNo === '' ? true : false)}
                      onRecordValue={onRecordAmount}
                      onChange={(amount) => checkErrors(amount)}
                      recorded={recorded?.[0]?.amount}
                    />
                  </div>
                  <div className="mb-2 flex flex-row">
                    <div className="self-end ">
                      {usageTypeSelected ? `${getUsageTypeLabel(usageTypeSelected)}` : ''}
                    </div>
                    {errors && errors.amount && (
                      <div className="ml-2 mt-6">
                        <div className="whitespace-nowrap text-sm self-end text-red-700">{errors.amount}</div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};

export default PartUsage;
