import { CouchLikeOperation, OperationState } from 'shared/lib/types/operations';
import { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import RunStatusLabel from '../RunStatusLabel';
import Button, { BUTTON_TYPES } from '../Button';
import AvatarStack from '../AvatarStack';
import { useAuth } from '../../contexts/AuthContext';
import { DatabaseServices } from '../../contexts/proceduresSlice';
import {
  CANNOT_END_OPERATION_WITH_RUNNING_PROCEDURES_MESSAGE,
  CANNOT_START_EMPTY_OPERATION_MESSAGE,
  END_OPERATION_MESSAGE,
} from '../../screens/OperationDetail';
import { useMixpanel } from '../../contexts/MixpanelContext';
import runUtil from '../../lib/runUtil';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { operationViewPath } from '../../lib/pathUtil';
import { PERM } from '../../lib/auth';
import LoadingScreen from '../LoadingScreen';
import OperationUtil from '../../lib/operationUtil';
import Grid, { GridColumn, TextAlign } from '../../elements/Grid';

interface OperationsTableProps {
  operations: Array<CouchLikeOperation>;
  loading: boolean;
  setState: (state: OperationState) => void;
  state: OperationState;
  mainVerticalPadding: number;
}

type OperationRow = CouchLikeOperation & {
  participants: Array<string>;
  progress?: Progress;
  actionDisabled?: boolean;
  actionDisabledTitle?: string;
};

type Progress = {
  complete: number;
  total: number;
};

const renderActions = (
  row: OperationRow,
  startOperation: (opName: string) => void,
  endOperation: (opName: string) => void
) => {
  switch (row.state) {
    case 'running':
      return (
        <div className="text-right pr-2">
          <Button
            type={BUTTON_TYPES.PRIMARY}
            isDisabled={row.actionDisabled}
            onClick={() => endOperation(row.name)}
            size="sm"
            title={row.actionDisabledTitle}
          >
            End
          </Button>
        </div>
      );
    case 'planning':
      return (
        <div className="text-right pr-2">
          <Button
            type={BUTTON_TYPES.PRIMARY}
            isDisabled={row.actionDisabled}
            onClick={() => startOperation(row.name)}
            size="sm"
            title={row.actionDisabledTitle}
          >
            Start
          </Button>
        </div>
      );
  }
  return null;
};

const getColumns = (
  teamId: string,
  state: OperationState,
  startOperation: (opName: string) => void,
  endOperation: (opName: string) => void,
  setState: (state: OperationState) => void
): Array<GridColumn<OperationRow>> => {
  const nameCol: GridColumn<OperationRow> = {
    key: 'name',
    name: 'Name',
    sortable: true,
    renderCell({ row }: { row: OperationRow }) {
      return (
        <div className="text-base truncate">
          <Link to={operationViewPath(teamId, row.key)} className="font-medium text-blue-600 hover:underline truncate">
            {row.name}
          </Link>
          <div className="text-xs text-gray-400 truncate">
            <Link to={operationViewPath(teamId, row.key)}>{row.description}</Link>
          </div>
        </div>
      );
    },
  };
  const stateCol: GridColumn<OperationRow> = {
    key: 'state',
    name: 'Status',
    renderCell({ row }: { row: OperationRow }) {
      return <RunStatusLabel statusText={row.state ?? 'running'} onClick={() => setState(row.state)} />;
    },
  };
  const participantsCol: GridColumn<OperationRow> = {
    key: 'participants',
    name: 'Participants',
    renderCell({ row }: { row: OperationRow }) {
      return <AvatarStack userIds={row.participants} />;
    },
  };
  const progressCol: GridColumn<OperationRow> = {
    key: 'progress',
    name: 'Progress',
    sortable: true,
    align: TextAlign.Right,
    renderCell({ row }: { row: OperationRow }) {
      if (row.state === 'planning') {
        return null;
      }
      const p = row.progress;
      return (
        p && (
          <div className="flex">
            <span className="w-8 text-right pr-1">{p.complete}</span>
            <span className="text-center">/</span>
            <span className="w-8 text-left pl-1">{p.total}</span>
            <span className="w-12 text-right">
              ({p.total === 0 ? ' - ' : Math.round((100 * p.complete) / p.total)}%)
            </span>
          </div>
        )
      );
    },
    comparator: (a: OperationRow, b: OperationRow) => {
      if (!a.progress) {
        return -1;
      }
      if (!b.progress) {
        return 1;
      }
      if (a.progress.total === 0 && b.progress.total === 0) {
        return 0;
      }
      if (a.progress.total === 0) {
        return -1;
      }
      if (b.progress.total === 0) {
        return 1;
      }
      return a.progress.complete / a.progress.total - b.progress.complete / b.progress.total;
    },
  };
  const actionsCol: GridColumn<OperationRow> = {
    key: 'actions',
    name: 'Actions',
    align: TextAlign.Right,
    renderCell({ row }: { row: OperationRow }) {
      return renderActions(row, startOperation, endOperation);
    },
  };

  const PLANNING_WIDTHS = ['70%', '15%', '14.9%'];
  const RUNNING_WIDTHS = ['52%', '12%', '12%', '12%', '11.9%'];
  const ENDED_WIDTHS = ['64%', '12%', '12%', '11.9%'];

  switch (state) {
    case 'planning':
      return [nameCol, stateCol, actionsCol].map((col, colIndex) => ({
        ...col,
        width: PLANNING_WIDTHS[colIndex],
      }));
    case 'running':
      return [nameCol, stateCol, participantsCol, progressCol, actionsCol].map((col, colIndex) => ({
        ...col,
        width: RUNNING_WIDTHS[colIndex],
      }));
    case 'ended':
      return [nameCol, stateCol, participantsCol, progressCol].map((col, colIndex) => ({
        ...col,
        width: ENDED_WIDTHS[colIndex],
      }));
  }
};

const OperationsTable = ({ operations, loading, setState, state, mainVerticalPadding }: OperationsTableProps) => {
  const { auth } = useAuth();
  const { services, currentTeamId }: { services: DatabaseServices; currentTeamId: string } = useDatabaseServices();
  const { mixpanel } = useMixpanel();

  const mixpanelTrack = useCallback(
    (trackingKey: string, properties?: object | undefined) => {
      if (mixpanel) {
        mixpanel.track(trackingKey, properties);
      }
    },
    [mixpanel]
  );

  const startOperation = useCallback(
    async (opName: string) => {
      try {
        const results = await services.settings.startOperation(opName);
        mixpanelTrack('Start Operation', { Source: 'Operations List' });
        return results;
      } catch (err) {
        // ignore
      }
    },
    [mixpanelTrack, services]
  );

  const endOperation = useCallback(
    async (opName: string) => {
      if (!window.confirm(END_OPERATION_MESSAGE)) {
        return Promise.resolve();
      }

      try {
        const results = await services.settings.endOperation(opName);
        mixpanelTrack('End Operation', { Source: 'Operations List' });
        return results;
      } catch (err) {
        // ignore
      }
    },
    [mixpanelTrack, services.settings]
  );

  const rows = useMemo(() => {
    return operations
      .filter((op) => op.state === state)
      .map((op) => {
        const runs = op.runs ?? [];
        const operationUtil = new OperationUtil(op.procedures, runs);
        const progress = (() => {
          if (op.state !== 'planning') {
            return {
              complete: runs.filter((run) => run.state === 'completed').length,
              total: operationUtil.numUnstartedProcedures() + runs.length,
            };
          }
        })();
        const actionDisabled = (() => {
          if (!auth.hasPermission(PERM.RUNS_EDIT)) {
            return true;
          }
          switch (op.state) {
            case 'running':
              return (
                operationUtil.hasUnstartedProcedures() || runs.filter((run) => run.state !== 'completed').length > 0
              );
            case 'planning':
              return op.procedures.length === 0;
          }
        })();
        const actionDisabledTitle = (() => {
          if (!auth.hasPermission(PERM.RUNS_EDIT)) {
            return 'Insufficient permissions';
          }
          switch (op.state) {
            case 'running':
              return actionDisabled ? CANNOT_END_OPERATION_WITH_RUNNING_PROCEDURES_MESSAGE : 'End Operation';
            case 'planning':
              return actionDisabled ? CANNOT_START_EMPTY_OPERATION_MESSAGE : 'Start Operation';
          }
        })();
        return {
          ...op,
          participants: runUtil.getUserParticipantIds(runs),
          progress,
          actionDisabled,
          actionDisabledTitle,
        };
      });
  }, [auth, state, operations]);

  return (
    <>
      {loading && <LoadingScreen />}
      <Grid
        key={state}
        columns={getColumns(currentTeamId, state, startOperation, endOperation, setState)}
        rows={rows}
        usedVerticalSpace={mainVerticalPadding}
        emptyRowMessage="No operations found"
      />
    </>
  );
};

export default OperationsTable;
