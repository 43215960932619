import {
  CreatePart,
  UpdatePart,
} from 'shared/lib/types/api/manufacturing/parts/requests';
import {
  NextLotNumberRcvRes,
  NextPartNumRcvRes,
  NextSerialRcvRes,
} from 'shared/lib/types/api/manufacturing/parts/responses';
import superlogin from '../../api/superlogin';
import { API_URL } from '../../config';

export type CreatePartsReq = {
  parts: Array<CreatePart>;
};

export type GetPartReq = {
  partId: number;
  revisionId?: number;
};

export type UpdatePartReq = UpdatePart;

export type DeletePartsReq = {
  data: { ids: Array<number> };
};

/**
 * Public manufacturing routes.
 */
class BuildsService {
  static instances = {};

  static getInstance = (teamId: string): BuildsService => {
    if (!BuildsService.instances[teamId]) {
      BuildsService.instances[teamId] = new BuildsService(teamId);
    }

    return BuildsService.instances[teamId];
  };

  static removeInstance = (teamId: string): void => {
    delete BuildsService.instances[teamId];
  };

  private readonly teamId: string;
  private readonly restUrl: string;

  constructor(teamId: string) {
    this.teamId = teamId;
    this.restUrl = `${API_URL}/teams/${this.teamId}/builds`;
  }

  async nextPartNumber(prefix: string | undefined): Promise<string> {
    const url = `${this.restUrl}/parts/part-numbers${
      prefix ? `?prefix=${prefix}` : ''
    }`;
    const response = await superlogin.getHttp().post<NextPartNumRcvRes>(url);
    return response.data.part_number;
  }

  async nextSerial(partId: string, prefix?: string): Promise<string> {
    const url = `${this.restUrl}/parts/${partId}/serials${
      prefix ? `?prefix=${prefix}` : ''
    }`;
    const response = await superlogin.getHttp().post<NextSerialRcvRes>(url);
    return response.data.serial;
  }

  async nextLotNumber(partId: string, prefix?: string): Promise<string> {
    const url = `${this.restUrl}/parts/${partId}/lot-numbers${
      prefix ? `?prefix=${prefix}` : ''
    }`;
    const response = await superlogin.getHttp().post<NextLotNumberRcvRes>(url);
    return response.data.lot_number;
  }
}

export default BuildsService;
