import React, { Fragment, useCallback } from 'react';
import useBlockRedlines from '../../hooks/useBlockRedlines';
import useBlockComponents from '../../hooks/useBlockComponents';

/*
 * Component for rendering a procedure block when running or viewing a procedure.
 * Supports showing redline history changes.
 *
 * block: A Block object to render.
 * redlines: A list of RedlineBlock objects with revision history.
 * recorded: An arbitrary object with recorded values for the given Block.
 * contentIndex: The current contentIndex of this Block in the parent Step.
 *               In the future, this could be refactored out.
 * isEnabled: True if recording new Block values should be enabled.
 * isHidden: True to hide all block content (expand/collapse)
 * onRecordValuesChanged: Callback for when user enters new recorded values.
 *                        Type fn(recorded), where recorded is any arbitrary object.
 * onRecordErrorsChanged: Callback when there are errors in user-submitted recorded
 *                        values. Type fn(errors), where errors is a dictionary
 *                        of type { fieldName: String error }
 * onRecordUploadingChanged: Callback when user recorded values are uploading. Type
 *                           fn(isUploading), where isUploading is a boolean that is
 *                           true if the content block is currently uploading data.
 */
const ProcedureBlockRun = ({
  blockLabel,
  block,
  redlines,
  recorded,
  contentIndex,
  isEnabled,
  isHidden,
  onRecordValuesChanged,
  onRecordErrorsChanged,
  onRecordUploadingChanged,
  isSpacerHidden,
  isDark,
  onContentRefChanged,
  scrollMarginTopValueRem,
}) => {
  // Keep latestApprovedField for back-compat for old redlines that were accepted.
  const { latestApprovedBlock } = useBlockRedlines({
    block,
    redlines,
  });

  const { TypedProcedureBlock, TypedBlock } = useBlockComponents({ blockType: block.type });

  const recordValueChangedHandler = useCallback(
    (recorded) => {
      onRecordValuesChanged && onRecordValuesChanged(block.id, recorded);
    },
    [block.id, onRecordValuesChanged]
  );

  const recordErrorsChangedHandler = useCallback(
    (errors) => {
      onRecordErrorsChanged && onRecordErrorsChanged(contentIndex, errors);
    },
    [contentIndex, onRecordErrorsChanged]
  );

  const recordUploadingChangedHandler = useCallback(
    (isUploading) => {
      onRecordUploadingChanged(contentIndex, isUploading);
    },
    [contentIndex, onRecordUploadingChanged]
  );

  return (
    <Fragment>
      {/* Render specific block content */}
      <TypedProcedureBlock
        blockLabel={blockLabel}
        isHidden={isHidden}
        actions={[]}
        isSpacerHidden={isSpacerHidden}
        hasExtraVerticalSpacing={isEnabled && block.inputType === 'checkbox'}
      >
        <TypedBlock
          block={latestApprovedBlock}
          blockId={block.id}
          recorded={recorded}
          isEnabled={isEnabled}
          isDark={isDark}
          onRecordValuesChanged={recordValueChangedHandler}
          onRecordErrorsChanged={recordErrorsChangedHandler}
          onRecordUploadingChanged={recordUploadingChangedHandler}
          onContentRefChanged={onContentRefChanged}
          scrollMarginTopValueRem={scrollMarginTopValueRem}
        />
      </TypedProcedureBlock>
    </Fragment>
  );
};

export default ProcedureBlockRun;
