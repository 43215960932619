import React, { useMemo, useState } from 'react';
import Comment from './Comment';
import { ReviewComment } from 'shared/lib/types/views/procedures';
import ChildrenReviewComments from './ChildrenReviewComments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/*
 * Component for rendering all Parent Child Review Comments.
 *
 */

interface ParentReviewCommentProps {
  childComments?: Array<ReviewComment>;
  parentId?: string;
  parentComment: ReviewComment;
  stepId?: string;
  onResolveComment: (commentId: string) => void;
  onUnresolveComment: (commentId: string) => void;
  saveReviewComment?: (comment: ReviewComment) => Promise<void>;
  editReviewComment?: (comment: ReviewComment) => Promise<void>;
  isDocStateActive: boolean;
  onFieldRefChanged?: (id: string, element: HTMLElement | null) => void;
}

const ParentReviewComment = ({
  childComments,
  parentId,
  parentComment,
  stepId,
  onResolveComment,
  onUnresolveComment,
  saveReviewComment,
  editReviewComment,
  isDocStateActive,
  onFieldRefChanged,
}: ParentReviewCommentProps) => {
  const [formVisible, setFormVisible] = useState(false);
  const [parentCollapsed, setParentCollapsed] = useState(parentComment.resolved);

  const onSetCommentFormVisible = (isVisible) => {
    setFormVisible(isVisible);
  };

  const onSetParentCollapsed = () => {
    setParentCollapsed((parentCollapsed) => !parentCollapsed);
  };

  const buttonClass = useMemo(
    () =>
      'btn-link font-semibold px-2.5 py-1 text-sm rounded hover:bg-slate-300 disabled:bg-transparent disabled:text-gray-400 disabled:cursor-default',
    []
  );

  const commentButton = (
    <button className={buttonClass} onClick={() => setFormVisible(true)}>
      <FontAwesomeIcon icon="reply" /> Reply
    </button>
  );
  return (
    <>
      {/* Render Parent Review Comment */}
      <div className="bg-slate-200 rounded-lg mt-4">
        <Comment
          key={parentComment.id}
          comment={parentComment}
          onResolve={onResolveComment}
          onUnresolve={onUnresolveComment}
          editComment={editReviewComment}
          commentButton={
            (childComments && childComments.length > 0) || saveReviewComment === undefined || parentComment.resolved ? (
              <></>
            ) : (
              commentButton
            )
          }
          onFieldRefChanged={onFieldRefChanged}
          onRefChanged={undefined}
          error={undefined}
          isChild={false}
          onSetParentCollapsed={onSetParentCollapsed}
          parentCollapsed={parentCollapsed}
          mentionsEnabled={true}
        />

        {/* Render Children Comments handling backwards compatibility for older run comments */}
        {parentComment.id && (
          <ChildrenReviewComments
            childComments={childComments}
            parentId={parentId}
            stepId={stepId}
            isDocStateActive={isDocStateActive}
            onResolveComment={undefined}
            onUnresolveComment={undefined}
            saveReviewComment={saveReviewComment}
            editReviewComment={editReviewComment}
            onSetCommentFormVisible={onSetCommentFormVisible}
            formVisible={formVisible}
            commentButton={commentButton}
            parentCollapsed={parentCollapsed}
            canResolve={!parentComment.resolved}
          />
        )}
      </div>
    </>
  );
};

export default React.memo(ParentReviewComment);
