import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * A react-flow node for rendering a procedure section.
 *
 * @param data {Object} - A react-flow node data object.
 */
const SectionNode = ({ data }) => {
  return (
    <div className="flex h-full items-end">
      <div className="font-semibold">
        {data.label}
        {data.isRepeat && (
          <span className="ml-1 whitespace-nowrap" aria-label={`repeat ${data.repeatKey}`}>
            <FontAwesomeIcon icon="redo" className="text-sm" />
            {` Repeat ${data.repeatKey}`}
          </span>
        )}
      </div>
    </div>
  );
};

export default SectionNode;
