import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import DateTimeDisplay from '../DateTimeDisplay';
import SuggestedEditIcon from './SuggestedEditIcon';
import ReviewProcedureStep from '../Review/ReviewProcedureStep';
import diffUtil from '../../lib/diffUtil';
import CommentsList from '../CommentsList';
import React, { useMemo } from 'react';
import { RunStepFullRedline, RunStepRedline } from 'shared/lib/types/views/procedures';
import { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';

interface SuggestedEditHistoryItemProps {
  redline: RunStepRedline;
  previousRedline: RunStepRedline;
  isExpanded: boolean;
  onToggleExpandCollapse: () => void;
}

const SuggestedEditHistoryItem = ({
  redline,
  previousRedline,
  isExpanded,
  onToggleExpandCollapse,
}: SuggestedEditHistoryItemProps) => {
  const diffWithPrevious = useMemo(
    () =>
      diffUtil.getStepDiff({
        previous: previousRedline.step,
        updated: redline.step,
        ignoredFields: ['actions', 'id', 'actions', 'redline_id'],
      }),
    [previousRedline.step, redline.step]
  );

  const stepChanged = diffWithPrevious.diff_change_state === ARRAY_CHANGE_SYMBOLS.MODIFIED;

  return (
    <div
      className={`flex flex-col w-full py-2 px-2 gap-y-2 rounded border-2 bg-gray-50 ${
        redline.run_only ? 'border-blue-400' : 'border-red-400'
      }`}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="-mr-2 text-sm">
          <ExpandCollapseCaret
            isExpanded={isExpanded}
            onClick={onToggleExpandCollapse}
            ariaLabel="Expand/Collapse Suggested Edit Toggle"
            isHidden={false}
          />
          <span className="font-semibold mr-1">{[redline.user_id ?? redline.userId].join(', ')}</span>
          <DateTimeDisplay timestamp={redline.created_at ?? redline.createdAt ?? ''} />
        </div>
        <SuggestedEditIcon isBlueline={Boolean(redline.run_only)} />
      </div>
      {isExpanded && (
        <div>
          {stepChanged && (
            <ReviewProcedureStep
              step={diffWithPrevious}
              showDiffContainer={false}
              isCollapsed={false}
              isFullWidth={true}
              runId={undefined}
              projectId={undefined}
              stepKey={undefined}
              sectionId={undefined}
              sectionKey={undefined}
              sourceName={undefined}
              docState={undefined}
              operation={undefined}
              repeatKey={undefined}
              onStartLinkedRun={undefined}
              onRefChanged={undefined}
              saveNewComment={undefined}
              onResolveReviewComment={undefined}
              onUnresolveReviewComment={undefined}
              saveReviewComment={undefined}
              comments={undefined}
              isHidden={undefined}
              onStepCollapse={undefined}
              onAddIssue={undefined}
            />
          )}
          <CommentsList comments={(redline as RunStepFullRedline).comments ?? []} />
        </div>
      )}
    </div>
  );
};

export default SuggestedEditHistoryItem;
