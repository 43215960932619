import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Formik, Form, Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cloneDeep, pick } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import FieldSetProcedureStep from './FieldSetProcedureStep';
import ProcedureStep from './ProcedureStep';
import FieldSetProcedureSectionHeader from './FieldSetProcedureSectionHeader';
import ExpandCollapseCaret from './ExpandCollapse/ExpandCollapseCaret';
import { DroppableType } from './FormProcedure';
import ProcedureEditCommentsList from './ProcedureEditCommentsList';
import { useSettings } from '../contexts/SettingsContext';
import PromptSnippet from './Snippet/PromptSnippet';
import ModalDetachSnippet from './Snippet/ModalDetachSnippet';
import ModalSnippetSelect from './Snippet/ModalSnippetSelect';
import ModalSaveSnippet from './Snippet/ModalSaveSnippet';
import snippetUtil, { SNIPPET_SECTION_DELETED_AND_DETACHED } from '../lib/snippetUtil';
import validateUtil from '../lib/validateUtil';
import revisionsUtil from '../lib/revisions';
import DraftAddedStep from './DraftAddedStep';
import AddContentMenu from './ContentMenu/AddContentMenu';
import getStepAddContentItems from './ContentMenu/stepAddContentItems';
import { Actions } from './ContentMenu/addContentTypes';
import Selectable, { Boundary } from './Selection/Selectable';
import ModalSaveSnippetUnresolvedActions from './Snippet/ModalSaveSnippetUnresolvedActions';
import FlashMessage from './FlashMessage';
import clipboardUtil from '../lib/clipboardUtil';
import procedureUtil from '../lib/procedureUtil';
import {
  copyItemToClipboard,
  selectClipboardItem,
  selectProceduresNoDraftsForReleased,
} from '../contexts/proceduresSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { useMixpanel } from '../contexts/MixpanelContext';
import { BlockTypes } from './Blocks/BlockTypes';
import { EDIT_STICKY_HEADER_HEIGHT_REM } from './EditToolbar';
import { PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS } from '../screens/ProcedureEdit';
import VirtualizedAPI from '../elements/Virtualized/VirtualizedAPI';
import EditSectionDependencies from './StepDependency/EditSectionDependencies';

const OPTIMIZATION_THRESHOLD = 3;

const FieldSetProcedureSection = ({
  section,
  sectionIndex,
  snippetsMap,
  errors,
  onInsertStepSnippet,
  onSaveStepSnippet,
  onSectionHeaderFormChanged,
  onAddStep,
  onAddStepHeader,
  onAddSection,
  onRemoveStep,
  onRemoveStepHeader,
  onRemoveSectionHeader,
  configurePartKitBlock,
  configurePartBuildBlock,
  onPartChanged,
  onFieldRefChanged,
  isCollapsedMap,
  onCollapse,
  enabledContentTypes,
  comments,
  onSectionFormChanged,
  onStepFormChanged,
  onSaveReviewComment,
  onResolveComment,
  onUnresolveComment,
  onAcceptRedlineField,
  onRejectRedlineField,
  onAcceptRedlineBlock,
  onRejectRedlineBlock,
  onAcceptRedlineStep,
  onRejectRedlineStep,
  stepRedlineMap, // Assuming this to be optional as of EPS-2988 (MR 1058),
  changedStepIdSet,
  setChangedStepIdSet,
  isSaveStepSnippetDisabled,
  isRestrictedToSnippetContents,
  areDependenciesEnabled,
  isTestProcedure = false,
}) => {
  const store = useStore();
  const { getSetting } = useSettings();
  const { mixpanel } = useMixpanel();
  const { currentTeamId } = useDatabaseServices();
  const [isSectionCollapsed, setIsSectionCollapsed] = useState(() => isCollapsedMap[section.id]);
  const [showsSaveSnippet, setShowsSaveSnippet] = useState(false);
  const [currentSnippetMetadata, setCurrentSnippetMetadata] = useState({
    index: 0,
    step: undefined,
  });

  /**
   * Track section errors internally, since validation can be triggered at the
   * procedure level (review procedure) and at the section level (save snippet).
   *
   * `sectionErrors` should be the results of validating this section individually,
   * but allowing the procedure to override it with the `errors` prop.
   */
  const [sectionErrors, setSectionErrors] = useState(errors);
  const [snippetModalState, setSnippetModalState] = useState({
    stepIndex: 0,
    hidden: true,
  });
  const [modalDetachSnippetState, setModalDetachSnippetState] = useState({
    step: null,
    stepIndex: 0,
    hidden: true,
  });
  const [modalSaveSnippetUnresolvedActionsState, setModalSaveSnippetUnresolvedActionsState] = useState({
    hidden: true,
  });

  // Move updates to the event loop to prevent rendering backlog.
  useEffect(() => {
    window.setTimeout(() => {
      setIsSectionCollapsed(isCollapsedMap[section.id]);
      VirtualizedAPI.refresh(PROCEDURE_EDIT_VIRTUALIZED_ELEMENTS);
    }, 0);
  }, [isCollapsedMap, section.id]);

  // Override section errors with procedure validation.
  useEffect(() => {
    setSectionErrors(errors);
  }, [errors]);

  const syncSectionToProcedure = useCallback(
    (values) => {
      onSectionFormChanged(values, sectionIndex);
    },
    [onSectionFormChanged, sectionIndex]
  );

  const toggleIsSectionCollapsed = useCallback(() => {
    onCollapse(section.id, !isSectionCollapsed);
  }, [isSectionCollapsed, section.id, onCollapse]);

  // TODO: DRY this up, possibly by creating a MixpanelContext.js
  const mixpanelTrack = useCallback(
    (trackingKey) => {
      if (mixpanel && trackingKey) {
        mixpanel.track(trackingKey);
      }
    },
    [mixpanel]
  );

  const getStepRedlines = useCallback((stepId) => stepRedlineMap?.get(stepId) ?? [], [stepRedlineMap]);

  const hasUnresolvedFeedback = useCallback(
    (stepId) => {
      const stepComments = procedureUtil.getCommentsByReferenceId(comments, stepId);
      return (
        stepRedlineMap?.get(stepId) ||
        revisionsUtil.getUnresolvedRedlineComments(stepComments).length > 0 ||
        revisionsUtil.getUnresolvedParentReviewComments(stepComments).length > 0
      );
    },
    [comments, stepRedlineMap]
  );

  const onProposeSaveStepAsSnippet = useCallback(
    async (index, step) => {
      setCurrentSnippetMetadata({
        step,
        index,
      });

      const contentTypeErrors = snippetUtil.validateStepForSnippet(step);
      if (contentTypeErrors && contentTypeErrors.type === 'unsupported_content') {
        window.alert(contentTypeErrors['unsupported_content']);
        return;
      }

      if (hasUnresolvedFeedback(step.id)) {
        setModalSaveSnippetUnresolvedActionsState({
          hidden: false,
        });
        return;
      }

      // Validate step.
      const procedures = selectProceduresNoDraftsForReleased(store.getState(), currentTeamId); // get state on demand to prevent re-rendering of component every time procedures change
      const { errors: validationErrors } = await validateUtil.validateStep({
        step,
        teamId: currentTeamId,
        procedures,
        showRedlineValidation: true,
      });

      if (Object.keys(validationErrors).length !== 0) {
        setSectionErrors((current) => {
          const updated = cloneDeep(current) || {};
          if (!updated.steps) {
            updated.steps = {};
          }
          updated.steps[step.id] = validationErrors;
          return updated;
        });
        return;
      }

      setShowsSaveSnippet(true);
    },
    [currentTeamId, hasUnresolvedFeedback, store]
  );

  const getSectionHeaderErrors = useCallback(
    (sectionHeaderId) => {
      if (!sectionErrors || !sectionErrors.headers) {
        return null;
      }
      return sectionErrors.headers[sectionHeaderId];
    },
    [sectionErrors]
  );

  const dependencyErrors = useMemo(() => {
    if (!sectionErrors || !sectionErrors.dependencies) {
      return null;
    }
    return sectionErrors.dependencies;
  }, [sectionErrors]);

  const fieldRef = useCallback((field) => (element) => onFieldRefChanged(field, element), [onFieldRefChanged]);

  const stepComments = useCallback((stepId) => procedureUtil.getCommentsByReferenceId(comments, stepId), [comments]);

  const initialValues = useMemo(() => pick(section, ['name', 'dependencies']), [section]);

  const initialErrors = useMemo(() => pick(sectionErrors, ['name', 'dependencies']), [sectionErrors]);

  const getStepErrors = useCallback(
    (stepId) => {
      if (!sectionErrors || !sectionErrors.steps) {
        return null;
      }
      return sectionErrors.steps[stepId];
    },
    [sectionErrors]
  );

  const getStepCommentErrors = useCallback(
    (stepId) => {
      if (!sectionErrors || !sectionErrors.steps || !sectionErrors.steps[stepId]) {
        return null;
      }
      return sectionErrors.steps[stepId].comments;
    },
    [sectionErrors]
  );

  const onDetachStepSnippet = useCallback(() => {
    const step = modalDetachSnippetState.step;
    const stepIndex = modalDetachSnippetState.stepIndex;
    mixpanelTrack('Step Snippet Detached');
    const updated = cloneDeep(step);
    snippetUtil.detachSnippet(updated);
    onStepFormChanged(updated, sectionIndex, stepIndex);
    setModalDetachSnippetState({
      step: null,
      stepIndex: 0,
      hidden: true,
    });
  }, [onStepFormChanged, sectionIndex, mixpanelTrack, modalDetachSnippetState]);

  const getOnRemoveStepSnippet = useCallback(
    (stepIndex) => {
      return () => {
        mixpanelTrack('Step Snippet Removed');
        const updated = cloneDeep(section);
        updated.steps.splice(stepIndex, 1);

        const numSteps = procedureUtil.numNonAddedStepsInSection(updated);
        if (numSteps === 0) {
          // insert new step at beginning of section
          updated.steps.unshift(procedureUtil.newStep());
        }

        onSectionFormChanged(updated, sectionIndex);
      };
    },
    [onSectionFormChanged, section, sectionIndex, mixpanelTrack]
  );

  const getOnAcceptLatestStepSnippet = useCallback(
    ({ snippet, stepIndex, stepSnippetIdsMap }) => {
      return () => {
        if (!snippet) {
          return;
        }
        mixpanelTrack('Step Snippet Accepted Latest Version');
        const step = procedureUtil.copyStep(snippet.step, stepSnippetIdsMap);
        step.snippet_id = snippet.snippet_id;
        step.snippet_name = snippet.name;
        step.snippet_rev = snippet.revision;
        step.snippet_ids_map = stepSnippetIdsMap;
        onStepFormChanged(step, sectionIndex, stepIndex);
      };
    },
    [onStepFormChanged, sectionIndex, mixpanelTrack]
  );

  const onSelectSnippet = useCallback(
    (snippet) => {
      onInsertStepSnippet(snippetModalState.stepIndex, snippet);
      setSnippetModalState({
        stepIndex: 0,
        hidden: true,
      });
    },
    [snippetModalState, onInsertStepSnippet]
  );

  const onConfirmSaveStepAsSnippet = useCallback(
    async (values) => {
      if (!showsSaveSnippet || !currentSnippetMetadata || !onSaveStepSnippet) {
        return;
      }

      return onSaveStepSnippet(currentSnippetMetadata.index, values, currentSnippetMetadata.step)
        .then(() => {
          setCurrentSnippetMetadata({
            index: 0,
            step: undefined,
          });
          setShowsSaveSnippet(false);
        })
        .catch(() => {});
    },
    [currentSnippetMetadata, onSaveStepSnippet, showsSaveSnippet]
  );

  const dispatch = useDispatch();
  const [flashMessage, setFlashMessage] = useState('');
  const clipboardItem = useSelector((state) => selectClipboardItem(state, currentTeamId));
  const clipboardStep = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const clipboardStep = clipboardUtil.getStepFromClipboardItem(clipboardItem);
    if (
      !clipboardStep ||
      (isRestrictedToSnippetContents &&
        (snippetUtil.isSnippet(clipboardStep) || snippetUtil.validateStepForSnippet(clipboardStep) !== undefined))
    ) {
      return undefined;
    }
    return clipboardStep;
  }, [clipboardItem, isRestrictedToSnippetContents]);
  const clipboardBlock = useMemo(() => {
    if (!clipboardItem) {
      return undefined;
    }
    const clipboardBlock = clipboardUtil.getBlockFromClipboardItem(clipboardItem);
    if (
      !clipboardBlock ||
      (isRestrictedToSnippetContents && snippetUtil.validateBlockForSnippet(clipboardBlock) !== undefined)
    ) {
      return undefined;
    }
    return clipboardBlock;
  }, [clipboardItem, isRestrictedToSnippetContents]);

  const onCopyStep = useCallback(
    ({ stepIndex }) => {
      mixpanelTrack('Copy Step');
      const copiedStep = procedureUtil.copyStep(section.steps[stepIndex]);
      const clipboardItemStep = clipboardUtil.createClipboardItemStep(copiedStep);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemStep));
      setFlashMessage('Step copied');
    },
    [currentTeamId, dispatch, mixpanelTrack, section]
  );

  const onCutStep = useCallback(
    ({ stepIndex }) => {
      mixpanelTrack('Cut Step');
      const copiedStep = procedureUtil.copyStep(section.steps[stepIndex]);
      const clipboardItemStep = clipboardUtil.createClipboardItemStep(copiedStep);
      dispatch(copyItemToClipboard(currentTeamId, clipboardItemStep));
      onRemoveStep(stepIndex);
      setFlashMessage('Step cut');
    },
    [currentTeamId, dispatch, mixpanelTrack, section, onRemoveStep]
  );

  const onPasteStep = useCallback(
    ({ stepIndex }) => {
      if (!clipboardStep) {
        return;
      }
      mixpanelTrack('Paste Step');
      const updatedSection = cloneDeep(section);
      const stepCopy = procedureUtil.copyStep(clipboardStep);
      updatedSection.steps.splice(stepIndex + 1, 0, stepCopy);
      syncSectionToProcedure(updatedSection);
    },
    [clipboardStep, mixpanelTrack, section, syncSectionToProcedure]
  );

  const onPasteBlock = useCallback(
    ({ stepIndex }) => {
      if (!clipboardBlock) {
        return;
      }
      mixpanelTrack('Paste Block');
      const updatedSection = cloneDeep(section);
      const blockCopy = procedureUtil.copyBlock(clipboardBlock);
      updatedSection.steps[stepIndex].content.push(blockCopy);
      syncSectionToProcedure(updatedSection);
    },
    [clipboardBlock, mixpanelTrack, section, syncSectionToProcedure]
  );

  const onPasteBlockIntoSectionHeader = useCallback(
    ({ headerIndex }) => {
      if (
        !clipboardBlock ||
        // @ts-ignore enabledContentTypes should be of type string[]
        ![BlockTypes.Alert, BlockTypes.Text, BlockTypes.Attachment].includes(clipboardBlock.type)
      ) {
        return;
      }
      mixpanelTrack('Paste Block');
      const updatedSection = cloneDeep(section);
      const blockCopy = procedureUtil.copyBlock(clipboardBlock);
      updatedSection.headers[headerIndex].content.push(blockCopy);
      syncSectionToProcedure(updatedSection);
    },
    [clipboardBlock, mixpanelTrack, section, syncSectionToProcedure]
  );

  const onDependenciesUpdated = useCallback(
    (dependencies) => {
      const updated = cloneDeep(section);
      updated.dependencies = dependencies;
      syncSectionToProcedure(updated);
    },
    [syncSectionToProcedure, section]
  );

  const onRemoveDepedencies = useCallback(() => {
    const updated = cloneDeep(section);
    delete updated.dependencies;
    syncSectionToProcedure(updated);
  }, [syncSectionToProcedure, section]);

  const onContentMenuClick = (menuItem, stepIndex, step) => {
    mixpanelTrack(menuItem.label);
    switch (menuItem.action) {
      case Actions.AddStepHeader:
        return onAddStepHeader(stepIndex);
      case Actions.AddStep:
        return onAddStep(stepIndex);
      case Actions.AddSection:
        return onAddSection && onAddSection();
      case Actions.InsertSnippet:
        return setSnippetModalState({
          stepIndex,
          hidden: false,
        });
      case Actions.DeleteStep:
        return onRemoveStep(stepIndex);
      case Actions.CopyStep:
        return onCopyStep({ stepIndex });
      case Actions.CutStep:
        return onCutStep({ stepIndex });
      case Actions.PasteStep:
        return onPasteStep({ stepIndex });
      case Actions.PasteBlock:
        return onPasteBlock({ stepIndex });
      case Actions.SaveAsSnippet:
        return onProposeSaveStepAsSnippet(stepIndex, step);
      case Actions.DeleteStepHeader:
        return onRemoveStepHeader(stepIndex);
      default:
        return;
    }
  };

  const onKeyboard = useCallback(
    (event, boundary, index) => {
      if (boundary === Boundary.SectionHeader) {
        if (event.code === 'Backspace' || event.code === 'Delete') {
          onRemoveSectionHeader();
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
          if (clipboardBlock) {
            onPasteBlockIntoSectionHeader({ headerIndex: index });
          }
        }
      } else if (boundary === Boundary.Step) {
        if (event.code === 'Backspace' || event.code === 'Delete') {
          onRemoveStep(index);
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyC') {
          onCopyStep({ stepIndex: index });
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyX') {
          onCutStep({ stepIndex: index });
        } else if ((event.metaKey || event.ctrlKey) && event.code === 'KeyV') {
          if (clipboardStep) {
            onPasteStep({ stepIndex: index });
          } else if (clipboardBlock) {
            onPasteBlock({ stepIndex: index });
          }
        }
      } else if (boundary === Boundary.SectionDependency) {
        if (event.code === 'Backspace' || event.code === 'Delete') {
          onRemoveDepedencies();
        }
      }
    },
    [
      clipboardBlock,
      clipboardStep,
      onCopyStep,
      onCutStep,
      onPasteBlock,
      onPasteBlockIntoSectionHeader,
      onPasteStep,
      onRemoveSectionHeader,
      onRemoveStep,
      onRemoveDepedencies,
    ]
  );

  return (
    <>
      {/* Cannot save snippet with unresolved actions modal */}
      {!modalSaveSnippetUnresolvedActionsState.hidden && (
        <ModalSaveSnippetUnresolvedActions
          onSecondaryAction={() => {
            setModalSaveSnippetUnresolvedActionsState({
              hidden: true,
            });
          }}
        />
      )}

      {/* Save section snippet modal */}
      {showsSaveSnippet && (
        <ModalSaveSnippet
          onPrimaryAction={onConfirmSaveStepAsSnippet}
          onSecondaryAction={() => setShowsSaveSnippet(false)}
        />
      )}

      {/* Insert step snippet modal */}
      {!snippetModalState.hidden && (
        <ModalSnippetSelect
          onSelectSnippet={onSelectSnippet}
          onSecondaryAction={() =>
            setSnippetModalState({
              stepIndex: 0,
              hidden: true,
            })
          }
          isTestSnippet={isTestProcedure}
        />
      )}

      {/* Detach Snippet modal */}
      {!modalDetachSnippetState.hidden && (
        <ModalDetachSnippet
          onClickProceed={onDetachStepSnippet}
          onClickCancel={() =>
            setModalDetachSnippetState({
              step: null,
              stepIndex: 0,
              hidden: true,
            })
          }
        />
      )}

      {/* onSubmit dummy function needed to avoid runtime error:
        https://github.com/formium/formik/issues/2675 */}
      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        onSubmit={() => {}}
        validateOnChange={false}
        validate={syncSectionToProcedure}
        enableReinitialize
      >
        {() => (
          <Form>
            <div ref={fieldRef(`${section.id}`)} style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}>
              {section.shows_snippet_detached_message && (
                <div className="flex grow app-bg-blue-note ml-2 mt-2.5 px-2 py-1 rounded mb-1">
                  {SNIPPET_SECTION_DELETED_AND_DETACHED}
                </div>
              )}
              <div key={`section.${sectionIndex}.name`} className="flex">
                <div className="h-9">
                  <ExpandCollapseCaret
                    isExpanded={!isSectionCollapsed}
                    onClick={toggleIsSectionCollapsed}
                    ariaLabel={isSectionCollapsed ? 'Expand section' : 'Collapse section'}
                  />
                </div>
                <div className="w-24 h-9 flex items-center text-left text-lg">
                  <span className="w-24 shrink-0 font-semibold">
                    Section{' '}
                    {procedureUtil.displaySectionKey(sectionIndex, getSetting('display_sections_as', 'letters'))}
                  </span>
                </div>
                <div
                  className="w-full flex flex-col"
                  ref={fieldRef(`${section.id}.name`)}
                  style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                >
                  <Field
                    type="text"
                    name="name"
                    placeholder="Section name*"
                    className="text-sm border-1 border-gray-400 rounded"
                  />
                  {sectionErrors && sectionErrors.name && <div className="text-red-700">{sectionErrors.name}</div>}
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {/* Section dependencies */}
      {section.dependencies && !isSectionCollapsed && (
        <div className="w-full mt-4">
          <div className="flex items-start group">
            <div className="w-full ml-11 mr-1 bg-white rounded">
              <Selectable
                key={`section-${section.id}-dependencies`}
                boundary={Boundary.SectionDependency}
                block={section.dependencies}
                onKeyboard={(event) => onKeyboard(event, Boundary.SectionDependency)}
              >
                {({ styles }) => (
                  <div className={styles}>
                    <EditSectionDependencies
                      dependencies={section.dependencies}
                      sectionId={section.id}
                      onDependenciesUpdated={onDependenciesUpdated}
                      errors={dependencyErrors}
                    />
                  </div>
                )}
              </Selectable>
            </div>
            <button
              type="button"
              title="Remove Dependencies"
              onClick={onRemoveDepedencies}
              className="flex items-start"
              tabIndex={-1}
            >
              <FontAwesomeIcon
                icon="times-circle"
                className="px-1 text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100 cursor-pointer"
              />
            </button>
          </div>
        </div>
      )}

      {/* Section headers */}
      {section.headers && !isSectionCollapsed && (
        <div className="w-full mt-4">
          {section.headers.map((sectionHeader, sectionHeaderIndex) => (
            <div className="flex items-start group">
              <div className="w-full ml-11 mr-1 bg-white rounded">
                <Selectable
                  key={sectionHeader.id}
                  boundary={Boundary.SectionHeader}
                  block={sectionHeader}
                  onKeyboard={(event) => onKeyboard(event, Boundary.SectionHeader, sectionHeaderIndex)}
                >
                  {({ styles }) => (
                    <div className={`py-1 ${styles}`}>
                      <FieldSetProcedureSectionHeader
                        key={sectionHeader.id}
                        sectionHeader={sectionHeader}
                        errors={getSectionHeaderErrors(sectionHeader.id)}
                        sectionIndex={sectionIndex}
                        index={sectionHeaderIndex}
                        isSectionHeaderCollapsed={isCollapsedMap[sectionHeader.id]}
                        onSectionHeaderCollapse={onCollapse}
                        onFieldRefChanged={onFieldRefChanged}
                        onSectionHeaderFormChanged={onSectionHeaderFormChanged}
                        onResolveComment={onResolveComment}
                        onUnresolveComment={onUnresolveComment}
                        onSaveReviewComment={onSaveReviewComment}
                        comments={procedureUtil.getCommentsByReferenceId(comments, sectionHeader.id)}
                      />
                    </div>
                  )}
                </Selectable>
              </div>
              <button
                type="button"
                title="Remove Section Header"
                className="flex items-start"
                tabIndex={-1}
                onClick={onRemoveSectionHeader}
              >
                <FontAwesomeIcon
                  icon="times-circle"
                  className="px-1 text-gray-400 hover:text-gray-500 opacity-0 group-hover:opacity-100 cursor-pointer"
                ></FontAwesomeIcon>
              </button>
            </div>
          ))}
        </div>
      )}

      {/* Section steps */}
      {!isSectionCollapsed && (
        <div>
          <Droppable droppableId={section.id} type={DroppableType.Step}>
            {(droppableProvided, droppableSnapshot) => (
              <div
                ref={droppableProvided.innerRef}
                className={`flex flex-col gap-y-2 border-2 border-dashed mt-2 ${
                  droppableSnapshot.isDraggingOver ? 'border-gray-400' : 'border-transparent'
                }`}
                {...droppableProvided.droppableProps}
              >
                {section.steps &&
                  section.steps.map((step, stepIndex) => (
                    <Selectable
                      key={step.id}
                      boundary={Boundary.Step}
                      block={step}
                      onKeyboard={(event) => onKeyboard(event, Boundary.Step, stepIndex)}
                    >
                      {({ isSelected, styles }) => (
                        <Draggable key={step.id} draggableId={step.id} index={stepIndex}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className="flex flex-nowrap items-start group/step"
                              aria-label="Step"
                              role="region"
                            >
                              {/* Hover step items */}
                              <div
                                className={`h-9 flex flex-row mt-3 gap-x-2 items-center ${
                                  isSelected ? 'opacity-100' : 'opacity-0 group-hover/step:opacity-50'
                                }`}
                              >
                                <AddContentMenu
                                  menuItems={getStepAddContentItems({
                                    isPartKitStep: step.type === 'part_kit' || step.type === 'part_build',
                                    hasStepHeader: step.headers?.length > 0,
                                    canPasteStep: !!clipboardStep,
                                    canPasteBlock: !!clipboardBlock,
                                    canSaveAsSnippet: !isSaveStepSnippetDisabled,
                                    canAddSection: onAddSection && stepIndex === section.steps.length - 1,
                                    isSnippet: snippetUtil.isSnippet(step),
                                  })}
                                  onClick={(menuItem) => onContentMenuClick(menuItem, stepIndex, step)}
                                />
                                {/* Drag and drop grip */}
                                <div
                                  {...provided.dragHandleProps}
                                  className="text-gray-400 opacity-0 group-hover/step:opacity-100"
                                  tabIndex={null}
                                >
                                  {!snippetUtil.isTestSnippet(step) && <FontAwesomeIcon icon="grip-vertical" />}
                                </div>
                              </div>

                              <div className={`ml-2 w-full min-w-0 ${styles}`}>
                                {/* Step content */}
                                <div className="flex flex-col justify-center">
                                  {!step.created_during_run && !step.snippet_id && (
                                    <FieldSetProcedureStep
                                      step={step}
                                      stepIndex={stepIndex}
                                      sectionIndex={sectionIndex}
                                      isPending={false}
                                      errors={getStepErrors(step.id)}
                                      isCollapsed={isCollapsedMap[step.id]}
                                      isStepHeadersEnabled={true}
                                      onFieldRefChanged={onFieldRefChanged}
                                      onStepCollapse={onCollapse}
                                      configurePartKitBlock={configurePartKitBlock}
                                      configurePartBuildBlock={configurePartBuildBlock}
                                      onPartChanged={onPartChanged}
                                      onStepFormChanged={onStepFormChanged}
                                      onAcceptRedlineField={onAcceptRedlineField}
                                      onRejectRedlineField={onRejectRedlineField}
                                      onAcceptRedlineBlock={onAcceptRedlineBlock}
                                      onRejectRedlineBlock={onRejectRedlineBlock}
                                      onRemoveStepHeader={() => onRemoveStepHeader(stepIndex)}
                                      redlines={getStepRedlines(step.id)}
                                      changedStepIdSet={changedStepIdSet}
                                      setChangedStepIdSet={setChangedStepIdSet}
                                      enabledContentTypes={enabledContentTypes}
                                      precedingStepId={undefined} // KURT: This doesn't seem to be used properly - sending undefined for now
                                      comments={stepComments(step.id)}
                                      areDependenciesEnabled={areDependenciesEnabled}
                                      optimize={section.steps.length > OPTIMIZATION_THRESHOLD}
                                    />
                                  )}
                                  {step.created_during_run && !step.snippet_id && (
                                    <div
                                      className="w-full flex flex-col"
                                      ref={fieldRef(`${step.id}.stepAddedDuringRun`)}
                                      style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                                    >
                                      <DraftAddedStep
                                        sectionIndex={sectionIndex}
                                        errors={getStepErrors(step.id)}
                                        stepIndex={stepIndex}
                                        addedStep={step}
                                        onAcceptRedlineStep={onAcceptRedlineStep}
                                        onRejectRedlineStep={onRejectRedlineStep}
                                        isCollapsedMap={isCollapsedMap}
                                        onCollapse={onCollapse}
                                      />
                                    </div>
                                  )}
                                  {step.snippet_id && (
                                    <>
                                      <div
                                        className="w-full flex flex-col"
                                        ref={fieldRef(`${step.id}.unresolvedSnippet`)}
                                        style={{ scrollMarginTop: `${EDIT_STICKY_HEADER_HEIGHT_REM}rem` }}
                                      ></div>
                                      <PromptSnippet
                                        onAcceptLatest={getOnAcceptLatestStepSnippet({
                                          snippet: snippetsMap[step.snippet_id],
                                          stepIndex,
                                          stepSnippetIdsMap: step.snippet_ids_map || {},
                                        })}
                                        onDetach={() =>
                                          setModalDetachSnippetState({
                                            step,
                                            stepIndex,
                                            hidden: false,
                                          })
                                        }
                                        onRemove={getOnRemoveStepSnippet(stepIndex)}
                                        isCurrentLatest={
                                          !snippetsMap ||
                                          !snippetsMap[step.snippet_id] ||
                                          step.snippet_rev === snippetsMap[step.snippet_id].revision
                                        }
                                        currentName={step.snippet_name}
                                        latestName={
                                          snippetsMap &&
                                          snippetsMap[step.snippet_id] &&
                                          snippetsMap[step.snippet_id].name
                                        }
                                        currentVersion={
                                          <ProcedureStep
                                            step={step}
                                            stepKey={procedureUtil.displayStepKey(stepIndex)}
                                            sectionKey={procedureUtil.displaySectionKey(
                                              sectionIndex,
                                              getSetting('display_sections_as', 'letters')
                                            )}
                                            isCollapsed={isCollapsedMap[step.id]}
                                            onStepCollapse={onCollapse}
                                          />
                                        }
                                        latestVersion={
                                          snippetsMap && snippetsMap[step.snippet_id] ? (
                                            <ProcedureStep
                                              step={{
                                                ...snippetsMap[step.snippet_id].step,
                                                id: step.id,
                                              }}
                                              stepKey={procedureUtil.displayStepKey(stepIndex)}
                                              sectionKey={procedureUtil.displaySectionKey(
                                                sectionIndex,
                                                getSetting('display_sections_as', 'letters')
                                              )}
                                              isCollapsed={isCollapsedMap[step.id]}
                                              onStepCollapse={onCollapse}
                                            />
                                          ) : (
                                            <></>
                                          )
                                        }
                                      />
                                      {getStepErrors(step.id) && getStepErrors(step.id).unresolvedSnippet && (
                                        <div className="flex justify-end text-red-700">
                                          {getStepErrors(step.id).unresolvedSnippet}
                                        </div>
                                      )}
                                    </>
                                  )}
                                  <ProcedureEditCommentsList
                                    comments={stepComments(step.id)}
                                    errors={getStepCommentErrors(step.id)}
                                    onFieldRefChanged={onFieldRefChanged}
                                    isCollapsed={isCollapsedMap[step.id]}
                                    onSaveReviewComment={onSaveReviewComment}
                                    onResolveComment={onResolveComment}
                                    onUnresolveComment={onUnresolveComment}
                                    stepId={step.id}
                                  />
                                </div>
                              </div>
                              <div
                                className={`px-1 justify-end ${
                                  isSelected ? 'opacity-100' : 'opacity-0 group-hover/step:opacity-100'
                                }`}
                              >
                                <button
                                  type="button"
                                  title="Remove Step"
                                  tabIndex={-1}
                                  onClick={() => onRemoveStep(stepIndex)}
                                >
                                  <FontAwesomeIcon
                                    icon="times-circle"
                                    className="self-center text-gray-400 hover:text-gray-500"
                                  ></FontAwesomeIcon>
                                </button>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      )}
                    </Selectable>
                  ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      )}
      <FlashMessage
        message={flashMessage}
        // @ts-ignore
        messageUpdater={setFlashMessage}
      />
    </>
  );
};

export default FieldSetProcedureSection;
