import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo } from 'react';

const ActionIcon = ({ action }: { action: string }) => {
  const icon: IconProp = useMemo(() => {
    switch (action) {
      case 'cycles':
        return 'arrows-spin';
      case 'time':
      case 'seconds':
        return 'hourglass';
      case 'check-out':
        return 'upload';
      case 'check-in':
        return 'download';
      case 'detail update':
        return 'pen-to-square';
      default:
        return 'spinner';
    }
  }, [action]);

  return (
    <div>
      <FontAwesomeIcon className={`${icon === 'hourglass' ? 'pl-[1px]' : ''} mr-1.5 text-slate-700`} icon={icon} />
    </div>
  );
};

export default ActionIcon;
