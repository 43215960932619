import { useDispatch } from 'react-redux';
import _, { isNil } from 'lodash';
import { FEATURE_OFFLINE_RUN_ACTIONS_ENABLED } from '../config';
import {
  addLinkedRun,
  addStepComment,
  completeStep,
  endRun,
  signOffStep,
  skipStep,
  skipSection,
  repeatStep,
  repeatSection,
  startRun,
  addParticipant,
  removeParticipant,
  setOperation,
  clearOperation,
  updateRunTags,
  failStep,
  saveRedlineBlock,
  saveRedlineStepField,
  saveRedlineStepComment,
  updateBlock,
  saveRedlineHeader,
  updateStepDetail,
  addStep,
  addFullStepSuggestedEdit,
  includeFullStepSuggestedEdit,
  revokeStepSignoff,
  reopenRun,
} from '../contexts/runsSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import RunService, { RecordedBlocks, RepeatSectionOptions } from '../api/runs';
import idUtil from '../lib/idUtil';
import procedureUtil, { ProcedureFieldKey } from '../lib/procedureUtil';
import {
  HeaderRedlineMetadata,
  Run,
  RunHeader,
  RunRedlineComment,
  RunStep,
  RunStepBlock,
  RunStepComment,
  RunStepFullRedline,
  RunStepRedline,
} from 'shared/lib/types/views/procedures';
import tableUtil from 'shared/lib/tableUtil';
import timingUtil from 'shared/lib/timingUtil';
import attachmentUtil from '../lib/attachmentUtil';
import {
  updateDocWithComment,
  updateDocWithStepSignoffRevoked,
} from 'shared/lib/runUtil';
import { generateCommentId } from 'shared/lib/idUtil';
import { DatabaseServices } from '../contexts/proceduresSlice';
import { AxiosResponse } from 'axios';
import apm from '../lib/apm';
import { newStepRedline, REDLINE_TYPE } from 'shared/lib/redlineUtil';

type UseRunActionsReturns = {
  addLinkedRun: (
    teamId,
    run,
    sectionId,
    stepId,
    contentId,
    linkedRunId
  ) => void;
  addStepComment: ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    contentId,
    rowIndex,
    columnIndex,
    comment,
    isRunEnded,
  }: {
    teamId: string;
    run: Run;
    userId: string;
    sectionId: string;
    stepId: string;
    contentId?: string;
    rowIndex?: number;
    columnIndex?: number;
    comment: RunStepComment;
    isRunEnded: boolean;
  }) => Promise<void | AxiosResponse>;
  editStepComment: ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    contentId,
    rowIndex,
    columnIndex,
    comment,
    isRunEnded,
  }: {
    teamId: string;
    run: Run;
    userId: string;
    sectionId: string;
    stepId: string;
    contentId?: string;
    rowIndex?: number;
    columnIndex?: number;
    comment: RunStepComment;
    isRunEnded: boolean;
  }) => Promise<void | AxiosResponse>;
  addStepAfter: ({
    teamId,
    runId,
    userId,
    sectionId,
    precedingStepId,
    step,
    isRedline,
  }: {
    teamId: string;
    runId: string;
    userId: string;
    sectionId: string;
    precedingStepId: string;
    step: RunStep;
    isRedline: boolean;
  }) => Promise<void>;
  addFullStepSuggestedEdit: ({
    teamId,
    runId,
    sectionId,
    stepId,
    updatedStep,
    userId,
    includeInRun,
    isRedline,
    comments,
  }: {
    teamId: string;
    runId: string;
    userId: string;
    sectionId: string;
    stepId: string;
    updatedStep: RunStep;
    includeInRun: boolean;
    isRedline: boolean;
    comments: Array<RunRedlineComment>;
  }) => void;
  includeFullStepSuggestedEdit: ({
    teamId,
    runId,
    sectionId,
    stepId,
    userId,
    redline,
  }: {
    teamId: string;
    runId: string;
    userId: string;
    sectionId: string;
    stepId: string;
    redline: RunStepRedline;
  }) => void;
  completeStep: (teamId, run, userId, sectionId, stepId, recorded) => void;
  endRun: (
    teamId,
    run,
    userId,
    recorded,
    comment,
    status
  ) => Promise<void | AxiosResponse>;
  reopenRun: ({
    teamId,
    run,
    userId,
    comment,
  }: {
    teamId: string;
    run: Run;
    userId: string;
    comment: string;
  }) => void;
  signOffStep: (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    signoffId,
    operator,
    recorded,
    operatorRoles
  ) => void;
  pinSignOffStep: ({
    run,
    sectionId,
    stepId,
    signoffId,
    operator,
    pinUser,
    pin,
    recorded,
  }: {
    run: Run;
    sectionId: string;
    stepId: string;
    signoffId: string;
    operator: string;
    pinUser: string;
    pin: string;
    recorded: RecordedBlocks;
  }) => Promise<void>;
  revokeStepSignoff: ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    userOperatorRoles,
    signoffId,
  }: {
    teamId: string;
    run: Run;
    userId: string;
    sectionId: string;
    stepId: string;
    userOperatorRoles: Array<string>;
    signoffId: string;
  }) => void;
  skipStep: (teamId, run, userId, sectionId, stepId, recordedTelemetry) => void;
  skipSection: (
    teamId,
    run,
    userId,
    sectionId,
    skippedAt,
    recordedTelemetrySection
  ) => void;
  repeatStep: (
    teamId: string,
    run: Run,
    userId: string,
    recordedTelemetry: object,
    stepRepeat: RunStep,
    sectionId: string,
    stepId: string,
    includeRedlines: boolean
  ) => void;
  repeatSection: (
    teamId: string,
    run: Run,
    userId: string,
    recordedTelemetrySection: { steps: object } | null,
    sectionRepeatOptions: RepeatSectionOptions,
    sectionId: string,
    includeRedlines: boolean
  ) => void;
  startRun: (teamId, linkedRun) => void;
  addParticipant: (teamId, run, userId) => void;
  removeParticipant: (teamId, run, userId) => void;
  setOperation: (teamId, run, operation) => void;
  clearOperation: (teamId, run) => void;
  updateRunTags: (teamId, run, runTags) => void;
  failStep: (teamId, run, userId, sectionId, stepId, recorded) => void;
  saveRedlineBlock: (
    teamId: string,
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string,
    contentIndex: number,
    block: RunStepBlock,
    pending: boolean,
    isRedline: boolean
  ) => void;
  saveRedlineStepField: (
    teamId: string,
    run: Run,
    userId: string,
    stepId: string,
    stepField: ProcedureFieldKey,
    pending: boolean,
    isRedline: boolean
  ) => void;
  saveRedlineStepComment: (
    teamId,
    run,
    userId,
    stepId,
    text,
    commentId
  ) => void;
  updateBlock: (
    teamId,
    run,
    sectionId,
    stepId,
    contentId,
    recorded,
    userOperatorRoles,
    fieldIndex
  ) => void;
  saveVariable: (run, variable) => void;
  saveRedlineHeader: (
    teamId: string,
    run: Run,
    userId: string,
    header: RunHeader,
    headerRedlineMetadata: HeaderRedlineMetadata,
    pending: boolean,
    isRedline: boolean
  ) => void;
  updateStepDetails: (
    teamid: string,
    run: Run,
    userId: string,
    sectionId: string,
    stepId: string,
    field: string,
    value
  ) => void;
};

/*
 * This is a poor man's factory function, returning the associated run actions
 * depending on whether preview mode is enabled or not
 */
const useRunActions = (
  isPreviewMode: boolean,
  updatePreviewRun: (run) => void
): UseRunActionsReturns => {
  const dispatch = useDispatch();
  const { services }: { services: DatabaseServices } = useDatabaseServices();

  const liveAddLinkedRun = (
    teamId,
    run,
    sectionId,
    stepId,
    contentId,
    linkedRunId
  ) => {
    dispatch(
      addLinkedRun({
        teamId,
        run,
        sectionId,
        stepId,
        contentId,
        linkedRunId,
      })
    );
  };

  const previewAddLinkedRun = (
    teamId,
    run,
    sectionId,
    stepId,
    contentId,
    linkedRunId
  ) => {
    /* do nothing in preview mode */
  };

  const liveAddStepComment = ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    contentId,
    rowIndex,
    columnIndex,
    comment,
    isRunEnded,
  }: Parameters<UseRunActionsReturns['addStepComment']>[0]) => {
    comment.timestamp = new Date().toISOString();
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        addStepComment({
          teamId,
          runId: run._id,
          userId,
          sectionId,
          stepId,
          contentId,
          rowIndex,
          columnIndex,
          comment,
        })
      );
      return Promise.resolve();
    }
    return services.runs
      .addStepComment(run._id, comment, {
        sectionId,
        stepId,
        contentId,
        rowIndex,
        columnIndex,
      })
      .catch(() => {
        /* noop */
      });
  };

  const previewAddStepComment = ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    contentId,
    rowIndex,
    columnIndex,
    comment,
  }: Parameters<UseRunActionsReturns['addStepComment']>[0]) => {
    comment.timestamp = new Date().toISOString();
    updateDocWithComment({
      doc: run,
      userId,
      sectionId,
      stepId,
      contentId,
      rowIndex,
      columnIndex,
      comment,
    });
    updatePreviewRun(run);
    return Promise.resolve();
  };

  const liveEditStepComment = ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    contentId,
    rowIndex,
    columnIndex,
    comment,
    isRunEnded,
  }: Parameters<UseRunActionsReturns['editStepComment']>[0]) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        addStepComment({
          teamId,
          runId: run._id,
          userId,
          sectionId,
          stepId,
          contentId,
          rowIndex,
          columnIndex,
          comment,
        })
      );
      return Promise.resolve();
    }
    return services.runs
      .addStepComment(run._id, comment, {
        sectionId,
        stepId,
        contentId,
        rowIndex,
        columnIndex,
      })
      .catch(() => {
        /* noop */
      });
  };

  const previewEditStepComment = ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    contentId,
    rowIndex,
    columnIndex,
    comment,
  }: Parameters<UseRunActionsReturns['editStepComment']>[0]) => {
    updateDocWithComment({
      doc: run,
      userId,
      sectionId,
      stepId,
      contentId,
      rowIndex,
      columnIndex,
      comment,
    });
    updatePreviewRun(run);
    return Promise.resolve();
  };

  const liveAddStepAfter = async ({
    teamId,
    runId,
    userId,
    sectionId,
    precedingStepId,
    step,
    isRedline,
  }) => {
    const createdAt = new Date().toISOString();
    /*
     * The local upload of files needs to be done before sending the step to redux,
     * because file objects are not serializable, and need to be removed from the step.
     */
    await attachmentUtil.uploadAllFilesFromStep(
      step,
      services.attachments,
      false
    );
    dispatch(
      addStep({
        teamId,
        runId,
        userId,
        sectionId,
        precedingStepId,
        step,
        createdAt,
        runOnly: !isRedline,
      })
    );
  };

  const previewAddStepAfter = () => {
    // Not supported in preview mode
    return Promise.resolve();
  };

  const liveAddFullStepSuggestedEdit = async ({
    teamId,
    runId,
    sectionId,
    stepId,
    updatedStep,
    userId,
    includeInRun,
    isRedline,
    comments,
  }: {
    teamId: string;
    runId: string;
    userId: string;
    sectionId: string;
    stepId: string;
    updatedStep: RunStep;
    includeInRun: boolean;
    isRedline: boolean;
    comments: Array<RunRedlineComment>;
  }) => {
    /*
     * The local upload of files needs to be done before sending the step to redux,
     * because file objects are not serializable, and need to be removed from the step.
     */
    await attachmentUtil.uploadAllFilesFromStep(
      updatedStep,
      services.attachments,
      false
    );
    const createdAt = new Date().toISOString();
    const commentsWithMetadata = comments.map((comment) => {
      comment.user_id = userId;
      comment.created_at = createdAt;
      return comment;
    });
    const redline = newStepRedline({
      step: updatedStep,
      userId,
      pending: true,
      fieldOrBlockMetadata: {},
      isRedline,
      createdAt,
      comments: commentsWithMetadata,
      type: REDLINE_TYPE.FULL_STEP,
    }) as RunStepFullRedline;

    dispatch(
      addFullStepSuggestedEdit({
        teamId,
        runId,
        sectionId,
        stepId,
        redline,
        includeInRun,
      })
    );
  };

  const previewAddFullStepSuggestedEdit = () => {
    // Not supported in preview mode
    return Promise.resolve();
  };

  const liveIncludeFullStepSuggestedEdit = async ({
    teamId,
    runId,
    sectionId,
    stepId,
    userId,
    redline,
  }: {
    teamId: string;
    runId: string;
    userId: string;
    sectionId: string;
    stepId: string;
    redline: RunStepRedline;
  }) => {
    /*
     * The local upload of files needs to be done before sending the step to redux,
     * because file objects are not serializable, and need to be removed from the step.
     */
    await attachmentUtil.uploadAllFilesFromStep(
      redline.step,
      services.attachments,
      false
    );
    const includedAt = new Date().toISOString();

    dispatch(
      includeFullStepSuggestedEdit({
        teamId,
        userId,
        runId,
        sectionId,
        stepId,
        redline,
        includedAt,
      })
    );
  };

  const previewIncludeFullStepSuggestedEdit = () => {
    // Not supported in preview mode
    return Promise.resolve();
  };

  const liveCompleteStep = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    recorded
  ) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        completeStep({
          teamId,
          runId: run._id,
          userId,
          sectionId,
          stepId,
          recorded,
        })
      );
    } else {
      services.runs
        .completeStep(
          run._id,
          sectionId,
          stepId,
          new Date().toISOString(),
          recorded
        )
        .catch(() => {
          /* noop */
        });
    }
  };

  const previewCompleteStep = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    recorded
  ) => {
    timingUtil.updateRunWithDurations(run, sectionId, stepId);
    RunService.updateDocWithStepComplete(
      run,
      userId,
      sectionId,
      stepId,
      new Date().toISOString(),
      recorded
    );
    updatePreviewRun(run);
  };

  const liveEndRun = (teamId, run, userId, recorded, comment, status) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        endRun({
          teamId,
          run,
          userId,
          recorded,
          comment,
          status,
        })
      );
      return Promise.resolve();
    }
    return services.runs.endRun(run._id, recorded, comment, status);
  };

  const previewEndRun = (teamId, run, userId, recorded, comment, status) => {
    // Not supported in preview mode
    return Promise.resolve();
  };

  const liveReopenRun = ({ teamId, run, userId, comment }) => {
    dispatch(
      reopenRun({
        teamId,
        run,
        userId,
        comment,
      })
    );
    return Promise.resolve();
  };

  const previewReopenRun = ({ teamId, run, userId, comment }) => {
    // Not supported in preview mode
    return Promise.resolve();
  };

  const liveSignOffStep = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    signoffId,
    operator,
    recorded,
    operatorRoles
  ) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        signOffStep({
          teamId,
          runId: run._id,
          userId,
          sectionId,
          stepId,
          signoffId,
          operator,
          recorded,
          operatorRoles,
        })
      );
    } else {
      services.runs
        .signOffStep(
          run._id,
          sectionId,
          stepId,
          signoffId,
          new Date().toISOString(),
          operator,
          recorded
        )
        .catch(() => {
          /* noop */
        });
    }
  };

  const previewSignOffStep = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    signoffId,
    operator,
    recorded,
    operatorRoles
  ) => {
    timingUtil.updateRunWithDurations(run, sectionId, stepId);
    RunService.updateDocWithStepSignoff(
      run,
      userId,
      sectionId,
      stepId,
      signoffId,
      new Date().toISOString(),
      operator,
      recorded,
      new Set(operatorRoles)
    );
    updatePreviewRun(run);
  };

  const livePinSignOffStep = async ({
    run,
    sectionId,
    stepId,
    signoffId,
    operator,
    pinUser,
    pin,
    recorded,
  }) => {
    await services.runs.pinSignOffStep({
      runId: run._id,
      sectionId,
      stepId,
      signoffId,
      timestamp: new Date().toISOString(),
      operator,
      pinUser,
      pin,
      recorded,
    });
  };

  const previewPinSignOffStep = async ({
    run,
    sectionId,
    stepId,
    signoffId,
    operator,
    pinUser,
    recorded,
  }) => {
    timingUtil.updateRunWithDurations(run, sectionId, stepId);
    RunService.updateDocWithStepSignoff(
      run,
      pinUser,
      sectionId,
      stepId,
      signoffId,
      new Date().toISOString(),
      operator,
      recorded,
      new Set([operator])
    );
    updatePreviewRun(run);
  };

  const liveRevokeStepSignoff = ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    userOperatorRoles,
    signoffId,
  }) => {
    dispatch(
      revokeStepSignoff({
        teamId,
        runId: run._id,
        userId,
        sectionId,
        stepId,
        userOperatorRoles,
        signoffId,
      })
    );
  };

  const previewRevokeStepSignoff = ({
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    userOperatorRoles,
    signoffId,
  }) => {
    updateDocWithStepSignoffRevoked({
      run,
      userId,
      sectionId,
      stepId,
      userOperatorRolesSet: new Set(userOperatorRoles),
      signoffId,
      timestamp: new Date().toISOString(),
    });
    updatePreviewRun(run);
  };

  const liveSkipStep = (teamId, run, userId, sectionId, stepId, recorded) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        skipStep({
          teamId,
          run,
          runId: run._id,
          userId,
          sectionId,
          stepId,
          recorded,
        })
      );
    } else {
      services.runs
        .skipStep(
          run,
          userId,
          sectionId,
          stepId,
          new Date().toISOString(),
          recorded
        )
        .catch(() => {
          /* noop */
        });
    }
  };

  const previewSkipStep = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    recordedTelemetry
  ) => {
    timingUtil.updateRunWithDurations(run, sectionId, stepId);
    RunService.updateDocWithStepSkipped(
      run,
      userId,
      sectionId,
      stepId,
      new Date().toISOString(),
      recordedTelemetry
    );
    updatePreviewRun(run);
  };

  const liveSkipSection = (
    teamId,
    run,
    userId,
    sectionId,
    skippedAt,
    recordedTelemetrySection
  ) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        skipSection({
          teamId,
          run,
          runId: run._id,
          userId,
          sectionId,
          skippedAt,
          recordedTelemetrySection,
        })
      );
    } else {
      services.runs
        .skipSection(
          run,
          userId,
          sectionId,
          skippedAt,
          recordedTelemetrySection
        )
        .catch(() => {
          /* noop */
        });
    }
  };

  const previewSkipSection = (
    teamId,
    run,
    userId,
    sectionId,
    skippedAt,
    recordedTelemetrySection
  ) => {
    timingUtil.updateRunWithDurations(run, sectionId);
    RunService.updateDocWithSectionSkipped(
      run,
      userId,
      sectionId,
      skippedAt,
      recordedTelemetrySection
    );
    updatePreviewRun(run);
  };

  const liveRepeatStep = (
    teamId,
    run,
    userId,
    recordedTelemetry,
    stepRepeat,
    sectionId,
    stepId,
    includeRedlines
  ) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        repeatStep({
          teamId,
          run,
          runId: run._id,
          userId,
          recorded: recordedTelemetry,
          stepRepeat,
          sectionId,
          stepId,
          includeRedlines,
        })
      );
    } else {
      services.runs
        .repeatStep(
          run,
          userId,
          recordedTelemetry,
          stepRepeat,
          sectionId,
          stepId,
          includeRedlines
        )
        .catch(() => {
          /* noop */
        });
    }
  };

  const previewRepeatStep = (
    teamId,
    run,
    userId,
    recordedTelemetry,
    stepRepeat,
    sectionId,
    stepId
  ) => {
    timingUtil.updateRunWithDurations(run, sectionId, stepId);
    RunService.updateDocWithStepRepeated(
      run,
      userId,
      recordedTelemetry,
      stepRepeat,
      sectionId,
      stepId,
      false
    );
    updatePreviewRun(run);
  };

  const liveRepeatSection = (
    teamId,
    run,
    userId,
    recordedTelemetrySection,
    sectionRepeatOptions,
    sectionId,
    includeRedlines
  ) => {
    dispatch(
      repeatSection({
        teamId,
        run,
        runId: run._id,
        userId,
        recorded: recordedTelemetrySection,
        sectionRepeatOptions,
        sectionId,
        includeRedlines,
      })
    );
  };

  const previewRepeatSection = (
    teamId,
    run,
    userId,
    recordedTelemetrySection,
    sectionRepeatOptions,
    sectionId
  ) => {
    timingUtil.updateRunWithDurations(run, sectionId);
    RunService.updateDocWithSectionRepeated(
      run,
      userId,
      recordedTelemetrySection,
      sectionRepeatOptions,
      sectionId,
      false
    );
    updatePreviewRun(run);
  };

  const liveStartRun = (teamId, linkedRun) => {
    dispatch(
      startRun({
        teamId,
        run: linkedRun,
      })
    );
  };

  const previewStartRun = (teamId, linkedRun) => {
    /* no action in preview mode */
  };

  const liveAddParticipant = (teamId, run, userId) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        addParticipant({
          teamId,
          runId: run._id,
          userId,
        })
      );
    } else {
      services.runs
        .addParticipant(run._id, new Date().toISOString())
        .catch((err) => apm.captureError(err));
    }
  };

  const previewAddParticipant = (teamId, run, userId) => {
    RunService.updateDocWithParticipantAdded(
      run,
      userId,
      new Date().toISOString()
    );
    updatePreviewRun(run);
  };

  const liveRemoveParticipant = (teamId, run, userId) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        removeParticipant({
          teamId,
          runId: run._id,
          userId,
        })
      );
    } else {
      services.runs
        .removeParticipant(run._id, new Date().toISOString())
        .catch((err) => apm.captureError(err));
    }
  };

  const previewRemoveParticipant = (teamId, run, userId) => {
    RunService.updateDocWithParticipantRemoved(
      run,
      userId,
      new Date().toISOString()
    );
    updatePreviewRun(run);
  };

  const liveSetOperation = (teamId, run, newOperation) => {
    dispatch(
      setOperation({
        teamId,
        runId: run._id,
        newOperation,
        currentOperation: run.operation,
      })
    );
  };

  const liveClearOperation = (teamId, run) => {
    dispatch(
      clearOperation({
        teamId,
        runId: run._id,
        currentOperation: run.operation,
      })
    );
  };

  const previewSetOperation = (teamId, run, operation) => {
    RunService.updateDocWithOperation(run, _.pick(operation, 'name', 'key'));
    updatePreviewRun(run);
  };

  const previewClearOperation = (teamId, run) => {
    RunService.updateDocToClearOperation(run);
    updatePreviewRun(run);
  };

  const liveUpdateRunTags = (teamId, run, runTags) => {
    dispatch(
      updateRunTags({
        teamId,
        runId: run._id,
        newRunTags: runTags,
        currentRunTags: run.run_tags,
      })
    );
  };

  const previewUpdateRunTags = (teamId, run, runTags) => {
    RunService.updateDocWithRunTags(run, runTags);
    updatePreviewRun(run);
  };

  const liveFailStep = (teamId, run, userId, sectionId, stepId, recorded) => {
    dispatch(
      failStep({
        teamId,
        runId: run._id,
        userId,
        sectionId,
        stepId,
        recorded,
      })
    );
  };

  const previewFailStep = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    recorded
  ) => {
    timingUtil.updateRunWithDurations(run, sectionId, stepId);
    RunService.updateDocWithStepFailure(
      run,
      userId,
      sectionId,
      stepId,
      new Date().toISOString(),
      recorded
    );
    updatePreviewRun(run);
  };

  const liveSaveRedlineBlock = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    contentIndex,
    block,
    pending,
    isRedline
  ) => {
    dispatch(
      saveRedlineBlock({
        teamId,
        run,
        userId,
        sectionId,
        stepId,
        contentIndex,
        block,
        pending,
        isRedline,
      })
    );
  };

  const previewSaveRedlineBlock = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    contentIndex,
    block,
    pending
  ) => {
    /* No action in preview mode */
  };

  const liveSaveRedlineStepField = (
    teamId,
    run,
    userId,
    stepId,
    stepField,
    pending,
    isRedline
  ) => {
    dispatch(
      saveRedlineStepField({
        teamId,
        run,
        runId: run._id,
        userId,
        stepId,
        stepField,
        pending,
        isRedline,
      })
    );
  };

  const previewSaveRedlineStepField = (
    teamId,
    run,
    userId,
    stepId,
    stepField,
    pending,
    isRedline
  ) => {
    /* No action in preview mode */
  };

  const liveSaveRedlineStepComment = (
    teamId,
    run,
    userId,
    stepId,
    text,
    commentId
  ) => {
    dispatch(
      saveRedlineStepComment({
        teamId,
        run,
        runId: run._id,
        userId,
        stepId,
        text,
        commentId: commentId || generateCommentId(),
      })
    );
  };

  const previewSaveRedlineStepComment = (
    teamId,
    run,
    userId,
    stepId,
    text,
    commentId
  ) => {
    /* No action in preview mode */
  };

  const liveUpdateBlock = (
    teamId,
    run,
    sectionId,
    stepId,
    contentId,
    recorded,
    userOperatorRoles,
    fieldIndex
  ) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        updateBlock({
          teamId,
          runId: run._id,
          sectionId,
          stepId,
          contentId,
          recorded,
          userOperatorRoles,
          fieldIndex,
        })
      );
    } else {
      const timestamp = new Date().toISOString();
      const actionId = idUtil.generateLargeId();
      services.runs
        .updateBlockRecorded({
          runId: run._id,
          sectionId,
          stepId,
          contentId,
          actionId,
          recorded,
          timestamp,
          fieldIndex,
        })
        .catch((err) => apm.captureError(err));
    }
  };

  const previewUpdateBlock = (
    teamId,
    run,
    sectionId,
    stepId,
    contentId,
    recorded,
    userOperatorRoles,
    fieldIndex
  ) => {
    if (!recorded) {
      return;
    }
    const step = procedureUtil.getStepById(run, stepId);
    if (!step) {
      return;
    }
    const block = (step as RunStep).content.find(
      (block) => block.id === contentId
    );
    if (!block) {
      return;
    }
    let updated = recorded;
    if (block.type === 'table_input') {
      let currentRecord = block['preview_recorded'];
      if (!currentRecord) {
        currentRecord = {
          version: 1,
          values: tableUtil.getInitialRecordedCells(block),
        };
      }
      updated = _.cloneDeep(currentRecord);
      _.set(updated, ['values', recorded.row, recorded.column], recorded.value);
    }

    if (block.type === 'field_input_table' && !isNil(fieldIndex)) {
      block.fields[fieldIndex]['preview_recorded'] = updated;
    }

    // TODO: This is a hacky workaround for lack of substep sync.  If we keep it, we should add to RunStepBlock type
    block['preview_recorded'] = updated;
    updatePreviewRun(run);
  };

  const liveSaveVariable = (run, variable) => {
    if (!services.runs) {
      return;
    }
    // TODO EPS-2977: Use redux to make procedure variables (including attachments) work offline.
    if (variable?.value?.attachment_id) {
      (async () => {
        await services.attachments.syncAttachment(variable.value.attachment_id);
      })().catch((err) => apm.captureError(err));
    }
    services.runs
      .saveVariable(run, variable.name, variable)
      .catch((err) => apm.captureError(err));
  };

  const previewSaveVariable = (run, variable) => {
    const name = variable.name;
    const variableIndex = run.variables.findIndex(
      (variable) => variable.name.toLowerCase() === name.toLowerCase()
    );
    if (variableIndex !== -1) {
      run.variables[variableIndex] = variable;
    }
    updatePreviewRun(run);
  };

  const previewSaveRedlineHeader = (run, variable) => {
    /* No action in preview mode */
  };

  const liveSaveRedlineHeader = (
    teamId,
    run,
    userId,
    header,
    headerRedlineMetadata,
    pending,
    isRedline
  ) => {
    dispatch(
      saveRedlineHeader({
        teamId,
        run,
        userId,
        header,
        headerId: header.id,
        headerRedlineMetadata,
        pending,
        isRedline,
      })
    );
  };

  const previewUpdateStepDetails = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    field,
    value
  ) => {
    RunService.updateDocWithStepDetail(run, sectionId, stepId, field, value);
    updatePreviewRun(run);
  };

  const liveUpdateStepDetails = (
    teamId,
    run,
    userId,
    sectionId,
    stepId,
    field,
    value
  ) => {
    if (FEATURE_OFFLINE_RUN_ACTIONS_ENABLED) {
      dispatch(
        updateStepDetail({
          teamId,
          runId: run._id,
          sectionId,
          stepId,
          field,
          value,
        })
      );
    } else {
      services.runs
        .updateStepDetail({
          runId: run._id,
          sectionId,
          stepId,
          field,
          value,
        })
        .catch((err) => apm.captureError(err));
    }
  };

  if (isPreviewMode) {
    return {
      addLinkedRun: previewAddLinkedRun,
      addStepComment: previewAddStepComment,
      editStepComment: previewEditStepComment,
      addStepAfter: previewAddStepAfter,
      addFullStepSuggestedEdit: previewAddFullStepSuggestedEdit,
      includeFullStepSuggestedEdit: previewIncludeFullStepSuggestedEdit,
      completeStep: previewCompleteStep,
      endRun: previewEndRun,
      reopenRun: previewReopenRun,
      signOffStep: previewSignOffStep,
      pinSignOffStep: previewPinSignOffStep,
      revokeStepSignoff: previewRevokeStepSignoff,
      skipStep: previewSkipStep,
      skipSection: previewSkipSection,
      repeatStep: previewRepeatStep,
      repeatSection: previewRepeatSection,
      startRun: previewStartRun,
      addParticipant: previewAddParticipant,
      removeParticipant: previewRemoveParticipant,
      setOperation: previewSetOperation,
      clearOperation: previewClearOperation,
      updateRunTags: previewUpdateRunTags,
      failStep: previewFailStep,
      saveRedlineBlock: previewSaveRedlineBlock,
      saveRedlineStepField: previewSaveRedlineStepField,
      saveRedlineStepComment: previewSaveRedlineStepComment,
      updateBlock: previewUpdateBlock,
      saveVariable: previewSaveVariable,
      saveRedlineHeader: previewSaveRedlineHeader,
      updateStepDetails: previewUpdateStepDetails,
    };
  } else {
    return {
      addLinkedRun: liveAddLinkedRun,
      addStepComment: liveAddStepComment,
      editStepComment: liveEditStepComment,
      addStepAfter: liveAddStepAfter,
      addFullStepSuggestedEdit: liveAddFullStepSuggestedEdit,
      includeFullStepSuggestedEdit: liveIncludeFullStepSuggestedEdit,
      completeStep: liveCompleteStep,
      endRun: liveEndRun,
      reopenRun: liveReopenRun,
      signOffStep: liveSignOffStep,
      pinSignOffStep: livePinSignOffStep,
      revokeStepSignoff: liveRevokeStepSignoff,
      skipStep: liveSkipStep,
      skipSection: liveSkipSection,
      repeatStep: liveRepeatStep,
      repeatSection: liveRepeatSection,
      startRun: liveStartRun,
      addParticipant: liveAddParticipant,
      removeParticipant: liveRemoveParticipant,
      setOperation: liveSetOperation,
      clearOperation: liveClearOperation,
      updateRunTags: liveUpdateRunTags,
      failStep: liveFailStep,
      saveRedlineBlock: liveSaveRedlineBlock,
      saveRedlineStepField: liveSaveRedlineStepField,
      saveRedlineStepComment: liveSaveRedlineStepComment,
      updateBlock: liveUpdateBlock,
      saveVariable: liveSaveVariable,
      saveRedlineHeader: liveSaveRedlineHeader,
      updateStepDetails: liveUpdateStepDetails,
    };
  }
};

export default useRunActions;
