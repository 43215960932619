import { useMemo } from 'react';
import { PartList as PartListContent } from 'shared/lib/types/views/procedures';
import { useDatabaseServices } from '../../contexts/DatabaseContext';
import { generateHiddenClassString } from '../../lib/styles';
import useParts from '../hooks/useParts';
import PartBadge from './PartBadge';
import { getPartRevisionId, isPartRestricted } from '../lib/parts';
import RestrictedInfo from './RestrictedInfo';
import { Part } from 'shared/lib/types/postgres/manufacturing/types';

type PartListProps = {
  content?: PartListContent;
  isHidden: boolean;
};

const PartList = ({ content, isHidden }: PartListProps) => {
  const partIdsInBlock = useMemo(() => {
    if (content) {
      const partIds = [content.part_id];
      content.items.forEach((bomComponent) => partIds.push(bomComponent.part_id));
      return partIds;
    }
  }, [content]);
  const { parts, getPart, getPartByRevisionId } = useParts({
    partIds: partIdsInBlock,
    includeAllReleasedRevisions: true,
  });
  const { currentTeamId } = useDatabaseServices();

  if (!parts) {
    return null;
  }

  let bomPart: Part | undefined = undefined;
  let bomPartRestricted = false;
  if (content?.part) {
    const bomPartRevisionId = getPartRevisionId(content.part);
    bomPart = bomPartRevisionId ? getPartByRevisionId(bomPartRevisionId) : content.part;

    const retrievedPart = getPart(content?.part_id || '');
    bomPartRestricted = retrievedPart ? isPartRestricted(retrievedPart) : false;
  }

  return (
    <div className={generateHiddenClassString('flex flex-wrap page-break', isHidden)}>
      <div className="flex items-start w-full py-1 mr-8">
        <div className="w-full mx-8">
          <div>
            <span className="text-sm font-medium uppercase">Part to Build</span>
          </div>
          <PartBadge part={bomPart} teamId={currentTeamId} partRestricted={bomPartRestricted} />
          {/* Kit items */}
          {(content?.items?.length ?? 0) > 0 && (
            <table className="table-auto w-full mt-3">
              <thead>
                <tr>
                  <td>
                    <div className="pr-2 py-1">
                      <label htmlFor="components" className="text-sm font-medium uppercase">
                        Component Materials
                      </label>
                    </div>
                  </td>
                  <td>
                    <div className="px-2 py-1">
                      <span className="text-sm font-medium uppercase">Quantity</span>
                    </div>
                  </td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                {content?.items.map((component, index) => {
                  const partRestricted = isPartRestricted(getPart(component.part_id));
                  return (
                    <tr key={index} className="h-10 text-sm border-b items-center bg-white">
                      <td>
                        <PartBadge
                          teamId={currentTeamId}
                          part={
                            component.revision_id
                              ? getPartByRevisionId(component.revision_id)
                              : getPart(component.part_id)
                          }
                          partRestricted={partRestricted}
                        />
                      </td>
                      <td>
                        {!partRestricted && <div className="px-2 py-1">{component.amount}</div>}
                        {partRestricted && (
                          <div className="px-2 py-1">
                            <RestrictedInfo />
                          </div>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartList;
