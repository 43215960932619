import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useMixpanel } from '../contexts/MixpanelContext';
import useFieldRedlines from '../hooks/useFieldRedlines';
import SuggestedEditsModal from '../components/SuggestedEditsModal';
import ButtonActionIcon from './ButtonActionIcon';
import ProcedureFieldDiff from './ProcedureFieldDiff';
import revisionsUtil from '../lib/revisions';
import ProcedureDiffText from './ProcedureDiffText';
import { DiffField, RunHeaderFieldRedline, RunStepFieldRedline } from 'shared/lib/types/views/procedures';
import { Action } from './Blocks/BlockTypes';
import { useRunContext } from '../contexts/RunContext';
import { AxiosResponse } from 'axios';

/**
 * Component for rendering a step field when running or viewing a procedure.
 * Supports showing redline history changes.
 *
 * @param fieldName - Name of the field.
 * @param fieldValue - Value of the field.
 * @param redlines - Array of RedlineField objects.
 * @param actions - Array of Action objects to render next to the procedure field.
 * @param onLabelClick - Function that will be called when non editable step label is clicked.
 */
interface ProcedureFieldProps {
  fieldName: string;
  fieldValue: DiffField<string>;
  redlines: Array<RunHeaderFieldRedline | RunStepFieldRedline>;
  actions?: Array<Action>;
  onLabelClick: () => void;
  onAcceptPendingRedline?: (redlineIndex: number) => Promise<AxiosResponse | void>;
  isBold?: boolean;
}

const ProcedureField = ({
  fieldName,
  fieldValue,
  redlines,
  actions,
  onLabelClick,
  onAcceptPendingRedline,
  isBold,
}: ProcedureFieldProps) => {
  const { mixpanel } = useMixpanel();
  const [showsRedlineModal, setShowsRedlineModal] = useState(false);
  const { latestRedline, latestApprovedField, hasRedlines, allRedlineUserIds, hasPendingRedlines } = useFieldRedlines({
    fieldName,
    fieldValue,
    redlines,
  });

  const { isRun } = useRunContext();

  const acceptRedline = useCallback(() => {
    if (!onAcceptPendingRedline) {
      return Promise.resolve();
    }

    return onAcceptPendingRedline(latestRedline.redlineIndex);
  }, [latestRedline, onAcceptPendingRedline]);

  const acceptRedlineEnabled = useMemo(
    () => Boolean(hasPendingRedlines && onAcceptPendingRedline),
    [hasPendingRedlines, onAcceptPendingRedline]
  );

  const showRedlineModal = useCallback(() => {
    if (mixpanel) {
      mixpanel.track('Redline Viewed', { 'Field Name': fieldName });
    }
    setShowsRedlineModal(true);
  }, [fieldName, mixpanel]);

  const extendedActions = useMemo(() => {
    const showRedlineModalAction = {
      icon: 'strikethrough',
      iconType: latestRedline.run_only ? 'primary' : 'caution',
      onAction: showRedlineModal,
      ariaLabel: `Suggested Edits (${latestRedline.run_only ? 'Blueline' : 'Redline'})`,
      pendingAction: hasPendingRedlines,
      pendingDotMatchesIcon: true,
    } as Action;
    return hasRedlines ? [showRedlineModalAction, ...(actions ?? [])] : actions;
  }, [latestRedline, showRedlineModal, hasPendingRedlines, hasRedlines, actions]);

  return (
    <Fragment>
      {/* Redline changes modal  TODO: Remove this*/}
      {showsRedlineModal && (
        <div className="font-normal">
          <SuggestedEditsModal
            hideModal={() => setShowsRedlineModal(false)}
            allRedlineUserIds={allRedlineUserIds}
            latestTimestamp={revisionsUtil.createdAt(latestRedline)}
            acceptAction={acceptRedlineEnabled ? acceptRedline : undefined}
            redlinePending={hasPendingRedlines}
            isBlueline={latestRedline.run_only}
          >
            <ProcedureFieldDiff original={fieldValue as string} redlined={latestRedline[fieldName]} />
          </SuggestedEditsModal>
        </div>
      )}

      <button
        className={`max-w-full text-left ${isBold ? 'font-semibold' : ''}`}
        onClick={onLabelClick}
        disabled={typeof onLabelClick !== 'function'}
      >
        {/* Show latest redline in run and render action buttons if available. */}
        {isRun && typeof latestApprovedField === 'string' && (
          <Fragment>
            <span>{latestApprovedField}</span>
          </Fragment>
        )}

        {/* Show saved field for a procedure. */}
        {(!isRun || (isRun && typeof latestApprovedField === 'object')) && <ProcedureDiffText diffValue={fieldValue} />}
      </button>

      {/*TODO: Remove this*/}
      {isRun &&
        extendedActions &&
        extendedActions.map((action) => (
          <span key={action.ariaLabel} className="ml-1">
            <ButtonActionIcon
              icon={action.icon}
              iconType={action.iconType}
              onAction={() => {
                action.onAction();
              }}
              ariaLabel={action.ariaLabel}
              pendingAction={action.pendingAction}
              pendingDotMatchesIcon={action.pendingDotMatchesIcon}
            />
          </span>
        ))}
    </Fragment>
  );
};

export default ProcedureField;
