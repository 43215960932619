import * as d3Dag from 'd3-dag';
import { MarkerType } from 'reactflow';
import procedureUtil from './procedureUtil';
import validateUtil from './validateUtil';
import stepConditionals, {
  CONDITIONAL_TYPE,
  CONDITIONAL_STATE,
  CONDITIONAL_TERNARY_STATE,
} from 'shared/lib/stepConditionals';
import { STEP_STATE, getStepState } from 'shared/lib/runUtil';
import runUtil from './runUtil';
import dependencyUtil from './stepDependencyUtil';
import { groupBatchStepsInRun } from './batchSteps';

const NODE_WIDTH = 172;
const NODE_HEIGHT = 56;
const EDGE_LABEL_HEIGHT = 19.5; // By observation
const SECTION_NODE_WIDTH = 640; // max-w-screen-sm
const DEFAULT_MARGIN = 32;
const CONTAINER_MARGIN = 0;
const EDGE_TYPE = 'default';
const ARROW_MARKER = { markerEnd: { type: MarkerType.Arrow } };
const DEPENDENCY_MARKER = { markerEnd: 'circle' };

const stateBgColorCss = (conditional) => {
  if (
    conditional.source_type === CONDITIONAL_TYPE.STEP ||
    conditional.source_type === CONDITIONAL_TYPE.CONTENT_BINARY ||
    conditional.source_type === CONDITIONAL_TYPE.CONTENT_TERNARY
  ) {
    if (
      conditional.state === STEP_STATE.COMPLETED ||
      conditional.state === CONDITIONAL_STATE.PASS ||
      conditional.state === CONDITIONAL_TERNARY_STATE.PASS
    ) {
      return '#d8ece4'; // app-green-200
    } else if (
      conditional.state === STEP_STATE.FAILED ||
      conditional.state === CONDITIONAL_STATE.FAIL ||
      conditional.state === CONDITIONAL_TERNARY_STATE.FAIL_HIGH ||
      conditional.state === CONDITIONAL_TERNARY_STATE.FAIL_LOW ||
      conditional.state === CONDITIONAL_TERNARY_STATE.FAIL_NO_DATA
    ) {
      return 'rgb(254 202 202)'; // red-200
    }
  }
  return 'rgb(191 219 254)'; // bg-blue-200
};

const getLabelFromConditional = (conditional) => {
  return {
    label: `${conditional.state.toUpperCase()}`,
    labelStyle: {},
    labelBgPadding: [8, 4],
    labelBgBorderRadius: 10, // Height is 19.5 by observation
    labelBgStyle: { fill: `${stateBgColorCss(conditional)}` },
  };
};

const getCycleErrorNode = (sectionId, positionY) => {
  return {
    id: `section-${sectionId}-error`,
    type: 'section',
    data: { label: 'Section failed to render due to cycle logic' },
    position: {
      x: CONTAINER_MARGIN,
      y: positionY,
    },
    style: {
      width: SECTION_NODE_WIDTH,
      height: NODE_HEIGHT,
      color: 'rgb(211, 47, 47)', // red-700
      fontSize: 'smaller',
    },
  };
};

const getStepsForSection = (section) =>
  groupBatchStepsInRun(section.steps).map((stepOrBatch) => (Array.isArray(stepOrBatch) ? stepOrBatch[0] : stepOrBatch));

const flowchart = {
  _addEdge: (nodeIDs, nodeData, isDependent, markerEnd, label = {}) => {
    // Don't add a default edge if target node already has a dependency edge
    if (nodeData.nodeIsDependentMap.get(nodeIDs.targetId) === true && isDependent === false) {
      return;
    }

    const id = `edge-${nodeIDs.sourceId}-${nodeIDs.targetId}`;
    let style = { strokeWidth: '2' };
    if (!isDependent) {
      style = {
        ...style,
        strokeDasharray: '5',
      };
    }
    if (!nodeData.edgeIdSet.has(id)) {
      nodeData.edges.push({
        id,
        source: nodeIDs.sourceId,
        target: nodeIDs.targetId,
        data: { label },
        type: EDGE_TYPE,
        style,
        ...markerEnd,
        ...label,
      });
      nodeData.edgeIdSet.add(id);
      nodeData.nodeIsDependentMap.set(nodeIDs.targetId, isDependent);
    }
  },

  _addExternalSectionEdge: (nodeIDs, nodeData, isDependent, markerEnd, label = {}) => {
    // Node must have global unique id, but is shared within the source section.
    const externalNodeId = `${nodeIDs.sectionId}-external-node-${nodeIDs.targetId}`;
    if (!nodeData.nodeIsDependentMap.has(externalNodeId)) {
      nodeData.nodes.push({
        id: externalNodeId,
        data: { label: `To ${nodeIDs.targetStepKey}` },
        position: {
          x: 0,
          y: 0,
        },
        type: 'output',
        style: {
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
          borderRadius: '20px',
        },
      });
      nodeData.nodeIsDependentMap.set(externalNodeId, isDependent);
    }
    const updatedIds = {
      ...nodeIDs,
      targetId: externalNodeId,
    };
    flowchart._addEdge(updatedIds, nodeData, isDependent, markerEnd, label);
  },

  _addSectionDependency: (nodeIds, nodeData) => {
    const externalNodeId = `${nodeIds.sourceId}-external-node-${nodeIds.sectionId}`;
    if (!nodeData.nodeIsDependentMap.has(externalNodeId)) {
      nodeData.nodes.push({
        id: externalNodeId,
        data: {
          label: `From Section ${nodeIds.sourceSectionKey}`,
        },
        position: {
          x: 0,
          y: 0,
        },
        type: 'input',
        style: {
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
          borderRadius: '20px',
        },
      });
      nodeData.nodeIsDependentMap.set(externalNodeId, true);
    }
    const updatedIds = {
      ...nodeIds,
      sourceId: externalNodeId,
    };
    flowchart._addEdge(updatedIds, nodeData, true, DEPENDENCY_MARKER);
  },

  /**
   * Check if a conditional would add a cycle, and update the graph map otherwise.
   *
   * @param {Object} conditional - A step conditional.
   * @param {Map<String, Set<Object>>} sourceConditionalsMap - Map that represents
   *   the graph of step conditionals. Key is step id, and value is the list of
   *   conditionals that link to the given step.
   */
  _hasConditionalCycle: (conditional, sourceConditionalsMap) => {
    // Cycle validation
    const createsCycle = validateUtil.conditionalCreatesCycle(
      conditional.source_id,
      conditional.target_id,
      sourceConditionalsMap
    );
    if (!createsCycle) {
      // Update conditional source map
      if (!sourceConditionalsMap[conditional.target_id]) {
        sourceConditionalsMap[conditional.target_id] = new Set();
      }
      sourceConditionalsMap[conditional.target_id].add(conditional);
    }
    return createsCycle;
  },

  _hasDependencyCycle: (sourceId, targetId, stepMap) => {
    const visited = new Set();
    const targetIds = new Set(stepMap[targetId].targetDependencies);
    while (targetIds.size && !visited.has(sourceId)) {
      const stepId = targetIds.values().next().value;
      if (visited.has(stepId)) {
        return true;
      }
      visited.add(stepId);
      targetIds.delete(stepId);
      stepMap[stepId].targetDependencies.forEach((targetDependency) => {
        targetIds.add(targetDependency);
      });
    }
    return visited.has(sourceId);
  },

  _getNextStepId: (stepId, stepMap, stepList, repeatMap) => {
    for (let index = stepMap[stepId].index + 1; index < stepList.length; index += 1) {
      const nextStep = stepMap[stepList[index]];
      if (nextStep.sourceDependencies.size === 0 && !repeatMap[nextStep.step.id]) {
        return nextStep.step.id;
      }
    }
    return null;
  },

  _nodeColor: (step) => {
    if (!step) {
      return {};
    }
    const stepState = getStepState(step);
    if (stepState === STEP_STATE.COMPLETED) {
      return { backgroundColor: 'rgb(216 236 228)', borderColor: 'rgb(44 172 123)' };
    } else if (stepState === STEP_STATE.FAILED) {
      return { backgroundColor: 'rgb(254 202 202)', borderColor: 'rgb(248 113 113)' };
    } else if (stepState === STEP_STATE.SKIPPED) {
      return { backgroundColor: 'rgb(224 224 224)', borderColor: 'rgb(132 132 132)' };
    }
    return {};
  },

  /**
   * @typedef {Object} NodesEdges
   * @property {Array<object>} nodes - A list of react-flow nodes.
   * @property {Array<object>} edges - A list of react-flow edges.
   */

  /**
   * Generates an initial list of react-flow nodes and edges for a section.
   *
   * Nodes are un-positioned and can be used as a starting point for layout
   * algorithms.
   *
   * @param {Object} section - A procedure section object.
   * @param {object} stepMap - A map of all step ids to step objects
   *   for the procedure.
   * @param {Array<String>} stepList - list of all step ids in linear order
   * @returns {NodesEdges}
   */
  _getInitialSectionElements: (section, stepMap, stepList, repeatMap, sectionMap) => {
    const steps = getStepsForSection(section);
    const nodes = [];
    const edges = [];
    const nodeIsDependentMap = new Map(); // Key: Node ID, Value: Boolean, whether node is dependent
    const edgeIdSet = new Set();
    const sourceConditionalsMap = {};
    const nodeData = {
      nodeIsDependentMap,
      nodes,
      edges,
      edgeIdSet,
    };

    // Add conditional edges
    steps.forEach((step, stepIndex) => {
      const { stepKey } = stepMap[step.id];
      const repeatKey = runUtil.displayRepeatKey(steps, stepIndex);
      if (repeatMap[step.id]) {
        return;
      }
      nodes.push({
        id: step.id,
        data: { stepKey, label: `${step.name}`, isRepeat: step['repeat_of'] ? true : false, repeatKey },
        type: 'step',
        position: {
          x: 0,
          y: 0,
        },
        style: {
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          borderRadius: '0.375rem',
          ...flowchart._nodeColor(step),
        },
      });
      nodeIsDependentMap.set(step.id, false);

      if (step.conditionals) {
        const hideLabel =
          stepConditionals.areConditionalsRedundant(step.conditionals) ||
          stepConditionals.isSingleConditional(step.conditionals);
        step.conditionals.forEach((conditional) => {
          if (!conditional.target_id) {
            return;
          }
          const { step: targetStep, section: targetSection, stepKey: targetStepKey } = stepMap[conditional.target_id];
          const createsCycle = flowchart._hasConditionalCycle(conditional, sourceConditionalsMap);
          let label = {};
          if (!hideLabel) {
            label = getLabelFromConditional(conditional);
          }

          const nodeIds = {
            sourceId: step.id,
            sectionId: section.id,
            targetId: targetStep.id,
            targetStepKey,
          };

          if (targetSection.id !== section.id) {
            flowchart._addExternalSectionEdge(nodeIds, nodeData, true, ARROW_MARKER, label);
          } else if (createsCycle) {
            // For now, simply don't render this edge.
          } else {
            flowchart._addEdge(nodeIds, nodeData, true, ARROW_MARKER, label);
          }
        });
      }
    });

    // Add dependency edges
    steps.forEach((step) => {
      if (repeatMap[step.id]) {
        return;
      }

      const { targetDependencies, sourceDependencies } = stepMap[step.id];
      targetDependencies.forEach((targetId) => {
        const { step: targetStep, section: targetSection, stepKey: targetStepKey } = stepMap[targetId];
        const createsCycle = flowchart._hasDependencyCycle(step.id, targetStep.id, stepMap);

        const nodeIds = {
          sourceId: step.id,
          sectionId: section.id,
          targetId: targetStep.id,
          targetStepKey,
        };

        if (targetSection.id !== section.id) {
          flowchart._addExternalSectionEdge(nodeIds, nodeData, true, DEPENDENCY_MARKER);
        } else if (createsCycle) {
          // For now, simply don't render these edges.
        } else {
          flowchart._addEdge(nodeIds, nodeData, true, DEPENDENCY_MARKER);
        }
      });
      sourceDependencies.forEach((sourceId) => {
        if (!(sourceId in sectionMap)) {
          return;
        }
        const { section: sourceSection, sectionKey: sourceSectionKey } = sectionMap[sourceId];
        if (sourceSection.id !== section.id) {
          const nodeIds = {
            sourceId: sourceSection.id,
            sectionId: section.id,
            targetId: step.id,
            sourceSectionKey,
          };
          flowchart._addSectionDependency(nodeIds, nodeData);
        }
      });
    });

    // Add default linear edges
    steps.forEach((step) => {
      if (repeatMap[step.id]) {
        return;
      }

      const { targetDependencies } = stepMap[step.id];
      const nextStepId = flowchart._getNextStepId(step.id, stepMap, stepList, repeatMap);
      if (nextStepId && !(step.conditionals && step.conditionals.length) && !targetDependencies.size) {
        const { step: targetStep, section: targetSection, stepKey: targetStepKey } = stepMap[nextStepId];

        // while not a true step dependency, we add it here to avoid causing cycles by adding default edges
        stepMap[step.id].targetDependencies.add(nextStepId);
        const createsCycle = flowchart._hasDependencyCycle(step.id, targetStep.id, stepMap);

        const nodeIds = {
          sourceId: step.id,
          sectionId: section.id,
          targetId: targetStep.id,
          targetStepKey,
        };

        if (targetSection.id !== section.id) {
          flowchart._addExternalSectionEdge(nodeIds, nodeData, false, ARROW_MARKER);
        } else if (createsCycle) {
          stepMap[step.id].targetDependencies.delete(nextStepId);
        } else {
          flowchart._addEdge(nodeIds, nodeData, false, ARROW_MARKER);
        }
      }
    });

    return {
      nodes,
      edges,
    };
  },

  /**
   * Generates node data for d3-dag from a list of react-flow nodes and edges.
   *
   * Returned edge data could include single edges (edges that point to themselves).
   *
   * @param {Array<Object>} nodes - A list of react-flow nodes.
   * @param {Array<Object>} edges - A list of react-flow edges.
   * @returns {Array<Object>} data - A list d3-dag edge connections.
   */
  _getConnectedData: (nodes, edges) => {
    const data = [];

    // Create dag from edge list
    nodes.forEach((node) => {
      data.push([node.id, node.id]);
    });

    edges.forEach((edge) => {
      data.push([edge.source, edge.target]);
    });

    return data;
  },

  /**
   * Layout nodes with d3-dag algorithms.
   *
   * Mutates give node positions.
   *
   * @param {Array<Object>} nodes - A list of react-flow nodes.
   * @param {Array<Object>} edges - A list of react-flow edges.
   * @returns {{width: Number, height: Number}} Width and height of resulting chart.
   */
  _layoutElements: (nodes, edges) => {
    const data = flowchart._getConnectedData(nodes, edges);

    const nodeWidth = NODE_WIDTH + DEFAULT_MARGIN;
    /**
     * Add enough room for an edge (with margin above and below). This assumes
     * all nodes will have edges, which isn't true for the nodes at the very
     * bottom of the chart, but it's an OK start.
     */
    const nodeHeight = NODE_HEIGHT + DEFAULT_MARGIN + EDGE_LABEL_HEIGHT + DEFAULT_MARGIN;

    // Layout with d3-dag and sugiyama.
    const layout = d3Dag
      .sugiyama()
      .decross(d3Dag.decrossOpt())
      .nodeSize((node) => (node === undefined ? [0, 0] : [nodeWidth, nodeHeight]));
    const create = d3Dag.dagConnect().single(true);
    const dag = create(data);

    // Assign the x and y position for the nodes.
    const { width, height } = layout(dag);

    // Update react-flow nodes with dag node positions.
    const dagNodes = dag.descendants();
    const nodeMap = Object.assign({}, ...nodes.map((node) => ({ [node.id]: node })));
    dagNodes.forEach((dagNode) => {
      const nodeId = dagNode.data.id;
      const node = nodeMap[nodeId];
      node.position = {
        x: dagNode.x,
        y: dagNode.y,
      };
    });

    // Return total size of chart.
    return {
      width,
      height,
    };
  },

  /**
   * Normalize a d3-dag layout to start at an [x, y] position of [0, 0] or the
   * given margins.
   *
   * Mutates given node positions.
   *
   * @param {Array} nodes - A list of react-flow nodes.
   * @param {Object} margins - An object of type {x: number, y: number} to
   *   use as starting point for node positioning.
   */
  _normalizeWithMargins: (nodes, margins) => {
    // Find min [x, y]
    let minX = -1,
      minY = -1;
    nodes.forEach((node) => {
      minX = minX === -1 || node.position.x < minX ? node.position.x : minX;
      minY = minY === -1 || node.position.y < minY ? node.position.y : minY;
    });

    // Normalize origin to [0, 0] and add margins.
    nodes.forEach((node) => {
      node.position.x -= minX;
      node.position.y -= minY;
      if (margins && margins.x) {
        node.position.x += margins.x;
      }
      if (margins && margins.y) {
        node.position.y += margins.y;
      }
    });
  },

  /**
   * Generates a list of nodes and edges for react-flow.
   *
   * @param {Object} procedure - A procedure doc.
   * @param {Object} config - Workspace config doc for generating section and step keys.
   * @returns {NodesEdges}
   */
  getPositionedElements: (procedure, config) => {
    const nodes = [];
    const edges = [];
    const sectionMap = {};
    const stepMap = {};
    const stepList = [];
    const repeatMap = {};

    // Build step map.
    let index = 0;
    let sectionIndex = -1;
    procedure.sections.forEach((section) => {
      if (!section.repeat_of) {
        sectionIndex++;
      } else {
        repeatMap[section.repeat_of] = section.id;
      }

      const sectionKey = procedureUtil.displaySectionKey(sectionIndex, config && config.display_sections_as);
      sectionMap[section.id] = {
        section,
        sectionKey,
        targetDependencies: new Set(),
      };

      let stepIndex = -1;
      const steps = getStepsForSection(section);
      steps.forEach((step) => {
        if (!('repeat_of' in step) || !step.repeat_of) {
          stepIndex += 1;
        } else {
          repeatMap[step.repeat_of] = step.id;
        }
        const stepKey = procedureUtil.displaySectionStepKey(
          sectionIndex,
          stepIndex,
          config && config.display_sections_as
        );
        stepMap[step.id] = {
          step,
          section,
          stepKey,
          index,
          sourceDependencies: new Set(), // steps this step is dependent upon
          targetDependencies: new Set(), // steps that depend on this step
        };
        stepList[index] = step.id;
        index += 1;
      });
    });

    // Second pass is required for adding dependencies to the map
    procedure.sections.forEach((section) => {
      const steps = getStepsForSection(section);
      steps.forEach((step) => {
        const dependentIds = dependencyUtil.getAllDependentIds(step.dependencies);
        dependentIds.forEach((dependentId) => {
          if (dependentId in stepMap) {
            stepMap[dependentId].targetDependencies.add(step.id);
          }
          if (dependentId in sectionMap) {
            sectionMap[dependentId].targetDependencies.add(step.id);
          }
          stepMap[step.id].sourceDependencies.add(dependentId);
        });

        if (step.conditionals) {
          step.conditionals.forEach((conditional) => {
            if (!conditional.target_id) {
              return;
            }
            stepMap[step.id].targetDependencies.add(conditional.target_id);
            stepMap[conditional.target_id].sourceDependencies.add(step.id);
          });
        }
      });
    });

    // Layout each section.
    let sectionStartY = DEFAULT_MARGIN;
    sectionIndex = -1;
    procedure.sections.forEach((section, originalIndex) => {
      if (!section.repeat_of) {
        sectionIndex++;
      }
      let { nodes: _nodes, edges: _edges } = flowchart._getInitialSectionElements(
        section,
        stepMap,
        stepList,
        repeatMap,
        sectionMap
      );
      let height = 0;
      try {
        ({ height } = flowchart._layoutElements(_nodes, _edges));
      } catch {
        // Clear all data and add a message node to indicate cycle
        height = NODE_HEIGHT;
        _nodes = [getCycleErrorNode(section.id, sectionStartY)];
        _edges = [];
      }

      // Add section header node
      const sectionKey = procedureUtil.displaySectionKey(sectionIndex, config && config.display_sections_as);
      const repeatKey = runUtil.displayRepeatKey(procedure.sections, originalIndex);
      nodes.push({
        id: `section-${section.id}`,
        type: 'section',
        data: {
          label: `Section ${sectionKey}: ${section.name || ''}`,
          isRepeat: section.repeat_of ? true : false,
          repeatKey,
        },
        position: {
          x: CONTAINER_MARGIN,
          y: sectionStartY,
        },
        style: {
          width: SECTION_NODE_WIDTH,
          height: NODE_HEIGHT,
        },
      });
      sectionStartY += NODE_HEIGHT + DEFAULT_MARGIN;
      const margins = {
        x: CONTAINER_MARGIN,
        y: sectionStartY,
      };
      flowchart._normalizeWithMargins(_nodes, margins);
      nodes.push(..._nodes);
      edges.push(..._edges);

      sectionStartY += height + DEFAULT_MARGIN;
    });

    return {
      nodes,
      edges,
    };
  },
};

export default flowchart;
