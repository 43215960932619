export enum ItemStatus {
  InInventory = 'In Inventory',
  Unavailable = 'Unavailable',
  Scrap = 'Scrap',
}

// ---
// Inventory Activity
// ---

export enum ItemAction {
  Created = 'Created',
  Updated = 'Updated',
  Received = 'Received',
  CheckedOut = 'Checked Out',
  CheckedIn = 'Checked In',
  UsageRecorded = 'Usage Recorded',
  DetailUpdated = 'Detail Updated',
  IssueLinked = 'Issue Linked',
}

export enum ItemActorType {
  Api = 'api',
  User = 'user',
  Run = 'run',
}

export type ItemActivity = {
  id: number;
  timestamp: string;
  item_id: string;
  action: ItemAction;
  actor_type: ItemActorType;
  actor_id?: string;
  new_value: object;
  old_value?: object;
};

export type InsertItemActivity = Omit<ItemActivity, 'id' | 'timestamp'>;

export type ListItemActivities = {
  has_older: boolean;
  activities: ItemActivity[];
};
