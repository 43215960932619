import { API_URL } from '../config';
import superlogin from './superlogin';
import { MAX_RETRIES } from './runs';
import procedureUtil from '../lib/procedureUtil';
import { EventEmitter2 } from 'eventemitter2';
import { onSelectorChanged } from './observers';
import RevisionsService from './revisions';
import { SuperLoginClient } from 'superlogin-client';
import { AxiosResponse } from 'axios';
import {
  Procedure,
  ProcedureMetadata,
  ReviewComment,
} from 'shared/lib/types/views/procedures';
import { Redline } from '../lib/views/redlines';
import { DocChange } from '../lib/couchdbUtil';
import { REDLINE_STATE } from '../lib/redlineUtil';
import { ProtoBlockList } from '../components/Blocks/ProtoBlockTypes';
import _ from 'lodash';
import { ReviewerGroup } from 'shared/lib/types/couch/procedures';
import { Tag } from 'shared/lib/types/couch/settings';
import { ReviewType } from 'shared/lib/types/settings';
import { ReviewSettings } from 'shared/lib/reviewUtil';
import { getPendingProcedureIndex } from 'shared/lib/procedureUtil';

export class ProceduresError extends Error {
  status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

type BulkGetOptions = {
  id: string;
  rev?: string;
}[];

type BulkGetErrors = {
  id: string;
  rev: string;
  error: string;
  reason: string;
};

export type BulkProceduresResult = {
  procedures: Array<Procedure>;
  errors: Array<BulkGetErrors>;
};

export type Callback = () => void;

export type CancelFunc = {
  cancel: () => void;
};

type UpdateFunc = (procedure: Procedure) => Promise<Procedure>;

export type ReviewParameters = {
  reviewType?: ReviewType;
  reviewerGroups?: Array<ReviewerGroup>;
  /** @deprecated */
  reviewTypeId?: string;
};

class ProcedureService {
  static instances = {};

  static getInstance = (
    teamId: string,
    revisionService: RevisionsService,
    superLogin: SuperLoginClient
  ): ProcedureService => {
    if (!ProcedureService.instances[teamId]) {
      ProcedureService.instances[teamId] = new ProcedureService(
        teamId,
        revisionService,
        superLogin
      );
    }

    return ProcedureService.instances[teamId];
  };

  static removeInstance = (teamId: string): void => {
    delete ProcedureService.instances[teamId];
  };

  teamId: string;
  name: string;
  revisionsService: RevisionsService;
  restUrl: string;
  emitter: EventEmitter2;
  observer: { cancel: () => void } | null;
  superlogin: SuperLoginClient;

  constructor(
    teamId: string,
    revisionsService: RevisionsService,
    superlogin: SuperLoginClient
  ) {
    this.teamId = teamId;
    this.name = `procedures_${teamId}`;
    this.revisionsService = revisionsService;
    this.restUrl = `${API_URL}/teams/${this.teamId}/procedures`;
    this.emitter = new EventEmitter2({ wildcard: true });
    this.superlogin = superlogin;
  }

  startChangeFeed(): void {
    if (this.observer) {
      return;
    }
    const notifyListeners = (callbackValue) => {
      for (const change of callbackValue.results) {
        this.emitter.emit(`procedure.${change.id}`, callbackValue);
      }
    };
    this.observer = onSelectorChanged(
      this.name,
      null,
      null,
      notifyListeners,
      () => {
        /* no-op */
      }
    );
  }

  close(): void {
    if (this.observer) {
      this.observer.cancel();
      this.observer = null;
    }

    ProcedureService.removeInstance(this.teamId);
  }

  // Returns metadata for all procedures
  async getAllProceduresMetadata(): Promise<Array<ProcedureMetadata>> {
    const url = `${this.restUrl}/summary`;
    try {
      const resp = await superlogin.getHttp().get(url);
      return resp.data.data;
    } catch (err) {
      const raiseError = new ProceduresError(err.message, err.response.status);
      return Promise.reject(raiseError);
    }
  }

  /**
   * Gets procedure metadata for specific procedures.
   *
   * If no documents are specified, the empty set is returned.
   *
   * @param docs - The procedures to query for.
   * @returns The requested procedure metadata objects.
   */
  async getProceduresMetadata(
    docs: Array<DocChange>
  ): Promise<Array<ProcedureMetadata>> {
    // Early return in case no work to do.
    if (!docs || !docs.length) {
      return Promise.resolve([]);
    }

    const ids = docs.map((doc) => doc._id);

    const url = `${this.restUrl}/bulk-get/summary`;
    try {
      const resp = await superlogin.getHttp().post(url, { ids });
      return resp.data.data;
    } catch (err) {
      const raiseError = new ProceduresError(err.message, err.response.status);
      return Promise.reject(raiseError);
    }
  }

  /**
   * Get a batch of procedure documents.
   *
   * @param docs - A list of document ids and revisions to get.
   * @returns results - The resulting procedure documents or errors.
   */
  async bulkGetProcedures(docs: BulkGetOptions): Promise<BulkProceduresResult> {
    const url = `${this.restUrl}/bulk-get`;
    try {
      const resp = await superlogin.getHttp().post(url, { docs });
      return {
        procedures: resp.data.procedures,
        errors: resp.data.errors,
      };
    } catch (err) {
      const raiseError = new ProceduresError(err.message, err.response.status);
      return Promise.reject(raiseError);
    }
  }

  onProceduresChanged(callback: Callback): CancelFunc {
    this.startChangeFeed();
    if (callback) {
      this.emitter.on('procedure.*', callback);
      return {
        cancel: () => {
          this.emitter.off('procedure.*', callback);
        },
      };
    } else {
      return {
        cancel: () => {
          /* no-op */
        },
      };
    }
  }

  onProcedureChanged(id: string, callback: Callback): CancelFunc {
    this.startChangeFeed();
    if (callback) {
      this.emitter.on(`procedure.${id}`, callback);
      return {
        cancel: () => {
          this.emitter.off(`procedure.${id}`, callback);
        },
      };
    } else {
      return {
        cancel: () => {
          /* no-op */
        },
      };
    }
  }

  async getProcedure(id: string): Promise<Procedure> {
    const url = `${this.restUrl}/${id}`;
    try {
      const resp = await superlogin.getHttp().get(url);
      return resp.data;
    } catch (err) {
      const raiseError = new ProceduresError(err.message, err.response.status);
      return Promise.reject(raiseError);
    }
  }

  /**
   * Saves procedure to database as a draft.
   *
   * The input procedure can be a draft or non-draft. This function creates a copy of the
   * procedure and overwrites or adds the relevant fields so that the resulting saved procedure
   * is a draft.
   *
   * Returns a promise that resolves to the draft document saved to the database. The returned
   * procedure contains the _id and _rev fields used to put the document.
   */
  saveDraft(procedure: Procedure): Promise<Procedure> {
    const draft = _.cloneDeep(procedure);
    procedureUtil.updateDocAsDraft(draft);
    return this.updateProcedure(draft);
  }

  /**
   * First saves a draft, then saves the procedure to database with state "in_review".
   *
   * The input procedure can be a draft, in review, or release document. This function
   * overwrites or adds the relevant fields so that the resulting saved procedure
   * is in review.
   *
   * Returns a promise that resolves to the "in review" document saved to the database.
   * The returned procedure contains the _id and _rev fields used to put the document.
   */
  async saveReview(
    procedure: Procedure,
    settings: ReviewParameters
  ): Promise<Procedure> {
    const draft = await this.saveDraft(procedure);

    const url = `${this.restUrl}/${draft._id}/review`;
    const response = await superlogin.getHttp().post(url, {
      review_type_id: settings.reviewTypeId,
      review_type: settings.reviewType,
      reviewer_groups: settings.reviewerGroups,
    });

    return response.data;
  }

  async getReviewSettings(procedureId: string): Promise<ReviewSettings> {
    const url = `${this.restUrl}/${procedureId}/review/settings`;
    const response = await superlogin.getHttp().get(url);

    return response.data;
  }

  /**
   * Releases a procedure.
   *
   * The input procedure id must be the id of a procedure, not the id of a draft procedure
   */
  async release(procedureId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/actions/release`;
    return superlogin.getHttp().post(url);
  }

  async updateProcedure(procedure: Procedure): Promise<Procedure> {
    // Only need to submit doc to backend and the backend takes care of saving revisions
    await superlogin
      .getHttp()
      .put(`${this.restUrl}/${procedure._id}`, { data: procedure });
    return Promise.resolve(procedure);
  }

  /**
   * Calls provided updateFunc with procedure argument and then updates the resulting procedure
   * in the database. On document conflict, fetches the latest procedure with the same id and tries
   * again.
   *
   * Note: TODO - Abstract this out to a utils library and use in procedures.js and runs.js
   *
   * @param updateFunc - Accepts one argument (procedure) and returns a Promise
   *                     that resolves to an updated procedure.
   * @param procedure - The procedure doc to be passed on to updateFunc
   * @param attempts - Number of retry attempts (optional).
   */
  async _updateProcedureRetrying(
    updateFunc: UpdateFunc,
    procedure: Procedure,
    attempts = MAX_RETRIES
  ): Promise<Procedure> {
    if (attempts === 0) {
      return Promise.reject(new Error('Max retries exceeded.'));
    }

    try {
      const updatedProcedure = await updateFunc(procedure);
      return await this.updateProcedure(updatedProcedure);
    } catch (error) {
      if (error.response?.status === 409) {
        const procedureFromDb = await this.getProcedure(procedure._id);
        return this._updateProcedureRetrying(
          updateFunc,
          procedureFromDb,
          attempts - 1
        );
      }
      throw error;
    }
  }

  /**
   * Checks if procedure is already archived before updating the archived property to true.
   * TODO: move static method out of class
   */
  static async _tryArchiveProcedure(procedure: Procedure): Promise<Procedure> {
    // If procedure is already archived, drop this request.
    if (procedure.archived) {
      return Promise.reject(new Error('Procedure already archived'));
    }

    const updatedProcedure = _.cloneDeep(procedure);
    updatedProcedure.archived = true;

    return Promise.resolve(updatedProcedure);
  }

  /**
   * Deletes draft and makes a copy of draft to Revisions DB appended with deleted, deletedAt, deletedUserId
   */
  async deleteDraft(procedureId: string): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/draft`;
    return superlogin.getHttp().delete(url);
  }

  /**
   * Archives procedure and retries on 409 conflict. Also archives pending procedures associated with the master procedure.
   * @returns array of updated docs
   */
  async archiveProcedure(procedure: Procedure): Promise<Array<Procedure>> {
    const archiveFunc = (updated: Procedure) =>
      ProcedureService._tryArchiveProcedure(updated);

    const updatedDocs: Array<Procedure> = [];
    const updatedProcedure = await this._updateProcedureRetrying(
      archiveFunc,
      procedure
    );
    updatedDocs.push(updatedProcedure);

    try {
      const pendingIndex = getPendingProcedureIndex(procedure._id);
      const pending = await this.getProcedure(pendingIndex);
      const updatedPending = await this._updateProcedureRetrying(
        archiveFunc,
        pending
      );
      updatedDocs.push(updatedPending);
    } catch (error) {
      if (error?.status !== 404) {
        throw error;
      }
    }

    return updatedDocs;
  }

  /**
   * Confirm that a procedure is archived before updating archived property to false.
   * TODO: move static method out of class
   */
  static async _tryUnarchiveProcedure(
    procedure: Procedure
  ): Promise<Procedure> {
    // If procedure is not currently archived, drop this request.
    if (!procedure.archived) {
      return Promise.reject(new Error('Procedure is not archived'));
    }

    const updatedProcedure = _.cloneDeep(procedure);
    updatedProcedure.archived = false;

    return Promise.resolve(updatedProcedure);
  }

  /**
   * Unarchives procedure and retries on 409 conflict. Also unarchives pending procedures associated with the master procedure.
   * @returns array of updated docs
   */
  async unarchiveProcedure(procedure: Procedure): Promise<Array<Procedure>> {
    const unarchiveFunc = (updated: Procedure) =>
      ProcedureService._tryUnarchiveProcedure(updated);

    const updatedDocs: Array<Procedure> = [];
    const fullProcedure: Procedure = await this.getProcedure(procedure._id);
    const updatedProcedure = await this._updateProcedureRetrying(
      unarchiveFunc,
      fullProcedure
    );
    updatedDocs.push(updatedProcedure);

    try {
      const pendingIndex = getPendingProcedureIndex(procedure._id);
      const pending: Procedure = await this.getProcedure(pendingIndex);
      updatedDocs.push(
        await this._updateProcedureRetrying(unarchiveFunc, pending)
      );
    } catch (error) {
      if (error?.status !== 404) {
        throw error;
      }
    }

    return updatedDocs;
  }

  // Save updated reviewer groups array to procedure.
  async saveReviewerGroups(
    procedure: Procedure,
    reviewerGroups: Array<ReviewerGroup>
  ): Promise<Procedure> {
    const updateFunc = (updated: Procedure) => {
      updated.reviewer_groups = reviewerGroups;
      return Promise.resolve(updated);
    };
    const procedureWithReviewerGroups = await updateFunc(
      _.cloneDeep(procedure)
    );
    return this._updateProcedureRetrying(
      updateFunc,
      procedureWithReviewerGroups
    );
  }

  // Marks an approval for the procedure version by teamId, reviewerGroupId, reviewerId and optional operatorRole.
  async approve(
    procedureId: string,
    procedureRevNum: string,
    reviewerGroupId: string,
    reviewerId: string,
    operatorRole: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/approvals`;
    const body = {
      procedure_rev_num: procedureRevNum,
      reviewer_group_id: reviewerGroupId,
      reviewer_id: reviewerId,
      operator_role: operatorRole,
    };

    return superlogin.getHttp().post(url, body);
  }

  // Revokes an approval for the procedure
  async revokeApproval({
    procedureId,
    procedureRevNum,
    reviewerGroupId,
    reviewerId,
  }: {
    procedureId: string;
    procedureRevNum: number;
    reviewerGroupId: string;
    reviewerId: string;
  }): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/approvals/revoke`;
    const body = {
      procedure_rev_num: procedureRevNum,
      reviewer_group_id: reviewerGroupId,
      reviewer_id: reviewerId,
    };

    return superlogin.getHttp().post(url, body);
  }

  // TODO: Add tests
  async importProcedure(file: File): Promise<AxiosResponse> {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);
    const url = `${API_URL}/teams/${this.teamId}/import/procedure/upload`;
    return superlogin.getHttp().post(url, bodyFormData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  // TODO: Add tests
  async convertToDraft(
    importUUID: string,
    protoBlockList: ProtoBlockList,
    projectId?: string
  ): Promise<AxiosResponse> {
    const url = `${API_URL}/teams/${this.teamId}/import/procedure/generate`;
    return superlogin.getHttp().post(url, {
      importUUID,
      protoblocks: protoBlockList,
      projectId,
    });
  }

  /**
   * Adds or updates release note for a procedure with specified id.
   *
   * @param procedureId - id of the procedure where the comment is added.
   * @param text - text of the updated release note.
   */
  async updateReleaseNote(
    procedureId: string,
    text: object
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/release-note`;
    return superlogin.getHttp().post(url, { text });
  }

  /**
   * Adds comment to a procedure with specified id.
   *
   * @param procedureId - id of the procedure where the comment is added.
   * @param comment - comment object to be added to procedure.
   * @param comment.id - unique id of the comment.
   * @param comment.parent_id - if child comment then id of corresponding parent.
   * @param comment.type - comment type, e.g. "suggested_comment".
   * @param comment.reference_id - of the procedure content the comment references.
   *                               Could be a step or section id.
   * @param comment.text - text content of comment.
   * @param comment.mention_list - username and email pairs of users mentioned in comment.
   * @param procedureRev - revision of the procedure for the added comment.
   */
  async addComment(
    procedureId: string,
    comment: ReviewComment,
    procedureRev: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/comments`;
    return superlogin
      .getHttp()
      .post(url, { ...comment, procedure_rev: procedureRev });
  }

  /**
   * Resolves a comment in the provided procedure by commentId.
   *
   * @param procedureId - Unique identifier for procedure
   * @param commentId - Unique identifier for comment
   * @param procedureRev - Unique identifier for procedure revision
   */
  resolveComment(
    procedureId: string,
    commentId: string,
    procedureRev: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/comments/${commentId}/resolve`;

    return superlogin.getHttp().post(url, { procedure_rev: procedureRev });
  }

  /**
   * Unresolves a comment in the provided procedure by commentId.
   *
   * @param procedureId - Unique identifier for procedure
   * @param commentId - Unique identifier for comment
   * @param procedureRev - Unique identifier for procedure revision
   */
  async unresolveComment(
    procedureId: string,
    commentId: string,
    procedureRev: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/comments/${commentId}/unresolve`;

    return superlogin.getHttp().post(url, { procedure_rev: procedureRev });
  }

  // Getter method for teamId.
  getTeamId(): string {
    return this.teamId;
  }

  // Sends notification that the procedure with given id is ready to review to appropriate recipients.
  async sendNotificationsToReviewers(
    procedureId: string
  ): Promise<AxiosResponse> {
    const url = `${this.restUrl}/${procedureId}/notifications`;
    const body = {
      type: 'review',
    };

    return superlogin.getHttp().post(url, body);
  }

  async saveTags(procedureId: string, tags: Array<Tag>): Promise<Procedure> {
    const procedure = await this.getProcedure(procedureId);
    procedure.tags = tags;
    await superlogin
      .getHttp()
      .put(`${this.restUrl}/${procedureId}`, { data: procedure });
    return Promise.resolve(procedure);
  }

  /**
   * Get docs of unresolved redlines for a procedure.
   *
   * @param procedureId - The id of a procedure, not the id of a draft procedure
   * @returns Promise for an array of redline docs
   */
  async getUnresolvedRedlineDocs(procedureId: string): Promise<Array<Redline>> {
    const url = `${API_URL}/teams/${this.teamId}/procedures/${procedureId}/redlines`;
    const response = await superlogin
      .getHttp()
      .get(url, { params: { 'redline-state': REDLINE_STATE.UNRESOLVED } });
    return response.data;
  }

  async getRedlinesMetadata(): Promise<Array<Redline>> {
    const url = `${API_URL}/teams/${this.teamId}/procedures/metadata/redlines`;
    const response = await superlogin.getHttp().get(url, { params: {} });
    return response.data;
  }

  async getUniqueProcedureId(projectCode?: string): Promise<string> {
    const url = `${API_URL}/teams/${this.teamId}/procedures/generateUniqueId`;
    const response = await superlogin
      .getHttp()
      .post(url, { project_code: projectCode });
    return response.data.id;
  }
}

export default ProcedureService;
