import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReferenceSelectMenu from './Expression/ReferenceSelectMenu';
import { useSettings } from '../contexts/SettingsContext';
import { useProcedureContext } from '../contexts/ProcedureContext';
import expression, { ReferenceOption } from '../lib/expression';
import { FormikHelpers, FormikValues } from 'formik';
import useMenu from '../hooks/useMenu';
import { InputTextarea } from 'primereact/inputtextarea';
import { isEmpty, isNumber } from 'lodash';

interface ReferenceTextAreaProps {
  field;
  placeholder: string;
  path: string;
  setFieldValue?: FormikHelpers<FormikValues>['setFieldValue'];
  setValues?: FormikHelpers<FormikValues>['setValues'];
}

const ReferenceTextArea = ({ field, placeholder, path, setFieldValue, setValues }: ReferenceTextAreaProps) => {
  const [localBlock, setLocalBlock] = useState(field.value);
  const { config } = useSettings();
  const { isMenuVisible, setIsMenuVisible, displayMenu } = useMenu();
  const { validReferenceBlocks, stepOptions } = useProcedureContext();
  const displaySectionAs = (config && config.display_sections_as) || 'letters';

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const selectionStartRef = useRef<number | null>(null);
  const selectionEndRef = useRef<number | null>(null);

  const referenceOptions = validReferenceBlocks({ displaySectionAs });

  const legacyTextActive = useMemo(() => {
    return localBlock.text && (!localBlock.tokens || localBlock.tokens?.length === 0);
  }, [localBlock]);

  // If a redline is accepted and changes the field value, update the local state
  useEffect(() => {
    setLocalBlock(field.value);
  }, [field.value]);

  useEffect(() => {
    if (legacyTextActive) {
      const newEntry = [
        {
          type: 'text',
          value: localBlock.text,
        },
      ];
      setLocalBlock((_prev) => {
        return { ..._prev, text: localBlock.text, tokens: newEntry };
      });
    }
  }, [field.value, legacyTextActive, localBlock, localBlock.text]);

  const handleAddReferenceClick = (event) => {
    if (inputRef && inputRef.current) {
      selectionStartRef.current = inputRef.current.selectionStart;
      selectionEndRef.current = inputRef.current.selectionEnd;
    }
    event.stopPropagation();
    displayMenu();
  };

  const closeMenuHandler = useCallback(() => {
    setIsMenuVisible(false);
  }, [setIsMenuVisible]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (isMenuVisible) {
        event.preventDefault();
      }
    },
    [isMenuVisible]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const updateField = useCallback(
    (setField = true) => {
      if (inputRef && inputRef.current) {
        const updatedTokens = expression.textToTokens(inputRef.current.value, referenceOptions);
        setLocalBlock((_prev) => ({
          ..._prev,
          tokens: updatedTokens,
          text: inputRef.current?.value ?? '',
        }));
        if (setField) {
          const updatedFieldValue = {
            ...localBlock,
            tokens: updatedTokens,
            text: inputRef.current.value,
          };
          if (path) {
            setFieldValue && setFieldValue(`${path}`, updatedFieldValue);
          } else {
            setValues && setValues(updatedFieldValue);
          }
        }
      }
    },
    [referenceOptions, localBlock, path, setFieldValue, setValues]
  );

  const onSelectReference = useCallback(
    (reference: ReferenceOption) => {
      if (inputRef && inputRef.current) {
        const textValue = inputRef.current.value;
        const referenceName = expression.getNamespacedReference(reference.name, reference.id, referenceOptions);

        if (selectionStartRef.current !== null && selectionEndRef.current !== null) {
          const start = selectionStartRef.current;
          const end = selectionEndRef.current;
          inputRef.current.value = `${textValue.slice(0, start)}${referenceName}${textValue.slice(end)}`;
          inputRef.current.setSelectionRange(start + referenceName.length, start + referenceName.length);
        }

        inputRef.current.focus();
        updateField(false);
      }

      closeMenuHandler();
    },
    [closeMenuHandler, referenceOptions, updateField]
  );

  useEffect(() => {
    if (localBlock.tokens && typeof localBlock.tokens === 'object') {
      let referencesHaveChanged = false;
      const updatedTokens = [...localBlock.tokens];
      updatedTokens.forEach((token) => {
        if (token.type === 'reference' && token.reference_id) {
          let match;

          if (isNumber(token.field_index)) {
            match = referenceOptions.find(
              (item) => item.id === token.reference_id && item.origin?.fieldIndex === token.field_index
            );
          } else {
            match = referenceOptions.find((item) => item.id === token.reference_id);
          }
          if (!match) {
            token.value = `{${token.value}}`;
            token.type = 'text';
            token.reference_id = undefined;
            referencesHaveChanged = true;
          } else {
            if (token.value !== match.name) {
              token.value = match.name;
              referencesHaveChanged = true;
            }
          }
        }
      });

      if (referencesHaveChanged) {
        setLocalBlock((_prev) => ({ ..._prev, tokens: updatedTokens }));
      }
      if (inputRef && inputRef.current) {
        const newValue = expression.tokensToText(updatedTokens, referenceOptions);
        if (inputRef.current.value !== newValue) {
          inputRef.current.value = newValue;
        }
      }
    }
  }, [referenceOptions, localBlock]);

  const handleBlur = () => {
    if (!isMenuVisible) {
      updateField(true);
    }
  };

  return (
    <div className="relative flex flex-row bg-white border border-gray-400 focus-within:ring-1 focus-within:ring-blue-600 focus-within:border-blue-600 rounded p-0.5 w-full items-center">
      <InputTextarea
        autoResize={true}
        aria-label="TextBlock"
        ref={inputRef}
        className="outline-none w-full text-sm h-6"
        placeholder={placeholder}
        onChange={() => {
          updateField(false);
        }}
        onBlur={handleBlur}
        defaultValue={legacyTextActive ? localBlock.text : expression.tokensToText(localBlock.tokens, referenceOptions)}
        style={{ boxShadow: 'none', border: 'none' }}
      />
      {((stepOptions && isEmpty(stepOptions)) || stepOptions?.displayAddReferences) && (
        <>
          <button
            type="button"
            className="bg-blue-500 text-white text-lg font-semibold tracking-wide uppercase whitespace-nowrap px-2 py-0.5 rounded"
            onClick={handleAddReferenceClick}
            title="Insert reference"
          >
            +
          </button>
          {isMenuVisible && (
            <ReferenceSelectMenu onSelect={onSelectReference} close={closeMenuHandler} menuOptions={referenceOptions} />
          )}
        </>
      )}
    </div>
  );
};

export default ReferenceTextArea;
