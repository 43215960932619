import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkSlash, faExternalLinkAlt as fasExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import DateTimeDisplay from './DateTimeDisplay';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import TooltipOverlay from './TooltipOverlay';
import Button, { BUTTON_TYPES } from './Button';

interface PromptRedlineProps {
  allRedlineUserIds: Array<string>;
  timestamp: string;
  onReject?: () => void;
  error?: string;
  onAccept?: () => void;
  children: React.ReactNode;
  isLocationAmbiguous?: boolean;
  isDisconnected?: boolean;
  contextUrl?: string;
}

const PromptRedline = ({
  allRedlineUserIds,
  timestamp,
  onReject,
  error,
  onAccept,
  children,
  isLocationAmbiguous,
  isDisconnected,
  contextUrl = '',
}: PromptRedlineProps) => {
  const backgroundColor = useMemo(
    () => (isLocationAmbiguous ? 'bg-amber-100' : 'app-bg-blue-note'),
    [isLocationAmbiguous]
  );
  return (
    <>
      <div
        className={`relative flex flex-col py-2 px-3 ${backgroundColor} rounded border border-black border-opacity-10`}
      >
        {isLocationAmbiguous && (
          <div className="flex flex-row gap-x-1 mb-2">
            <TooltipOverlay
              content={
                <div>
                  {isDisconnected ? (
                    <div>
                      This suggested edit is disconnected. See{' '}
                      <Link className="link group" to={{ pathname: contextUrl }} target="_blank">
                        source run
                        <FontAwesomeIcon
                          className="px-1 text-blue-500 group-hover:text-blue-600"
                          icon={fasExternalLinkAlt}
                        />
                      </Link>
                      for context.
                    </div>
                  ) : (
                    <div>
                      This suggested edit may be out of place. See{' '}
                      <Link className="link group" to={{ pathname: contextUrl }} target="_blank">
                        source run
                        <FontAwesomeIcon
                          className="px-1 text-blue-500 group-hover:text-blue-600"
                          icon={fasExternalLinkAlt}
                        />
                      </Link>
                      for context.
                    </div>
                  )}
                </div>
              }
              delayClose={true}
            >
              <FontAwesomeIcon icon={faLinkSlash} />
            </TooltipOverlay>
          </div>
        )}
        <div>{children}</div>
        <div className="flex items-center justify-between text-sm mt-1">
          <div>
            {allRedlineUserIds.join(', ')} at <DateTimeDisplay timestamp={timestamp} />
          </div>
          <div className="self-end flex flex-nowrap">
            {onReject !== undefined && (
              <Button type={BUTTON_TYPES.TERTIARY} leadingIcon="times" onClick={onReject}>
                Ignore
              </Button>
            )}
            {onAccept !== undefined && (
              <Button type={BUTTON_TYPES.TERTIARY} leadingIcon="check" onClick={onAccept}>
                Accept Edits
              </Button>
            )}
          </div>
        </div>
      </div>
      {error && (
        <div className="flex justify-end mb-2 text-sm text-red-700">
          <div>{error}</div>
        </div>
      )}
    </>
  );
};

export default PromptRedline;
