import PromptRedline from '../../PromptRedline';
import React, { useCallback, useMemo } from 'react';
import {
  DraftAlertBlock,
  DraftFieldInputBlock,
  ReleaseAlertBlock,
  ReleaseFieldInputBlock,
  RunHeaderBlockRedline,
  RunStepBlockRedline,
} from 'shared/lib/types/views/procedures';
import revisionsUtil from '../../../lib/revisions';
import AlertRun from '../AlertRun';
import FieldInput from '../FieldInput';

/**
 * Component to display block redline diffs.
 *
 * @param type - header_redline or step_redline
 * @param block - the original block
 * @param path - a path prefix to use in for all Formik fields. Can be empty.
 *       Eg, 'sections[0].steps[0].content[2]', for editing the third block
 *       in the first step of the first section.
 * @param runRedline - the redline as it was created in the run
 * @param acceptRedline - Function to call when a redline block is accepted
 * @param rejectRedline - Function to call when a redline block is rejected
 */
interface BlockRedlineBlockProps {
  block: DraftAlertBlock | DraftFieldInputBlock;
  path: string;
  runRedline: RunHeaderBlockRedline | RunStepBlockRedline;
  contentErrors?;
  acceptRedline: (
    path: string,
    block: ReleaseAlertBlock | ReleaseFieldInputBlock,
    redline: RunHeaderBlockRedline | RunStepBlockRedline
  ) => void;
  rejectRedline: (
    path: string,
    block: ReleaseAlertBlock | ReleaseFieldInputBlock,
    redline: RunHeaderBlockRedline | RunStepBlockRedline
  ) => void;
}

const BlockRedlineBlock = ({
  block,
  path,
  runRedline,
  contentErrors,
  acceptRedline,
  rejectRedline,
}: BlockRedlineBlockProps) => {
  const createdBy = useMemo(() => revisionsUtil.createdBy(runRedline), [runRedline]);
  const timestamp = useMemo(() => revisionsUtil.createdAt(runRedline), [runRedline]);

  const redlinedBlock = useMemo(() => {
    return (runRedline as RunHeaderBlockRedline).header
      ? revisionsUtil.getHeaderRedlineBlock(runRedline as RunHeaderBlockRedline)
      : revisionsUtil.getStepRedlineBlock(runRedline as RunStepBlockRedline);
  }, [runRedline]);
  const onAcceptRedline = useCallback(
    () => acceptRedline(path, block, runRedline),
    [acceptRedline, block, path, runRedline]
  );
  const onRejectRedline = useCallback(
    () => rejectRedline(path, block, runRedline),
    [rejectRedline, block, path, runRedline]
  );

  return (
    <PromptRedline
      allRedlineUserIds={[createdBy]} // TODO EPS-2141: Refactor to pass in a single string when the keep-all-redlines work is done.
      timestamp={timestamp}
      onAccept={onAcceptRedline}
      error={contentErrors?.redline}
      onReject={onRejectRedline}
    >
      {/* Render redline diff for a specific block */}
      {block.type === 'alert' && (
        <AlertRun
          block={block as DraftAlertBlock}
          redlinedBlock={redlinedBlock as ReleaseAlertBlock | undefined}
          isDark={false}
        />
      )}

      {block.type === 'input' && (
        <FieldInput
          block={block as DraftFieldInputBlock}
          redlinedBlock={redlinedBlock as ReleaseFieldInputBlock}
          isEnabled={false}
        />
      )}
    </PromptRedline>
  );
};

export default BlockRedlineBlock;
