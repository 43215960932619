import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import pluralize from 'pluralize';
import { components, OptionProps } from 'react-select';

const ItemOption = (props: OptionProps) => {
  const UsageDataLabel = () => {
    if (!props.data.usageData) {
      return null;
    }
    const usageData = props.data.usageData;
    const overLimit = usageData && usageData.limit && usageData.totalAmount >= usageData.limit;
    const usageLabel = `Usage: ${usageData.totalAmount} ${usageData.limit ? `/ ${usageData.limit}` : ''} ${capitalize(
      usageData.name
    )}`;

    return (
      <div className="flex">
        <div className={`text-xs ${overLimit ? 'text-red-700' : ''}`}>{usageLabel}</div>
        {overLimit && (
          <FontAwesomeIcon
            icon="circle-exclamation"
            className="text-red-700 pl-1"
            size="xs"
            style={{ paddingTop: '3px' }}
          />
        )}
      </div>
    );
  };

  const OpenIssuesLabel = () => {
    if (!props.data) {
      return null;
    }
    const numIssues = props.data.numIssues;
    const numCriticalIssues = props.data.numCriticalIssues;

    return (
      <div>
        {(numIssues && numIssues > 0) || (numCriticalIssues && numCriticalIssues > 0) ? (
          <div className="flex">
            <FontAwesomeIcon
              icon="circle-exclamation"
              className={`mr-1 ml-0.5 ${numCriticalIssues > 0 ? 'text-red-500' : 'text-yellow-500'}`}
              size="xs"
              style={{ paddingTop: '3px' }}
            />
            <span className="text-xs">
              {numIssues} Open {pluralize('Issues', numIssues)}
            </span>

            {numCriticalIssues > 0 ? <span className="text-xs ml-1">({numCriticalIssues} Critical)</span> : null}
          </div>
        ) : null}
      </div>
    );
  };

  const quantityLabel = props.data.quantityLabel;
  const statusLabel = props.data.statusLabel;

  return (
    <components.Option {...props}>
      <div>{props.children}</div>
      {quantityLabel && <div className="text-xs">{props.data.quantityLabel}</div>}
      {statusLabel && <div className="text-xs text-red-700">{props.data.statusLabel}</div>}
      <div className="text-xs">{props.data.locationLabel}</div>
      <UsageDataLabel />
      <OpenIssuesLabel />
    </components.Option>
  );
};

export default ItemOption;
