import React, { useMemo } from 'react';
import { Field } from 'formik';
import { HeaderFieldRedline, StepFieldRedline } from '../lib/views/redlines';
import { RunHeaderFieldRedline, RunStepRedline } from 'shared/lib/types/views/procedures';
import FieldRedlineBlock from './Blocks/redline/FieldRedlineBlock';
import { getRunRedlinesSorted } from '../lib/redlineUtil';
import { getRedlineId } from 'shared/lib/redlineUtil';

/**
 * Component for rendering the form for a header field when editing a procedure.
 * Supports showing all redline changes made to the field for acceptance or rejection.
 *
 * @param fieldName - Name of the field.
 * @param fieldValue - Value of the field.
 * @param redlines - Array of header field redline docs
 * @param type - Header or step field
 * @param fieldValueType - Type of the form field, e.g., text, number, etc.
 * @param placeholder - Text rendered when form field is empty.
 * @param acceptRedline - Function called when accept redline button is pressed.
 * @param rejectRedline - Function called when reject redline button is pressed.
 */
interface FieldSetProcedureFieldProps {
  fieldName: 'name';
  fieldValue: string;
  redlines: Array<HeaderFieldRedline> | Array<StepFieldRedline>;
  fieldValueType: string;
  placeholder: string;
  error?: string;
  acceptRedline: (redline: RunHeaderFieldRedline | RunStepRedline) => void;
  rejectRedline: (redline: RunHeaderFieldRedline | RunStepRedline) => void;
}

const FieldSetProcedureField = ({
  fieldName,
  fieldValue,
  redlines,
  fieldValueType,
  placeholder,
  error,
  acceptRedline,
  rejectRedline,
}: FieldSetProcedureFieldProps) => {
  const runRedlinesSorted = useMemo(
    () => getRunRedlinesSorted<HeaderFieldRedline | StepFieldRedline>(redlines),
    [redlines]
  );

  return (
    <fieldset className="min-w-0 flex flex-col gap-y-2">
      {/* Display all redlines for the field. */}
      {runRedlinesSorted &&
        runRedlinesSorted.length > 0 &&
        runRedlinesSorted.map((runRedline) => (
          <FieldRedlineBlock
            key={getRedlineId(runRedline)}
            fieldName={fieldName}
            fieldValue={fieldValue}
            runRedline={runRedline}
            error={error}
            acceptRedline={acceptRedline}
            rejectRedline={rejectRedline}
          />
        ))}

      {/* Render form field. */}
      <Field
        type={fieldValueType}
        name={fieldName}
        placeholder={placeholder}
        className="text-sm w-full border-1 border-gray-400 rounded"
      />
    </fieldset>
  );
};

export default FieldSetProcedureField;
