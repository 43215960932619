import React, { Fragment, useMemo, useCallback } from 'react';
import ExpandCollapseCaret from '../ExpandCollapse/ExpandCollapseCaret';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PERM } from '../../lib/auth';
import { useAuth } from '../../contexts/AuthContext';
import { useMixpanel } from '../../contexts/MixpanelContext';
import runUtil from '../../lib/runUtil';
import { useRunContext } from '../../contexts/RunContext';
import { useRunFilter } from '../../contexts/RunFilterContext';
import { RUN_STATE, isStepEnded } from 'shared/lib/runUtil';
import RunProgressBar from '../RunProgressBar';
import ReviewSectionHeader from './ReviewSectionHeader';
import DiffContainer from '../Diff/DiffContainer';
import diffUtil from '../../lib/diffUtil';
import sharedDiffUtil, { ARRAY_CHANGE_SYMBOLS } from 'shared/lib/diffUtil';
import {
  PROCEDURE_STATE_COMPLETED,
  PROCEDURE_STATE_IN_REVIEW,
  PROCEDURE_STATE_RELEASED,
} from 'shared/lib/procedureUtil';
import DiffContainerTableAdapter from '../Diff/DiffContainerTableAdapter';
import { useReviewContext } from '../../contexts/ReviewContext';
import useDiff from '../../hooks/useDiff';
import ProcedureDiffText from '../ProcedureDiffText';
import { MenuContextAction } from '../MenuContext';
import ReviewProcedureStep from './ReviewProcedureStep';
import ThreeDotMenu from '../../elements/ThreeDotMenu';

const CONFIRM_END_SECTION_STR = 'Are you sure? All unfinished steps in this section will be marked skipped.';
const CONFIRM_REPEAT_SECTION_STR =
  'Are you sure you want to repeat this section? All unfinished steps in this section will be marked skipped.';

const ReviewProcedureSection = ({
  section,
  sectionIndex, // TODO (jon): remove sectionIndex!
  sectionKey,
  sourceName,
  repeatKey,
  projectId,
  runId,
  docState,
  operation,
  onRepeatSection,
  isRepeatable,
  onSkipSection,
  onRefChanged,
  onStartLinkedRun,
  saveNewComment,
  onResolveReviewComment,
  onUnresolveReviewComment,
  saveReviewComment,
  comments,
  showReviewComments,
  isCollapsedMap,
  onCollapse,
  onExpandCollapseAllSteps,
  allStepsAreExpanded,
  scrollToBufferRem = 0,
  stepCounts,
  runStatus,
  onAddStepIssue,
}) => {
  // default function if it is not valid in the props.
  onRefChanged = onRefChanged
    ? onRefChanged
    : () => {
        /* no-op */
      };

  const { auth } = useAuth();
  const { mixpanel } = useMixpanel();
  const { isUserParticipant, isSectionVisible, isSingleCardEnabled, isRun } = useRunContext();

  const { isSectionFiltered, isStepFiltered } = useRunFilter();

  const { onScrollToDiffRefChanged } = useReviewContext();
  const { handleOnScrollToDiffRefChanged } = useDiff({ onScrollToDiffRefChanged });

  const allStepsEnded = useMemo(() => {
    for (const stepIndex in section.steps) {
      const step = section.steps[stepIndex];
      if (!isStepEnded(step)) {
        return false;
      }
    }
    return true;
  }, [section.steps]);

  const isSectionCollapsed = useMemo(() => {
    // Sections are always expanded in single card view
    if (isSingleCardEnabled) {
      return false;
    }
    return isCollapsedMap[section.id];
  }, [section.id, isCollapsedMap, isSingleCardEnabled]);

  const sectionStepIndexMap = useMemo(() => {
    const map = {};
    section.steps.forEach((step, index) => {
      map[step.id] = index;
    });
    return map;
  }, [section.steps]);

  const isReview = useMemo(() => docState === PROCEDURE_STATE_IN_REVIEW, [docState]);
  const isReleased = useMemo(() => docState === PROCEDURE_STATE_RELEASED, [docState]);
  const isCompleted = useMemo(() => docState === PROCEDURE_STATE_COMPLETED, [docState]);
  const isNotEditing = useMemo(
    () => runUtil.isRunStateActive(docState) || isReleased || isReview || isCompleted,
    [docState, isCompleted, isReleased, isReview]
  );

  const removedStepMap = useMemo(() => sharedDiffUtil.getContainerMap(section.steps, 'old'), [section.steps]);
  const nonRemovedStepMap = useMemo(() => sharedDiffUtil.getContainerMap(section.steps, 'new'), [section.steps]);

  const getStepKey = useCallback(
    (step, stepIndex) => {
      const stepIndexForKey = isReview
        ? diffUtil.getIndexForKey(step, removedStepMap, nonRemovedStepMap) ?? 0
        : stepIndex;

      return runUtil.displayStepKey(section.steps, stepIndexForKey);
    },
    [isReview, nonRemovedStepMap, removedStepMap, section.steps]
  );

  const toggleIsSectionCollapsed = useCallback(() => {
    if (typeof onCollapse === 'function') {
      onCollapse(section.id, !isSectionCollapsed);
    }
  }, [isSectionCollapsed, section.id, onCollapse]);

  const repeatSection = useCallback(
    (sectionIndex) => {
      if (typeof onRepeatSection === 'function') {
        if (allStepsEnded || window.confirm(CONFIRM_REPEAT_SECTION_STR)) {
          if (mixpanel) {
            mixpanel.track('Section Repeated');
          }
          onRepeatSection(sectionIndex);
        }
      }
    },
    [onRepeatSection, allStepsEnded, mixpanel]
  );

  const skipSection = useCallback(
    (sectionIndex) => {
      if (typeof onSkipSection === 'function') {
        if (window.confirm(CONFIRM_END_SECTION_STR)) {
          if (mixpanel) {
            mixpanel.track('Section Skipped');
          }
          onSkipSection(sectionIndex);
        }
      }
    },
    [onSkipSection, mixpanel]
  );

  const startLinkedRun = function (sectionIndex, stepIndex, contentIndex, linkedRunId) {
    if (typeof onStartLinkedRun === 'function') {
      onStartLinkedRun(sectionIndex, stepIndex, contentIndex, linkedRunId);
    }
  };

  const onSectionRefChanged = useCallback(
    (element) => {
      onRefChanged(section.id, element);
    },
    [section.id, onRefChanged]
  );

  const menuActions = useMemo(() => {
    const skipSectionAction: MenuContextAction = {
      type: 'label',
      label: 'Skip Section',
      data: {
        icon: 'step-forward',
        title: 'Skip Section',
        onClick: () => skipSection(sectionIndex),
        disabled: docState !== RUN_STATE.RUNNING || !isUserParticipant || allStepsEnded,
      },
    };
    const repeatSectionAction: MenuContextAction = {
      type: 'label',
      label: 'Repeat Section',
      data: {
        icon: 'redo',
        title: 'Repeat Section',
        onClick: () => repeatSection(sectionIndex),
        disabled: docState !== RUN_STATE.RUNNING || !isUserParticipant || !isRepeatable,
      },
    };
    const buttonShowsCollapse = allStepsAreExpanded && !isSectionCollapsed;
    const expandCollapseAllStepsInSection: MenuContextAction = {
      type: 'label',
      label: `${buttonShowsCollapse ? 'Collapse' : 'Expand'} All Steps in Section`,
      data: {
        icon: buttonShowsCollapse ? 'compress-alt' : 'expand-alt',
        onClick: () => {
          if (isSectionCollapsed) {
            toggleIsSectionCollapsed();
          }
          if (buttonShowsCollapse === allStepsAreExpanded) {
            onExpandCollapseAllSteps();
          }
        },
      },
    };
    if (runUtil.isRunStateActive(docState) && auth.hasPermission(PERM.RUNS_EDIT, projectId)) {
      return [skipSectionAction, repeatSectionAction, expandCollapseAllStepsInSection];
    } else {
      return [expandCollapseAllStepsInSection];
    }
  }, [
    docState,
    isUserParticipant,
    allStepsEnded,
    isRepeatable,
    allStepsAreExpanded,
    isSectionCollapsed,
    auth,
    projectId,
    skipSection,
    sectionIndex,
    repeatSection,
    toggleIsSectionCollapsed,
    onExpandCollapseAllSteps,
  ]);

  const sectionBannerClass = useMemo(() => {
    if (runUtil.getSectionEndedPercent(section) === 100) {
      if (runUtil.getSectionCompletedPercent(section) === 100) {
        return 'bg-app-green-200';
      } else if (runUtil.getSectionSkippedPercent(section) === 100) {
        return 'bg-app-gray-400';
      } else if (runUtil.getSectionFailedPercent(section) === 100) {
        return 'bg-red-200';
      } else {
        return '';
      }
    }
    return '';
  }, [section]);

  const sectionNameDiffChangeState = useMemo(
    () => (sharedDiffUtil.isChanged(section, 'name') ? ARRAY_CHANGE_SYMBOLS.MODIFIED : ARRAY_CHANGE_SYMBOLS.UNCHANGED),
    [section]
  );

  const showSectionHeaderReviewCommentsProp = useCallback(
    (sectionHeader) => {
      return showReviewComments && sectionHeader.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED;
    },
    [showReviewComments]
  );

  const showStepReviewCommentsProp = useCallback(
    (step) => {
      return showReviewComments && step.diff_change_state !== ARRAY_CHANGE_SYMBOLS.REMOVED;
    },
    [showReviewComments]
  );

  if (isRun && !isSectionVisible(section)) {
    return null;
  }

  if (isSectionFiltered && !isSectionFiltered(section.id)) {
    return null;
  }

  return (
    <DiffContainerTableAdapter
      label="Section"
      diffChangeState={section.diff_change_state}
      showModified={Boolean(isSectionCollapsed)}
      onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(section.id, element)}
    >
      <tbody>
        <tr>
          <td colSpan={3} className="break-words">
            {/* TODO EPS-4221: Remove this diff container when ready to enable full diffs. */}
            <DiffContainer
              label="Section Name"
              diffChangeState={sectionNameDiffChangeState}
              showModified={!isSectionCollapsed}
              onScrollToDiffRefChanged={(element) => handleOnScrollToDiffRefChanged(section.id, element)}
            >
              <div
                ref={onSectionRefChanged}
                className={`flex flex-row justify-between items-center my-2 page-break border-b-2 border-gray-300 ${sectionBannerClass}`}
                style={{ scrollMarginTop: `${scrollToBufferRem}rem` }}
              >
                <div className="flex flex-column">
                  {!isSingleCardEnabled && (
                    <ExpandCollapseCaret
                      isExpanded={!isSectionCollapsed}
                      onClick={toggleIsSectionCollapsed}
                      ariaLabel="Expand Collapse Section Toggle"
                      isHidden={!onCollapse}
                    />
                  )}
                  {/* Section name */}
                  {/* min-w-0 on flex child fixes long words pushing the size of this element
                  out past the parent container. */}
                  <div
                    className="flex text-xl text-left min-w-0"
                    aria-label={`Section ${sectionKey}${repeatKey ? `, Repeat ${repeatKey}` : ''}`}
                  >
                    <button className="max-w-full text-left cursor-pointer" onClick={toggleIsSectionCollapsed}>
                      <span>Section {sectionKey}: </span>
                      <ProcedureDiffText diffValue={section.name} />
                    </button>
                    {repeatKey && (
                      <>
                        <span className="ml-4 text-base self-center text-gray-600">
                          <FontAwesomeIcon icon="redo" />
                        </span>
                        <span className="ml-1 text-base self-center font-bold text-gray-600 italic whitespace-nowrap">
                          Repeat {repeatKey}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-row items-start ml-4">
                  {(runUtil.isRunStateActive(docState) || docState === RUN_STATE.COMPLETED) && (
                    <RunProgressBar runStatus={runStatus} stepCounts={stepCounts} isSection={true} />
                  )}
                  {onCollapse && isNotEditing && <ThreeDotMenu menuActions={menuActions} menuLabel="Section Menu" />}
                </div>
              </div>
            </DiffContainer>
          </td>
        </tr>
      </tbody>
      {/* Section Header */}
      {section.headers && !isSectionCollapsed && (
        <tbody className="mt-3">
          {section.headers.map((sectionHeader) => {
            return (
              <tr
                key={sharedDiffUtil.getDiffValue<string>(sectionHeader, 'id', 'new')}
                aria-label="Section Header"
                role="region"
              >
                <td colSpan={3}>
                  <ReviewSectionHeader
                    key={sharedDiffUtil.getDiffValue<string>(sectionHeader, 'id', 'new')}
                    sectionHeader={sectionHeader}
                    isCollapsed={isCollapsedMap[sharedDiffUtil.getDiffValue<string>(sectionHeader, 'id', 'new')]}
                    onRefChanged={onRefChanged}
                    scrollToBufferRem={scrollToBufferRem}
                    comments={comments}
                    showReviewComments={showSectionHeaderReviewCommentsProp(sectionHeader)}
                    saveReviewComment={saveReviewComment}
                    onResolveReviewComment={onResolveReviewComment}
                    onUnresolveReviewComment={onUnresolveReviewComment}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      )}
      {!isSectionCollapsed &&
        section.steps &&
        section.steps.map((step, stepIndex) => {
          if (isStepFiltered && !isStepFiltered(step.id)) {
            return null;
          }
          return (
            <Fragment key={`section.${section.id}.step.${step.id}`}>
              <ReviewProcedureStep
                runId={runId}
                projectId={projectId}
                step={step}
                stepKey={getStepKey(step, stepIndex)}
                sectionKey={sectionKey}
                sectionId={section.id}
                sourceName={sourceName}
                docState={docState}
                operation={operation}
                repeatKey={runUtil.displayRepeatKey(section.steps, sectionStepIndexMap[step.id])}
                onRefChanged={onRefChanged}
                onStartLinkedRun={(contentIndex, linkedRun) =>
                  startLinkedRun(sectionIndex, sectionStepIndexMap[step.id], contentIndex, linkedRun)
                }
                saveNewComment={saveNewComment}
                onResolveReviewComment={onResolveReviewComment}
                onUnresolveReviewComment={onUnresolveReviewComment}
                saveReviewComment={saveReviewComment}
                comments={comments}
                showReviewComments={showStepReviewCommentsProp(step)}
                isHidden={isSectionCollapsed}
                isCollapsed={isCollapsedMap[step.id]}
                onStepCollapse={onCollapse}
                scrollToBufferRem={scrollToBufferRem}
                onAddIssue={(issue) => onAddStepIssue(issue, step.id)}
              />
            </Fragment>
          );
        })}
    </DiffContainerTableAdapter>
  );
};

export default ReviewProcedureSection;
