import React, { useMemo } from 'react';
import percentUtil from '../lib/percentUtil';
import { STEP_STATE } from 'shared/lib/runUtil';
import RunStatusBadge, { RunStatus } from './RunStatusBadge';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '../elements/Tooltip';

export type StepCounts = {
  totalCount: number;
  completedCount: number;
  notRequiredCount: number;
  skippedCount: number;
  failedCount: number;
};

interface RunProgressBarProps {
  runStatus: RunStatus;
  stepCounts: StepCounts;
  showStatus?: boolean;
  showNumbers?: boolean;
  isSection?: boolean;
  isResponsive?: boolean;
}

/**
 * Progress bar showing how much of the run or section is completed and skipped, and the status of the run
 * (running, success, etc.) if specified.
 *
 * @param {Object} runStatus - RunStatus object
 * @param {Object} stepCounts - Contains the count of each step type/state e.g. completed, failed, etc
 * @param {Boolean} showStatus - shows the status of the run with a link to the run if true.
 * @param {Boolean} showNumbers - shows the percentage of completed and skipped steps
 * @param {Boolean} isSection - flag to indicate the progress bar is used for a section
 * @param {Boolean} isResponsive - will hide the bar leaving only the percent on smaller screens (disabled by default)
 */
const RunProgressBar = ({
  runStatus,
  stepCounts,
  showStatus = false,
  showNumbers = false,
  isSection = false,
  isResponsive = false,
}: RunProgressBarProps) => {
  const progressBarDisplayMode = useMemo(() => {
    return isResponsive ? 'hidden lg:flex' : 'flex';
  }, [isResponsive]);
  const percentageDisplayMode = useMemo(() => {
    return isResponsive ? 'text-gray-600 font-medium lg:ml-2' : 'ml-2';
  }, [isResponsive]);

  const completedStepsCount = stepCounts?.completedCount ?? 0;

  const notRequiredStepsCount = stepCounts?.notRequiredCount ?? 0;

  const failedStepsCount = stepCounts?.failedCount ?? 0;

  const skippedStepsCount = stepCounts?.skippedCount ?? 0;

  const totalStepsCount = stepCounts?.totalCount ?? 0;

  const percentCompleted = useMemo(() => {
    return percentUtil.toPercent(completedStepsCount, totalStepsCount);
  }, [completedStepsCount, totalStepsCount]);

  const percentNotRequired = useMemo(() => {
    return percentUtil.toPercent(notRequiredStepsCount, totalStepsCount);
  }, [notRequiredStepsCount, totalStepsCount]);

  const percentFailed = useMemo(() => {
    return percentUtil.toPercent(failedStepsCount, totalStepsCount);
  }, [failedStepsCount, totalStepsCount]);

  const percentSkipped = useMemo(() => {
    return percentUtil.toPercent(skippedStepsCount, totalStepsCount);
  }, [skippedStepsCount, totalStepsCount]);

  const percentIncomplete = useMemo(() => {
    const completedSteps = completedStepsCount + failedStepsCount + skippedStepsCount + notRequiredStepsCount;
    const incompleteSteps = totalStepsCount - completedSteps;
    return percentUtil.toPercent(incompleteSteps, totalStepsCount);
  }, [completedStepsCount, failedStepsCount, skippedStepsCount, notRequiredStepsCount, totalStepsCount]);

  const percentEnded = useMemo(() => {
    const endedSteps = completedStepsCount + skippedStepsCount + failedStepsCount;
    const canCompleteSteps = totalStepsCount - notRequiredStepsCount;

    const endedPercent = percentUtil.toPercent(endedSteps, canCompleteSteps);
    // Special case when both numerator/denominator are zero e.g. entire section cannot complete yet
    if (endedPercent === undefined) {
      return 0;
    }
    return endedPercent;
  }, [completedStepsCount, skippedStepsCount, failedStepsCount, notRequiredStepsCount, totalStepsCount]);

  const percentEndedString = useMemo(() => {
    return `${percentUtil.toNearestPercent(percentEnded)}%`;
  }, [percentEnded]);

  const runOverFlow = useMemo(() => {
    if (isSection) {
      return '';
    }
    return 'overflow-hidden';
  }, [isSection]);

  type BarData = {
    state: string;
    color: string;
    percent: number;
    label?: string;
    numbers?: string;
    isLeftBar?: boolean;
    isRightBar?: boolean;
  };

  const barData: Array<BarData> = useMemo(() => {
    const barData = [
      {
        state: STEP_STATE.COMPLETED,
        color: 'bg-emerald-500',
        percent: percentCompleted,
        label: 'Completed',
        numbers: `(${completedStepsCount}/${totalStepsCount})`,
      },
      {
        state: STEP_STATE.FAILED,
        color: 'bg-red-500',
        percent: percentFailed,
        label: 'Failed',
        numbers: `(${failedStepsCount}/${totalStepsCount})`,
      },
      {
        state: STEP_STATE.SKIPPED,
        color: 'bg-gray-400',
        percent: percentSkipped,
        label: 'Skipped',
        numbers: `(${skippedStepsCount}/${totalStepsCount})`,
      },
      {
        state: 'not-required',
        color: 'stripe border border-1 border-gray-300',
        percent: percentNotRequired,
        label: 'Not Required',
        numbers: `(${notRequiredStepsCount}/${totalStepsCount})`,
      },
      {
        state: 'incomplete',
        color: 'bg-white border border-1 border-gray-300',
        percent: percentIncomplete,
      },
    ] as Array<BarData>;

    // Determine which bars are the leftmost and rightmost, since those will have rounded ends
    const isPositivePercent = (bar) => bar.percent > 0;
    let leftBarIndex = barData.findIndex(isPositivePercent);
    let rightBarIndex = [...barData].reverse().findIndex(isPositivePercent);

    // in case all bars are zero, set the indices to the first and last
    leftBarIndex = leftBarIndex === -1 ? 0 : leftBarIndex;
    rightBarIndex = rightBarIndex === -1 ? barData.length - 1 : barData.length - rightBarIndex - 1;

    barData[leftBarIndex].isLeftBar = true;
    barData[rightBarIndex].isRightBar = true;
    return barData;
  }, [
    percentCompleted,
    percentNotRequired,
    percentFailed,
    percentSkipped,
    percentIncomplete,
    completedStepsCount,
    notRequiredStepsCount,
    failedStepsCount,
    skippedStepsCount,
    totalStepsCount,
  ]);

  return (
    <div
      data-testid={!isSection ? 'runProgressBar' : 'sectionProgressBar'}
      className={`grow gap-x-2 self-center ${runOverFlow}`}
    >
      <div className="flex flex-row items-center">
        <div className="flex w-full items-center">
          <div className={`${progressBarDisplayMode} flex-row h-2 space-x-px ${!isSection ? 'w-full' : 'w-32'}`}>
            {barData.map((bar, index) => {
              if (bar.percent > 0) {
                if (isSection) {
                  return (
                    <div key={index} style={{ width: `${bar.percent}%` }}>
                      <Tooltip visible={bar.state !== 'incomplete'} content={`${bar.label}: ${bar.numbers}`}>
                        <div
                          className={`h-2 justify-center relative group flex flex-row whitespace-nowrap ${bar.color} ${
                            bar.isLeftBar ? 'rounded-l-lg' : ''
                          } ${bar.isRightBar ? 'rounded-r-lg' : ''}`}
                        ></div>
                      </Tooltip>
                    </div>
                  );
                }
                return (
                  <div key={index} style={{ width: `${bar.percent}%` }}>
                    <Tooltip visible={bar.state !== 'incomplete'} content={`${bar.label}`}>
                      <div
                        className={`h-2 ${bar.color} ${bar.isLeftBar ? 'rounded-l-lg' : ''} ${
                          bar.isRightBar ? 'rounded-r-lg' : ''
                        }`}
                      />
                    </Tooltip>
                  </div>
                );
              } else {
                return null;
              }
            })}
          </div>
          <span className={`${percentageDisplayMode} text-sm font-md whitespace-nowrap w-10`}>
            {percentEndedString}
          </span>
          {showStatus && (
            <div className="ml-2">
              <RunStatusBadge runStatus={runStatus} />
            </div>
          )}
        </div>
      </div>
      {stepCounts && showNumbers && (
        <div className="flex flex-row text-xs font-semibold tracking-wide space-x-4  w-10/12">
          <Tooltip content="Completed">
            <div data-testid="completedPercentage" className="ml-1 flex flex-nowrap items-center">
              <FontAwesomeIcon icon="circle" className="mr-1 text-xs text-emerald-500" />
              <span className="whitespace-nowrap">
                {completedStepsCount}/{totalStepsCount}
              </span>
            </div>
          </Tooltip>
          {failedStepsCount > 0 && (
            <Tooltip content="Failed">
              <div data-testid="failedPercentage" className="ml-1 flex flex-nowrap items-center">
                <FontAwesomeIcon icon="circle" className="mr-1 text-xs text-red-500" />
                <span className="whitespace-nowrap">
                  {failedStepsCount}/{totalStepsCount}
                </span>
              </div>
            </Tooltip>
          )}
          {skippedStepsCount > 0 && (
            <Tooltip content="Skipped">
              <div data-testid="skippedPercentage" className="ml-1 flex flex-nowrap items-center">
                <FontAwesomeIcon icon="circle" className="mr-1 text-xs text-gray-400" />
                <span className="whitespace-nowrap">
                  {skippedStepsCount}/{totalStepsCount}
                </span>
              </div>
            </Tooltip>
          )}
          {notRequiredStepsCount > 0 && (
            <Tooltip content="Not Required">
              <div data-testid="notRequiredPercentage" className="ml-1 flex flex-nowrap items-center">
                <div className="h-3 w-3 rounded-l-lg rounded-r-lg stripe border border-1 border-gray-300 mr-1"></div>
                <span className="whitespace-nowrap">
                  {notRequiredStepsCount}/{totalStepsCount}
                </span>
              </div>
            </Tooltip>
          )}
        </div>
      )}
    </div>
  );
};

export default RunProgressBar;
