import React, { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import Select, { components as ReactSelectComponents, StylesConfig, ActionMeta } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikHandlers } from 'formik/dist/types';
import Button from './Button';
import { cloneDeep } from 'lodash';

const Control = ({ children, ...props }) => {
  return (
    <ReactSelectComponents.Control {...props}>
      <div className="flex pl-3 last:pl-4">
        <FontAwesomeIcon className="text-gray-500" icon={['far', 'check-circle']} />
      </div>
      {children}
    </ReactSelectComponents.Control>
  );
};

const MultiValueRemove = ({ showUserIdMenu, isDisabled, canAddUserIdToOperatorRoleSignoff, children, ...props }) => {
  const addUserIdProps = cloneDeep(props);
  addUserIdProps.innerProps.onClick = () => {
    if (props.data.type !== 'operator_role' || !canAddUserIdToOperatorRoleSignoff) {
      return;
    }
    showUserIdMenu(props.data.value);
  };

  return (
    <>
      {canAddUserIdToOperatorRoleSignoff &&
        props.data.type === 'operator_role' &&
        (!props.data.user_ids || props.data.user_ids === 0) && (
          <ReactSelectComponents.MultiValueRemove {...addUserIdProps}>
            <div
              className="flex flex-row w-full h-full hover:bg-blue-200 rounded-full"
              aria-label="Add User to Operator Role"
            >
              <Button type="tertiary" size="xs" leadingIcon="user-plus" />
            </div>
          </ReactSelectComponents.MultiValueRemove>
        )}
      {props.data.type === 'operator_role' && props.data.user_ids && props.data.user_ids.length > 0 && (
        <ReactSelectComponents.MultiValueRemove {...addUserIdProps}>
          <div
            className={`flex flex-row w-full h-full ${
              canAddUserIdToOperatorRoleSignoff ? 'hover:bg-blue-200' : ''
            } rounded-full`}
            aria-label="Update User for Operator Role"
          >
            <span className="px-2 text-xs lowercase font-light self-center whitespace-nowrap">
              {props.data.user_ids.join(', ')}
            </span>
          </div>
        </ReactSelectComponents.MultiValueRemove>
      )}
      {!isDisabled && (
        <ReactSelectComponents.MultiValueRemove {...props}>
          <div className="flex flex-row w-full h-full hover:bg-rose-300 rounded-full">
            <Button type="tertiary" size="xs" leadingIcon="xmark" iconTextColor="text-gray-800" />
          </div>
        </ReactSelectComponents.MultiValueRemove>
      )}
      {/* Add spacing if there are no buttons*/}
      {!canAddUserIdToOperatorRoleSignoff && isDisabled && <div className="w-1" />}
    </>
  );
};

type FieldSetSignoffSelectOption = {
  value: string | null;
  label: string;
  type?: string;
  user_ids?: Array<string>;
};

/**
 * Renders a signoff select.
 *
 * @param options - Dropdown options to select from.
 * @param name - Name to pass thru to react-select component.
 * @param placeholder - HTML element to use as placeholder.
 * @param value - Initial value of selector.
 * @param onChange - Callback triggered on selector change.
 * @param onBlur - Callback to pass thru to react-select component.
 * @param ariaLabel - Aria label for selector component.
 * @param opacity - Opacity of select control element.
 * @param hoverOpacity - Opacity of select control element on hover.
 */
interface FieldSetSignoffSelectProps {
  options: Array<FieldSetSignoffSelectOption>;
  name?: string;
  placeholder?: ReactNode;
  value: Array<FieldSetSignoffSelectOption> | null;
  onChange: (options: Array<FieldSetSignoffSelectOption>, action: ActionMeta) => void;
  onBlur?: FormikHandlers['handleBlur'];
  ariaLabel: string;
  opacity: number;
  hoverOpacity?: number;
  showControl?: boolean;
  borderWidth?: number;
  hasApproved?: boolean;
  menuIsOpen?: boolean;
  showUserIdMenu?: (operatorRole: string | null) => void;
  hideMenu?: () => void;
  isDisabled?: boolean;
  canAddUserIdToOperatorRoleSignoff?: boolean;
}

const FieldSetSignoffSelect = ({
  options,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  ariaLabel,
  opacity,
  hoverOpacity = 100,
  showControl = true,
  borderWidth = 1,
  hasApproved = false,
  menuIsOpen,
  showUserIdMenu,
  hideMenu,
  isDisabled = false,
  canAddUserIdToOperatorRoleSignoff = false,
}: FieldSetSignoffSelectProps) => {
  const selectSignoffWrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const closeSignoffMenu = (e) => {
      if (!hideMenu) {
        return;
      }
      if (selectSignoffWrapperRef.current && !selectSignoffWrapperRef.current.contains(e.target)) {
        hideMenu();
      }
    };
    if (menuIsOpen) {
      document.addEventListener('click', closeSignoffMenu);
    }
    // Remove listener when menu visibility changes or component unmounts
    return () => {
      document.removeEventListener('click', closeSignoffMenu);
    };
  }, [hideMenu, menuIsOpen]);

  const styles: StylesConfig = useMemo(
    () => ({
      control: (base) => ({
        ...base,
        borderColor: 'rgba(156, 163, 175)',
        backgroundColor: hasApproved ? '#B3D9CF' : 'white',
        opacity,
        '&:hover': { opacity: hoverOpacity },
        borderWidth,
        minWidth: 0,
        maxWidth: '40rem',
        width: value ? 'max-content' : '12rem',
      }),
      menu: (base) => ({
        ...base,
        width: 'max-content',
        zIndex: 20,
      }),
      valueContainer: (base) => ({
        ...base,
        minWidth: 0,
        paddingRight: 0,
      }),
      dropdownIndicator: (base) => ({
        ...base,
        color: 'rgba(156, 163, 175)',
      }),
      multiValue: (base, state) => {
        const hasDisabledUserId = isDisabled && state.data.type === 'user_id';
        return {
          ...base,
          borderRadius: '1rem',
          paddingLeft: '0.25rem',
          ...(hasDisabledUserId && { paddingRight: '0.5rem' }),
        };
      },
      multiValueRemove: (base) => ({
        ...base,
        borderRadius: '1rem',
        padding: 0,
        ':hover': {}, // Handle hover colors in the custom class
      }),
    }),
    [isDisabled, borderWidth, hasApproved, hoverOpacity, opacity, value]
  );

  const selectRef = useRef();
  const Placeholder = () => {
    return <div className="text-gray-600">{placeholder}</div>;
  };

  const renderMultiValueRemove = useCallback(
    (props) => {
      return (
        <MultiValueRemove
          showUserIdMenu={showUserIdMenu}
          isDisabled={isDisabled}
          canAddUserIdToOperatorRoleSignoff={canAddUserIdToOperatorRoleSignoff}
          {...props}
        />
      );
    },
    [canAddUserIdToOperatorRoleSignoff, isDisabled, showUserIdMenu]
  );

  const components = {
    Placeholder,
    IndicatorSeparator: () => null,
    MultiValueRemove: renderMultiValueRemove,
    ...(showControl && { Control }),
    ...(isDisabled && { DropdownIndicator: () => <div className="w-2" /> }),
  };

  const menuProps = useMemo(
    () => ({
      menuPosition: 'fixed',
      ...(menuIsOpen !== undefined && { menuIsOpen }),
      ...(showUserIdMenu && {
        onMenuOpen: () => {
          showUserIdMenu(null);
        },
      }),
      ...(hideMenu && { onMenuClose: hideMenu }),
    }),
    [hideMenu, menuIsOpen, showUserIdMenu]
  );

  return (
    <div ref={selectSignoffWrapperRef}>
      <Select
        ref={selectRef}
        classNamePrefix="react-select"
        styles={styles}
        options={options}
        name={name}
        components={components}
        value={value}
        onChange={onChange}
        onBlur={(e) => {
          onBlur && onBlur(e);
          showUserIdMenu && showUserIdMenu(null);
        }}
        isMulti
        placeholder
        isClearable={false}
        aria-label={ariaLabel}
        isSearchable={!isDisabled}
        {...menuProps}
      />
    </div>
  );
};

export default FieldSetSignoffSelect;
