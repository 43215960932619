import cloneDeep from 'lodash.clonedeep';
import blockUtil from './blockUtil';
import { cannotUpdateStep } from './runUtil';
import {
  FieldInputTableStepDocBlock,
  Recorded,
  RedlinedStep,
  RunExpressionBlock,
  RunFieldInputBlock,
  RunFieldInputRecorded,
  RunFieldInputTableBlock,
  RunReferencingBlockType,
  RunStep,
  RunStepBlock,
  StepAction,
  StepDoc,
  StepDocBlock,
} from './types/views/procedures';
import tableUtil from './tableUtil';
import signoffUtil from './signoffUtil';
import lodash from 'lodash';

export const getStepDocId = (
  runId: string,
  sectionId: string,
  stepId: string
) => {
  if (!runId || !sectionId || !stepId) {
    throw new Error('Missing run, section or step id.');
  }
  return `index_${runId}:${sectionId}:${stepId}`;
};

const cleanedStepDocContent = (
  content: Array<RunStepBlock>
): Array<StepDocBlock<RunStepBlock, Recorded>> => {
  return lodash.cloneDeep(content).map((block: RunStepBlock) => {
    if (block.type === 'table_input') {
      block.cells = tableUtil.getInitialCells(block);
    }
    return block;
  });
};
export const newStepDoc = (runId: string, sectionId: string, step: RunStep) => {
  return {
    _id: getStepDocId(runId, sectionId, step.id),
    run_id: runId,
    section_id: sectionId,
    step_id: step.id,
    content: cleanedStepDocContent(step.content),
  };
};

/**
 * Gets the recorded content of a step in a map "step recorded" shape.
 *
 * @param {Object} step - A step or run step object.
 * @returns {Object} recorded - The recorded dictionary in the shape
 *   { [block_index]: block_recorded_object }.
 */
export const getStepRecorded = (step) => {
  const recordedContentMap: { [index: number]: any } = {};
  if (!step) return recordedContentMap;

  step.content.forEach((block, index) => {
    if (block.type === 'field_input_table' && block.fields) {
      const fieldRecords: { [fieldIndex: number]: any } = {};
      block.fields.forEach((field, fieldIndex) => {
        if (field.recorded) {
          fieldRecords[fieldIndex] = field.recorded; // Directly use recorded
        }
      });
      recordedContentMap[index] = fieldRecords;
    } else if (block.recorded) {
      recordedContentMap[index] = block.recorded; // Use recorded directly
    }
  });
  return recordedContentMap;
};

export const updateBlockRecorded = ({
  step,
  contentId,
  userId,
  timestamp,
  actionId,
  recorded,
  userOperatorRoleSet,
  fieldIndex,
}: {
  step: RunStep;
  contentId: string;
  userId: string;
  timestamp: string;
  actionId: string;
  recorded: Recorded;
  userOperatorRoleSet: Set<string>;
  fieldIndex?: number;
}): boolean => {
  if (!step) {
    throw new Error('Missing step document');
  }

  let block = step.content.find((block) => block.id === contentId);
  if (!block) {
    throw new Error('Content not found');
  }

  if (typeof fieldIndex === 'number') {
    block = (block as RunFieldInputTableBlock).fields[fieldIndex];
    if (!block) {
      throw new Error('Content not found');
    }
  }

  // If step cannot be updated, drop this request.
  if (cannotUpdateStep(step)) {
    return false;
  }

  if (!recorded) {
    return false;
  }

  return blockUtil.addRecordedAction({
    block,
    userId,
    timestamp,
    actionId,
    recorded,
    userOperatorRoleSet,
  });
};

export const updateStepRecorded = ({
  step,
  recorded,
}: {
  step: RunStep;
  recorded: { [index: number]: any };
}) => {
  if (!step) {
    throw new Error('Missing step document');
  }

  if (cannotUpdateStep(step)) {
    return false;
  }

  if (!recorded) {
    return false;
  }

  let updated = false;
  Object.entries(recorded).forEach(([contentIndex, contentRecorded]) => {
    const block = step.content[+contentIndex];
    if (block && block.type === 'field_input_table') {
      block.fields.forEach((field, fieldIndex) => {
        const fieldRecord = (contentRecorded as { [key: number]: any })[
          fieldIndex
        ];
        if (fieldRecord) {
          updated =
            blockUtil.updateRecorded({
              block: field,
              recorded: fieldRecord,
            }) || updated;
        }
      });
    } else if (contentRecorded) {
      updated =
        blockUtil.updateRecorded({
          block,
          recorded: contentRecorded,
        }) || updated;
    }
  });
  return updated;
};

/**
 If there are no longer any signoffs, remove recorded values from
 expressions in the step.

 Both the step from the run and the stepDoc are needed, because
 the signoff-related info is in the step in the run, but the stepDoc is
 what needs to be updated.
 */
export const updateStepDocForRevokeSignoff = ({
  step,
  stepDoc,
}: {
  step: RunStep;
  stepDoc: RunStep;
}): boolean => {
  // Do nothing if any signoffs are still complete.
  if (signoffUtil.anySignoffsComplete(step)) {
    return false;
  }

  stepDoc.content
    .filter((block): block is RunReferencingBlockType =>
      ['expression', 'text'].includes(block.type)
    )
    .forEach((expressionBlock) => {
      delete expressionBlock.recorded;
    });

  return true;
};

// Keep the recorded values for field inputs if the input type does not change.
const _handleFieldInputRecorded = ({
  previousBlock,
  updatedBlock,
}: {
  previousBlock: StepDocBlock<RunStepBlock, Recorded>;
  updatedBlock: StepDocBlock<RunStepBlock, Recorded>;
}) => {
  const previousFieldInputBlock = previousBlock as StepDocBlock<
    RunFieldInputBlock,
    RunFieldInputRecorded
  >;
  const updatedFieldInputBlock = updatedBlock as StepDocBlock<
    RunFieldInputBlock,
    RunFieldInputRecorded
  >;

  if (previousFieldInputBlock.inputType === updatedFieldInputBlock.inputType) {
    return {
      ...updatedFieldInputBlock,
      recorded: previousFieldInputBlock.recorded,
      actions: previousFieldInputBlock.actions,
    } as StepDocBlock<RunStepBlock, Recorded>;
  }
  return updatedFieldInputBlock;
};

// Do not keep recorded values for field input tables if anything changes in the block.
const _handleFieldInputTableRecorded = ({
  previousBlock,
  updatedBlock,
}: {
  previousBlock: StepDocBlock<RunStepBlock, Recorded>;
  updatedBlock: StepDocBlock<RunStepBlock, Recorded>;
}) => {
  const previousFieldInputTableBlock =
    previousBlock as FieldInputTableStepDocBlock;
  const updatedFieldInputTableBlock =
    updatedBlock as FieldInputTableStepDocBlock;

  if (
    lodash.isEqual(
      lodash.omit(previousFieldInputTableBlock, [
        'recorded',
        'actions',
        'fields',
      ]),
      lodash.omit(updatedFieldInputTableBlock, [
        'recorded',
        'actions',
        'fields',
      ])
    ) &&
    previousFieldInputTableBlock.fields.length ===
      updatedFieldInputTableBlock.fields.length &&
    updatedFieldInputTableBlock.fields.every((field, fieldIndex) =>
      lodash.isEqual(
        lodash.omit(previousFieldInputTableBlock.fields[fieldIndex], [
          'recorded',
          'actions',
        ]),
        lodash.omit(field, ['recorded', 'actions'])
      )
    )
  ) {
    updatedFieldInputTableBlock.fields.forEach((field, fieldIndex) => {
      updatedFieldInputTableBlock.fields[fieldIndex] = {
        ...field,
        recorded: previousFieldInputTableBlock.fields[fieldIndex].recorded,
        actions: previousFieldInputTableBlock.fields[fieldIndex].actions,
      } as FieldInputTableStepDocBlock['fields'][number];
    });
    return updatedFieldInputTableBlock;
  }
  return updatedFieldInputTableBlock;
};

/**
 Catch-all for any block that has recorded values:
 if the block changed at all, do not keep the recorded values.
 */
const _handleGenericRecordedValues = ({
  previousBlock,
  updatedBlock,
}: {
  previousBlock: StepDocBlock<RunStepBlock, Recorded>;
  updatedBlock: StepDocBlock<RunStepBlock, Recorded>;
}): StepDocBlock<RunStepBlock, Recorded> => {
  if (!('recorded' in previousBlock) && !('actions' in previousBlock)) {
    return updatedBlock;
  }
  if (
    lodash.isEqual(
      lodash.omit(previousBlock, ['recorded', 'actions']),
      lodash.omit(updatedBlock, ['recorded', 'actions'])
    )
  ) {
    return {
      ...updatedBlock,
      recorded: previousBlock['recorded'],
      actions: previousBlock.actions,
    } as StepDocBlock<RunStepBlock, Recorded>;
  }

  return updatedBlock;
};

const _recordedValueHandler: {
  [blockType in 'input' | 'field_input_table']: ({
    previousBlock,
    updatedBlock,
  }: {
    previousBlock: StepDocBlock<RunStepBlock, Recorded>;
    updatedBlock: StepDocBlock<RunStepBlock, Recorded>;
  }) => StepDocBlock<RunStepBlock, Recorded>;
} = {
  input: _handleFieldInputRecorded,
  field_input_table: _handleFieldInputTableRecorded,
};

export const updateStepDocForIncludeRedline = ({
  updatedStep,
  redlineId,
  stepDoc,
}: {
  updatedStep: RunStep | RedlinedStep;
  redlineId: string;
  stepDoc: StepDoc;
}) => {
  const blockMap: { [id: string]: StepDocBlock<RunStepBlock, Recorded> } = (
    stepDoc.content ?? []
  ).reduce((map, block) => {
    map[block.id] = block;
    return map;
  }, {});
  stepDoc.previous = [
    ...(stepDoc.previous ?? []),
    { redline_id: stepDoc.redline_id, content: cloneDeep(stepDoc.content) },
  ];
  stepDoc.content = cleanedStepDocContent(updatedStep.content) ?? [];
  stepDoc.redline_id = redlineId;

  // Add back the recorded values so they are not lost.
  stepDoc.content.forEach((updatedBlock, blockIndex) => {
    const previousBlock = blockMap[updatedBlock.id];
    if (!previousBlock || previousBlock.type !== updatedBlock.type) {
      return;
    }

    const getBlockWithRecorded =
      _recordedValueHandler[updatedBlock.type] ?? _handleGenericRecordedValues;
    stepDoc.content[blockIndex] = getBlockWithRecorded({
      previousBlock,
      updatedBlock,
    });
  });

  return true;
};

export const updateStepWithAction = ({
  step,
  type,
  userId,
  timestamp,
  context,
}: {
  step: RunStep;
  type: StepAction['type'];
  userId: string;
  timestamp?: string;
  comment?: string;
  context?: object;
}) => {
  if (!step.actions) {
    step.actions = [];
  }
  step.actions.push({
    type,
    user_id: userId,
    timestamp: timestamp ?? new Date().toISOString(),
    ...(context ?? {}),
  } as StepAction);
};
